import {
  CurrencyValue,
  Chart,
  formatCurrency,
  ChartSkeleton,
  formatDatetime
} from '@blockanalitica/ui';
import { useMemo, useCallback } from 'react';
import {
  ChartConfig,
  ChartDataset
} from '@blockanalitica/ui/package/types/src/components/charts/Chart';
import { useChartBackground } from '@/hooks';
import { getAssetColor } from '@/utils/utils';

export interface DatesData {
  [underlying_symbol: string]: {
    period: string;
    collateral_amount_usd: string;
  }[];
}

export default function DatesLiquidationGraph({
  data: auctionData,
  chartDaysAgo
}: {
  data: DatesData;
  chartDaysAgo: string | number;
}) {
  const periodInfo = useMemo(() => {
    const value = Number(chartDaysAgo);
    if (value <= 2) {
      return {
        prefix: '',
        dateFormat: 'MMM dd, HH:mm',
        timeUnit: 'hour'
      };
    }
    if (value < 30) {
      return {
        prefix: '',
        dateFormat: 'MMM dd, yyyy',
        timeUnit: 'day'
      };
    }
    if (value < 90) {
      return {
        prefix: 'Week starting ',
        dateFormat: 'MMM dd, yyyy',
        timeUnit: 'week'
      };
    } else if (value < 365) {
      return {
        prefix: 'Week starting ',
        dateFormat: 'MMM dd, yyyy',
        timeUnit: 'month'
      };
    }
    return {
      prefix: '',
      dateFormat: 'MMM yyyy',
      timeUnit: 'month'
    };
  }, [chartDaysAgo]);
  const chartDatasets: ChartDataset[] = useMemo(() => {
    if (!auctionData) return [];
    const entriesWithTotals = Object.entries(auctionData).map(([key, rows]) => {
      const total = rows.reduce(
        (acc, row) => acc + Number(row.collateral_amount_usd),
        0
      );
      return { key, rows, total };
    });
    entriesWithTotals.sort((a, b) => a.total - b.total);
    return entriesWithTotals.map(({ key, rows }) => ({
      label: key,
      data: rows.map((row) => ({
        x: row.period,
        y: row.collateral_amount_usd
      })),
      borderColor: getAssetColor(key) || '#fff',
      backgroundColor: getAssetColor(key) || '#fff'
    }));
  }, [auctionData]);

  const valueFormatter = useCallback(
    (value: string | number) => (
      <CurrencyValue
        value={value}
        variant="dynamic"
        options={{
          currency: 'USD'
        }}
      />
    ),
    []
  );

  const subValueFormatter = useCallback(
    (value: string | number) => {
      return `${periodInfo.prefix}${formatDatetime(value, { format: periodInfo.dateFormat })}`;
    },
    [periodInfo]
  );

  const barConfig: ChartConfig = useMemo(() => {
    return {
      type: 'bar',
      options: {
        scales: {
          x: {
            stacked: true,
            time: {
              unit: periodInfo.timeUnit as 'hour' | 'day' | 'week' | 'month'
            }
          },
          y: {
            stacked: true
          }
        },
        elements: {
          bar: {
            hoverBorderWidth: 0
          }
        },
        plugins: {
          tooltip: {
            itemSort: (a, b) =>
              (b.parsed as { y: number }).y - (a.parsed as { y: number }).y
          }
        }
      }
    };
  }, [periodInfo.timeUnit]);
  const config = useChartBackground(barConfig);

  if (auctionData) {
    return (
      <Chart
        chartDatasets={chartDatasets}
        config={config}
        valueFormatter={valueFormatter}
        subValueFormatter={subValueFormatter}
        tooltip={{
          valueFormatter: (value) => formatCurrency(Number(value)),
          titleFormatter: (_value, tootlipItems) =>
            `${periodInfo.prefix}${formatDatetime((tootlipItems[0].raw as { x: string }).x, { format: periodInfo.dateFormat })}`
        }}
        showKpisAsTooltip
      />
    );
  }
  return <ChartSkeleton />;
}
