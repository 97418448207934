import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

const StyledButton = styled.button`
  ${(props) => css`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: ${props.theme.space.xs};
    white-space: nowrap;
    border-radius: ${props.theme.sizes['-2']};
    font-size: ${props.theme.sizes['0']};
    border: 1px solid ${props.theme.colors.grey35};
    background-color: ${props.theme.colors.grey20};
    padding: ${props.theme.space['2xs']} ${props.theme.space['s']};
    height: ${props.theme.sizes['6']};
    color: ${props.theme.colors.text};
    &:hover {
      border-color: ${props.theme.colors.primary};
    }
  `}
`;
export interface ButtonProps {
  children: ReactNode | ReactNode[];
}

export default function Button({ children, ...rest }: ButtonProps) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}
