import { useApi, PaginatedResponse } from '@/api';
import {
  CurrencyValue,
  DataTable,
  TableSkeleton,
  CryptoIcon,
  Box
} from '@blockanalitica/ui';
import { EntityName } from '@/components';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';
import { useTableProps } from '@blockanalitica/ui';

type LiquidationData = {
  underlying_symbol: string;
  underlying_address: string;
  amount_usd: string;
  amount: string;
};

interface AppliedFilters {
  protocols: string[];
  loan_assets: string[];
  coll_assets: string[];
  from_date: string | null;
  to_date: string | null;
}
interface LiquidationTableProps {
  appliedFilters?: AppliedFilters;
}

export default function CollateralLiquidatedTable({
  appliedFilters = {
    protocols: [],
    loan_assets: [],
    coll_assets: [],
    from_date: '',
    to_date: ''
  }
}: LiquidationTableProps) {
  const { pagination, sorting } = useTableProps({
    pageSize: 10,
    initialSorting: '-order_index'
  });

  const [data, error] = useApi<PaginatedResponse<LiquidationData[]>>({
    url: `/liquidations/popup`,
    queryParams: {
      p: pagination.currentPage.toString(),
      p_size: pagination.pageSize.toString(),
      order: sorting.currentSorting,
      protocols: appliedFilters.protocols.join(','),
      loan_assets: appliedFilters.loan_assets.toString(),
      coll_assets: appliedFilters.coll_assets.toString(),
      from_date: appliedFilters.from_date || '',
      to_date: appliedFilters.to_date || '',
      type: 'collateral'
    }
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  const columns: TableColumn[] = [
    {
      header: 'Token',
      getCell: (row) => (
        <EntityName
          symbol1={
            <CryptoIcon
              name={row.underlying_symbol}
              address={row.underlying_address}
              size="1"
            />
          }>
          {row.underlying_symbol}
        </EntityName>
      ),
      columnSize: '9rem'
    },

    {
      header: 'Collateral Liquidated USD',
      getCell: (row) => (
        <CurrencyValue
          value={row.amount_usd}
          options={{
            currency: 'USD'
          }}
        />
      ),
      sortingField: 'amount_usd',
      cellAlign: 'flex-end',
      headerAlign: 'flex-end'
    },
    {
      header: 'Collateral Liquidated',
      getCell: (row) => (
        <CurrencyValue
          value={row.amount}
          options={{
            currency: {
              name: row.underlying_symbol,
              address: row.underlying_address
            }
          }}
        />
      ),
      getCellBottom: () => '',
      sortingField: 'amount',
      cellAlign: 'flex-end',
      headerAlign: 'flex-end'
    }
  ];

  return (
    <Box>
      <DataTable
        rows={data.results}
        columns={columns}
        getKey={(row) => `${row.underlying_address}`}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    </Box>
  );
}
