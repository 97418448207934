import {
  LineChart,
  formatPercentage,
  formatCurrency,
  ChartSkeleton,
  Flex,
  Text,
  InfoCircleIcon
} from '@blockanalitica/ui';
import { useApi } from '@/api';
import { getColorForKey } from '@/utils/utils';

import { useMemo, useCallback } from 'react';
import { useTheme } from 'styled-components';
import type { TooltipItem } from 'chart.js';
import { useChartBackground } from '@/hooks';
import { Tabs } from '@/components';

type SlippageChartProps = {
  key: string;
  x: string;
  y: string;
};

type SlippageChartFilters = {
  tokensFiltered: string | null;
  collateralTokensFiltered: string | null;
};

export default function SlippagesChart({
  tokensFiltered,
  collateralTokensFiltered
}: SlippageChartFilters) {
  const theme = useTheme();
  const [data, error] = useApi<SlippageChartProps[]>(
    {
      url: `/net-estimator/slippages/`,
      queryParams: {
        tokens: String(tokensFiltered),
        collateral_tokens: String(collateralTokensFiltered)
      }
    },
    { keepPreviousData: true }
  );

  const datasets = useMemo(() => {
    if (!data) return [];
    const groupedByKey = data.reduce(
      (grouped, entry) => {
        if (!grouped[entry.key]) {
          grouped[entry.key] = [];
        }
        grouped[entry.key].push(entry);
        return grouped;
      },
      {} as Record<string, SlippageChartProps[]>
    );

    return Object.keys(groupedByKey).map((key) => ({
      label: key,
      xField: 'x',
      yField: 'y',
      data: groupedByKey[key],
      borderWidth: 1,
      borderColor: getColorForKey(key),
      backgroundColor: getColorForKey(key),
      fill: false
    }));
  }, [data]);
  const yScaleValueFormatter = useCallback((value: number | string) => {
    return formatPercentage(Number(value), { maximumFractionDigits: 5 });
  }, []);

  const xScaleValueFormatter = useCallback((value: number | string) => {
    return formatCurrency(Number(value), { currency: 'USD' });
  }, []);
  const chartConfig = useMemo(
    () => ({
      options: {
        fill: false,
        aspectRatio: 3,
        scales: {
          x: {
            type: 'linear' as const
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
              color: theme.colors.muted,
              boxWidth: 20
            }
          },
          tooltip: {
            itemSort: (a: TooltipItem<'line'>, b: TooltipItem<'line'>) => {
              return b.parsed.y - a.parsed.y;
            }
          }
        }
      }
    }),
    [theme.colors.muted]
  );

  const config = useChartBackground(chartConfig);

  if (error) {
    throw error;
  }

  if (!data) {
    return <ChartSkeleton />;
  }

  const slippageTitle = (
    <Flex gap="3xs" alignItems="center">
      <Text
        borderRight="1px solid"
        borderColor={theme.colors.grey35}
        paddingRight="3xs">
        Slippage
      </Text>
      <InfoCircleIcon size="0" color={theme.colors.primary} />
      <Text size="2" color={theme.colors.muted}>
        Slippage data is sourced from 1inch API and may not reflect reality
      </Text>
    </Flex>
  );

  return (
    <Tabs
      tabs={[
        {
          key: 'slippages',
          // @ts-ignore
          title: slippageTitle,
          content: (
            <LineChart
              datasets={datasets}
              config={config}
              valueFormatter={() => null}
              subValueFormatter={() => null}
              tooltip={{
                labelFormatter: (value) => `${value}`,
                valueFormatter: (value) => `${formatPercentage(Number(value))}`,
                titleFormatter: (_value, tootlipItems) =>
                  // @ts-ignore
                  `Estimated price impact at ${formatCurrency(Number(tootlipItems[0].raw.x), { currency: 'USD' })}:`
              }}
              yScaleValueFormatter={yScaleValueFormatter}
              xScaleValueFormatter={xScaleValueFormatter}
              showKpisAsTooltip
            />
          )
        }
      ]}
      activeTab="slippages"
      setActiveTab={() => null}
    />
  );
}
