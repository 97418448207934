import { Fragment, ReactNode, useEffect, useRef } from 'react';
import { Box, CrossIcon, ThemedComponentWrapper } from '@blockanalitica/ui';

type ModalProps = {
  title?: string;
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
  closeIconSize?: string | number;
};

export default function Modal({
  title,
  isOpen,
  close,
  children,
  closeIconSize = '1'
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        close();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [close]);

  return (
    <Fragment>
      {isOpen ? (
        <ThemedComponentWrapper name="modalBackground">
          <ThemedComponentWrapper ref={modalRef} name="modal">
            <ThemedComponentWrapper name="modalHeader">
              <Box>{title}</Box>
              <Box hover={{ color: 'colors.linkHover', cursor: 'pointer' }}>
                <CrossIcon size={closeIconSize} onClick={() => close()} />
              </Box>
            </ThemedComponentWrapper>
            {children}
          </ThemedComponentWrapper>
        </ThemedComponentWrapper>
      ) : null}
    </Fragment>
  );
}
