import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';

export default function BorrowHandIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      width={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      {...props}>
      <path
        d="M12.5295 7.40746C11.9571 7.81555 11.2566 8.0556 10.5 8.0556C8.567 8.0556 7 6.4886 7 4.5556C7 2.62261 8.567 1.0556 10.5 1.0556C11.753 1.0556 12.8522 1.71402 13.4705 2.70375M5 19.1428H7.61029C7.95063 19.1428 8.28888 19.1833 8.61881 19.2642L11.3769 19.9345C11.9753 20.0803 12.5988 20.0944 13.2035 19.977L16.253 19.3837C17.0585 19.2268 17.7996 18.841 18.3803 18.2761L20.5379 16.1773C21.154 15.579 21.154 14.608 20.5379 14.0087C19.9832 13.469 19.1047 13.4083 18.4771 13.8659L15.9626 15.7005C15.6025 15.9637 15.1643 16.1054 14.7137 16.1054H12.2855L13.8311 16.1054C14.7022 16.1054 15.4079 15.4189 15.4079 14.5715V14.2647C15.4079 13.5611 14.9156 12.9476 14.2141 12.7775L11.8286 12.1973C11.4404 12.1032 11.0428 12.0556 10.6431 12.0556C9.67833 12.0556 7.93189 12.8544 7.93189 12.8544L5 14.0805M19 5.5556C19 7.4886 17.433 9.0556 15.5 9.0556C13.567 9.0556 12 7.4886 12 5.5556C12 3.62261 13.567 2.0556 15.5 2.0556C17.433 2.0556 19 3.62261 19 5.5556ZM1 13.6556L1 19.4556C1 20.0157 1 20.2957 1.10899 20.5096C1.20487 20.6978 1.35785 20.8507 1.54601 20.9466C1.75992 21.0556 2.03995 21.0556 2.6 21.0556H3.4C3.96005 21.0556 4.24008 21.0556 4.45399 20.9466C4.64215 20.8507 4.79513 20.6978 4.89101 20.5096C5 20.2957 5 20.0157 5 19.4556V13.6556C5 13.0956 5 12.8155 4.89101 12.6016C4.79513 12.4135 4.64215 12.2605 4.45399 12.1646C4.24008 12.0556 3.96005 12.0556 3.4 12.0556L2.6 12.0556C2.03995 12.0556 1.75992 12.0556 1.54601 12.1646C1.35785 12.2605 1.20487 12.4135 1.10899 12.6016C1 12.8155 1 13.0956 1 13.6556Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

BorrowHandIcon.displayName = '<BorrowHandIcon />';
