import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';

export default function MenuIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 15"
      width={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      {...props}>
      <path
        d="M1 7.39999H19M1 1.39999H19M7 13.4H19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

MenuIcon.displayName = '<MenuIcon />';
