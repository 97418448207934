import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

const Button = styled.button<{ width?: string }>`
  ${({ theme, width }) => css`
    border: none;
    padding: ${theme.space['xs']};
    border-radius: ${theme.sizes['-4']};    background: linear-gradient(
      90deg,
      ${theme.colors.primary} 0%,
      ${theme.colors.linkHover} 100%
    );
    color: ${theme.colors.black};
    width: ${width};
    text-align: center;
    height: 100%;
    transition:
      0.5s ease-in-out,
      transform 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.07); /* Slight zoom effect on hover */
      background: linear-gradient(
        90deg,
        ${theme.colors.linkHover} 0%,
        ${theme.colors.primary} 100%
      );
    &:disabled {

    ${(props) => css`
      border-color: ${props.theme.colors.border};
      background: linear-gradient(
        90deg,
        ${theme.colors.primary} 0%,
        ${theme.colors.linkHover} 100%
      );
    `}

    &:hover {
      cursor: not-allowed;
      transform: scale(1);

    }
    }
  `}
`;

export interface HomepageApplyButtonProps {
  children: ReactNode | ReactNode[];
  width?: string;
  disabled?: boolean;
}

export default function HomepageApplyButton({
  children,
  width = 'auto',
  ...rest
}: HomepageApplyButtonProps) {
  return (
    // pass width as 100% to make the button full width
    <Button width={width} {...rest}>
      {children}
    </Button>
  );
}
