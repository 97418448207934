import {
  PercentageValue,
  Flex,
  CryptoIcon,
  CurrencyValue,
  InfoTooltip,
  DataTable,
  Box,
  Text
} from '@blockanalitica/ui';
import { EntityName, Link } from '@/components';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';
import type { EstimatorPool } from './LeverageOptimizer';
import { protocolToTitleCase } from '@/utils/utils';
import { useTheme } from 'styled-components';

type SupplyRatesEstimatorTableProps = {
  results: EstimatorPool[];
  totalRecords: number;
  pagination: {
    currentPage: number;
    pageSize: number;
    totalRecords: number;
  };
  sorting: {
    currentSorting: string;
    onSortingChange: (sorting: string) => void;
  };
};

export default function LeverageOptimizerTable({
  results,
  pagination,
  sorting,
  totalRecords
}: SupplyRatesEstimatorTableProps) {
  const theme = useTheme();

  const columns: TableColumn<EstimatorPool>[] = [
    {
      header: 'Pool',
      getCell: (row) => (
        <EntityName
          symbol1={<CryptoIcon name={row.collateral_symbol} size="3" />}
          symbol2={<CryptoIcon name={row.underlying_symbol} size="3" />}>
          <Flex gap="3xs" flexDirection="column">
            <Box>{row.pool}</Box>
            <Box color="muted" fontSize="-1">
              {protocolToTitleCase(row.protocol)}
            </Box>
          </Flex>
        </EntityName>
      )
    },
    {
      header: 'Net Apy',
      getCell: (row) => (
        <PercentageValue
          value={row.net_apy}
          options={{
            tooltip: true,
            tooltipProps: {
              customContent: (
                <Flex gap="xs" flexDirection="column">
                  <Flex gap="xs" justifyContent="space-between">
                    Collateral Token Yield:{' '}
                    <PercentageValue value={row.net_native_token_apy} />
                  </Flex>
                  <Flex gap="xs" justifyContent="space-between">
                    Supply APY: <PercentageValue value={row.net_supply_apy} />
                  </Flex>
                  <Flex gap="xs" justifyContent="space-between">
                    Borrow APY: <PercentageValue value={row.net_borrow_apy} />
                  </Flex>
                </Flex>
              )
            }
          }}
        />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'net_apy'
    },
    {
      header: 'Leverage',
      getCell: (row) => <>{row.real_leverage}x</>,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'real_leverage'
    },
    {
      header: 'Max Leverage',
      getCell: (row) => <>{row.max_leverage}x</>,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'max_leverage'
    },
    {
      header: 'Health Rate',
      getCell: (row) => <>{row.health_rate}</>,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'health_rate'
    },
    {
      header: 'Liquidation Price',
      getCell: (row) => (
        <CurrencyValue
          value={row.liquidation_price}
          options={{ currency: 'USD' }}
        />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'liquidation_price'
    },
    {
      header: 'Total Collateral',
      getCell: (row) => (
        <CurrencyValue
          value={row.real_collateral_amount_usd}
          options={{ currency: 'USD' }}
        />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'real_collateral_amount_usd',
      getCellBottom: (row) => (
        <>
          {row.supply_cap_reached && (
            <Flex gap="3xs" color={theme.colors.limit}>
              <InfoTooltip text="Supply cap reached." />
              <Text size="2">Limit Reached</Text>
            </Flex>
          )}{' '}
        </>
      )
    },
    {
      header: 'Total Debt',
      getCell: (row) => (
        <CurrencyValue
          value={row.real_borrow_amount_usd}
          options={{ currency: 'USD' }}
        />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'real_borrow_amount_usd',
      getCellBottom: (row) => (
        <>
          {row.max_borrow_reached && (
            <Flex gap="3xs" color={theme.colors.limit}>
              <InfoTooltip text="Total supply or borrow cap reached." />
              <Text size="2">Limit Reached</Text>
            </Flex>
          )}{' '}
        </>
      )
    }
  ];

  return (
    <DataTable
      rows={results}
      columns={columns}
      getKey={(row) =>
        `${row.protocol}${row.underlying_symbol}${row.collateral_symbol}${row.pool_id}${row.max_leverage}`
      }
      sorting={sorting}
      rowLinkProps={{
        to: (row) => `/${row.protocol}/${row.pool_id}`,
        // @ts-ignore
        linkComponent: Link
      }}
      pagination={{
        ...pagination,
        totalRecords: totalRecords
      }}
    />
  );
}
