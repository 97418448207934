import {
  Card,
  Flex,
  KpiHeader,
  CryptoIcon,
  useIsMobile,
  KpiCard
} from '@blockanalitica/ui';
import { EntityList } from '@/components';

export type TotalSupply = {
  total_supply: string;
  total_supply_change: string | null;
  total_supply_change_percentage: string | null;
};

export default function SupplyKpis({ data }: { data: TotalSupply }) {
  const isMobile = useIsMobile();

  return (
    <Flex flexDirection={['column', 'row']} gap="xs">
      <KpiCard
        title="Total Stablecoin Supply"
        field="total_supply"
        data={data}
        options={{
          currency: 'USD'
        }}
        extra={
          <Flex
            flexDirection="column"
            gap="xs"
            alignItems="flex-end"
            // @ts-ignore
            display={isMobile ? 'none' : 'flex'}>
            <KpiHeader>Supported Protocols</KpiHeader>
            <EntityList showTotalNumber={false} entitySize="3">
              <CryptoIcon name="skyprotocol" size="5" />
              <CryptoIcon name="sparkprotocol" size="5" />
              <CryptoIcon name="aaveprotocol" size="5" />
              <CryptoIcon name="Morpho" size="5" />
              <CryptoIcon name="COMP" size="5" />
              <CryptoIcon name="fluid" size="5" />
            </EntityList>
          </Flex>
        }
      />
      <Card
        // @ts-ignore
        display={isMobile ? 'flex' : 'none'}>
        <Flex flexDirection="column" gap="xs">
          <KpiHeader>Supported Protocols</KpiHeader>
          <EntityList showTotalNumber={false} entitySize="3">
            <CryptoIcon name="skyprotocol" size="5" />
            <CryptoIcon name="sparkprotocol" size="5" />
            <CryptoIcon name="aaveprotocol" size="5" />
            <CryptoIcon name="Morpho" size="5" />
            <CryptoIcon name="COMP" size="5" />
            <CryptoIcon name="fluid" size="5" />
          </EntityList>
        </Flex>
      </Card>
    </Flex>
  );
}
