import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';
export default function StockDownIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      {...props}>
      <path
        d="M19 19.0556H2.6C2.03995 19.0556 1.75992 19.0556 1.54601 18.9466C1.35785 18.8508 1.20487 18.6978 1.10899 18.5096C1 18.2957 1 18.0157 1 17.4556V1.05562M19 13.0556L13.5657 7.6213C13.3677 7.42329 13.2687 7.32429 13.1545 7.2872C13.0541 7.25457 12.9459 7.25457 12.8455 7.2872C12.7313 7.32429 12.6323 7.4233 12.4343 7.6213L10.5657 9.48993C10.3677 9.68794 10.2687 9.78695 10.1545 9.82404C10.0541 9.85667 9.94591 9.85667 9.84549 9.82404C9.73133 9.78695 9.63232 9.68794 9.43431 9.48993L5 5.05562M19 13.0556H15M19 13.0556V9.05562"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

StockDownIcon.displayName = '<StockDownIcon />';
