import { EtherScanPictogram, Flex } from '@blockanalitica/ui';

type EtherscanLinkProps = {
  value: string;
  network: string;
  type?: 'tx' | 'address';
  size?: string;
};

const urlMap: Record<string, string> = {
  ethereum: 'https://etherscan.io/'
};

export default function EtherscanLink({
  value,
  network,
  type = 'tx',
  size = '0'
}: EtherscanLinkProps) {
  return (
    <Flex
      as="a"
      // @ts-ignore
      href={`${urlMap[network]}${type}/${value}`}
      target="_blank"
      rel="noreferrer"
      alignItems="center">
      <EtherScanPictogram size={size} />
    </Flex>
  );
}
