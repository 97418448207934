export const CHART_LINE_COLORS = [
  '#F94144',
  '#F3722C',
  '#F8961E',
  '#F9C74F',
  '#90BE6D',
  '#43AA8B',
  '#577590',
  '#277DA1',
  '#4D908E',
  '#F9844A',
  '#EE6352',
  '#6A4C93',
  '#726DA8',
  '#E9C46A',
  '#2A9D8F',
  '#264653',
  '#E76F51',
  '#9B5DE5',
  '#00BBF9',
  '#8338EC',
  '#3A86FF',
  '#FB5607',
  '#FF006E',
  '#FFD60A',
  '#06D6A0',
  '#3D405B',
  '#FFB703',
  '#8ECAE6',
  '#219EBC',
  '#023047',
  '#FFB5A7',
  '#FF9F1C',
  '#F15BB5',
  '#9D4EDD',
  '#E36414',
  '#5A189A',
  '#8AC926',
  '#FF595E',
  '#3A0CA3',
  '#6D597A',
  '#34A0A4',
  '#BFDBF7',
  '#F7C8E0',
  '#2EC4B6',
  '#8338EC',
  '#A9DEF9',
  '#E5989B',
  '#9A031E',
  '#FFB4A2',
  '#355070',
  '#F25F5C',
  '#005F73',
  '#0A9396'
];

export const PROTOCOLS_WITHOUT_IRM = ['sky', 'liquity_v2'];
export const BORROW_ONLY_PROTOCOLS = ['sky', 'liquity_v2'];
