import { useState } from 'react';
import { Modal } from '@/components';
import {
  Flex,
  Box,
  BoxProps,
  ChevronUpIcon,
  ChevronDownIcon,
  Text
} from '@blockanalitica/ui';
import styled, { css } from 'styled-components';
const Container = styled(Box)<BoxProps>`
  cursor: pointer;
  ${(props) => css`
    background-color: ${props.theme.colors.border};
    padding: ${props.theme.space['2xs']};
    border-radius: ${props.theme.sizes['-2']};
    border: 1px solid ${props.theme.colors.border};
    outline: none;
    &:hover {
      border: 1px solid ${props.theme.colors.primary};
    }
  `}
`;

export default function CollateralModal({
  content,
  title
}: {
  content: React.ReactNode;
  title: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const iconSize = '-1';
  return (
    <>
      <Container onClick={() => setIsModalOpen(true)}>
        <Flex gap="2xs" alignItems="center">
          <Text size="3">View All</Text>
          {isModalOpen ? (
            <ChevronUpIcon size={iconSize} />
          ) : (
            <ChevronDownIcon size={iconSize} />
          )}
        </Flex>
      </Container>
      <Box color="text">
        <Flex>
          <Modal
            isOpen={isModalOpen}
            close={() => setIsModalOpen(false)}
            title={title}>
            {content}
          </Modal>
        </Flex>
      </Box>
    </>
  );
}
