import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function modalBackground(theme: Theme): ComponentStyle {
  return {
    componentName: 'modalBackground',
    baseStyle: {
      width: '100%',
      height: '100%',
      background: theme.colors.black65,
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '99',
      backdropFilter: 'blur(6px)',
      '-webkitBackdropFilter': 'blur(6px)'
    },
    variants: {}
  };
}
