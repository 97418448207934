import { Fragment, ReactNode } from 'react';
import {
  Box,
  ThemedComponentWrapper,
  ThemedComponentWrapperProps
} from '@blockanalitica/ui';
interface CollapsableSidebarProps extends Partial<ThemedComponentWrapperProps> {
  sidebarOpen: boolean;
  toggleSidebar: (value?: boolean) => void;
  children: ReactNode;
}

export default function CollapsableSidebar({
  sidebarOpen,
  toggleSidebar,
  children,
  ...rest
}: CollapsableSidebarProps) {
  return (
    <Fragment>
      <ThemedComponentWrapper
        flexDirection="column"
        as="div"
        variant={!sidebarOpen ? 'closed' : undefined}
        {...rest}
        name="collapsableSidebar">
        {children}
      </ThemedComponentWrapper>

      {sidebarOpen ? (
        <Box onClick={() => toggleSidebar()} name="alphaBackgroundLayer" />
      ) : null}
    </Fragment>
  );
}
