import { ReactNode, useMemo } from 'react';
import { Flex, FlexProps } from '@blockanalitica/ui';
import styled, { css } from 'styled-components';

const BottomCell = styled(Flex)<FlexProps>`
  ${(props) => css`
    font-size: ${props.theme.sizes['-1']};
    color: ${props.theme.colors.muted};
  `}
`;

interface TableCellBottomProps {
  checkValue?: string | number | null;
  children?: ReactNode;
}

export default function TableCellBottom({
  checkValue,
  children,
  ...rest
}: TableCellBottomProps) {
  const show = useMemo(() => {
    if (checkValue === undefined) {
      return true;
    }

    let value: number;
    if (typeof checkValue !== 'number') {
      value = Number(checkValue);
    } else {
      value = checkValue;
    }

    if (isNaN(value) || value === 0) {
      return false;
    }

    return true;
  }, [checkValue]);

  if (!show) {
    return null;
  }

  return <BottomCell {...rest}>{children}</BottomCell>;
}
