import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function dropdownHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'dropdownHeader',
    baseStyle: {
      gap: theme.space.xs,
      backgroundColor: theme.colors.grey10,
      border: 'none',
      padding: theme.space['xs']
    },
    variants: {
      secondary: {
        border: `solid 1px ${theme.colors.border}`,
        borderRadius: theme.sizes['-4'],
        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      },
      ratesSection: {
        border: `solid 1px ${theme.colors.border}`,
        fontSize: theme.sizes['0'],
        color: theme.colors.muted,
        paddingTop: `${theme.sizes['-5']}`,
        borderRadius: theme.sizes['-4'],
        paddingBottom: `${theme.sizes['-5']}`,
        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      },
      leverageOptimizer: {
        border: `solid 1px ${theme.colors.grey35}`,
        backgroundColor: theme.colors.purple,
        borderBottom: `1px dashed ${theme.colors.grey35}`,
        borderRadius: `${theme.sizes['-4']} ${theme.sizes['-4']} 0px 0px}`,
        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      },
      leverageOptimizerProtocols: {
        border: `solid 1px ${theme.colors.grey35}`,
        backgroundColor: theme.colors.purple,
        borderRadius: `${theme.sizes['-4']}`,
        fontSize: theme.sizes['0'],
        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      },
      liquidations: {
        border: `1px solid ${theme.colors.grey35}`,
        borderRadius: theme.sizes['-2'],
        backgroundColor: theme.colors.grey20,
        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      }
    }
  };
}
