export const colors = {
  background: '#060507',
  foreground: '#20203E',
  muted: '#7a7ea0',
  text: '#f7f9fc',
  black: '#060507',
  primary: '#8585FF',
  success: '#1dd9ba',
  error: '#ff6d6d',
  warning: '#ff832b',
  link: '#8585FF',
  linkHover: '#5e5eff',
  transparent: 'transparent',
  border: '#35384b',
  tooltip: '#1c1828',
  white85: 'rgba(255, 255, 255, 0.85)',
  white: '#ffffff',
  chartLine: '#8585FF',
  chartIndicatorLine: '#909090',
  chartUp: '#45C8E5',
  chartDown: '#A979D8',
  tableRowHover: '#222230',
  tabInactive: '#222230',
  datepicker: '#4B4B67',
  white_figma: '#E8EEF7',
  gradient_bot: '#5B5B7C',
  skeleton: {
    baseColor: '#13131E',
    highlightColor: '#1c1c2c'
  },
  purple: '#1C1C36',
  limit: '#FFAA33',
  onSurface: '#13131E',
  grey10: '#13131E',
  grey15: '#20202C',
  grey20: '#2B2B3B',
  grey25: '#36364A',
  grey35: '#4B4B68',
  grey69: '#A3A3BD',
  grey85: '#D3D3DF',
  grey45: '#606085',
  tableStickyBg: '#13131E',
  black65: 'rgba(0, 0, 0, 0.65)',
  black23: 'rgba(0, 0, 0, 0.23)',
  muted10: 'rgba(182, 185, 255, 0.1)'
};
