import {
  ChartTimeFilter,
  DropdownAssetsFilter,
  RateHistoryChartProps
} from '@/components';
import {
  LineChart,
  Flex,
  Box,
  formatPercentage,
  Dropdown,
  DropdownOption,
  formatDatetime
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { protocolToTitleCase, generateColorFromPool } from '@/utils/utils';
import type { TooltipItem } from 'chart.js';
import { useChartBackground } from '@/hooks';

export default function BorrowRatesChart({
  data,
  callbacks,
  filters
}: RateHistoryChartProps) {
  const theme = useTheme();
  const datasets = useMemo(() => {
    const groupedByPool = data.reduce(
      (grouped, entry) => {
        const pool_id = `${entry.protocol} ${entry.pool_name}`; // Construct pool_id

        if (!grouped[pool_id]) {
          grouped[pool_id] = true;
        }

        return grouped;
      },
      {} as Record<string, boolean>
    );

    return Object.keys(groupedByPool).map((pool_id) => {
      const [protocol, ...poolNameParts] = pool_id.split(' ');
      const pool_name = poolNameParts.join(' ');
      const poolData = data.filter(
        (entry) => `${entry.protocol} ${entry.pool_name}` === pool_id
      );

      const color = generateColorFromPool(pool_id);
      const formattedLabel = protocolToTitleCase(protocol);

      return {
        label: `${formattedLabel} - ${pool_name}`,
        borderColor: color,
        xField: 'date',
        yField: 'rate',
        data: poolData,
        borderWidth: 1,
        backgroundColor: color,
        fill: false
      };
    });
  }, [data]);
  const yScaleValueFormatter = useCallback((value: number | string) => {
    return formatPercentage(Number(value), { maximumFractionDigits: 5 });
  }, []);

  const chartConfig = useMemo(
    () => ({
      options: {
        // @ts-ignore
        fill: false,
        aspectRatio: 3,
        plugins: {
          legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
              color: theme.colors.muted,
              boxWidth: 20
            }
          },
          tooltip: {
            itemSort: (a: TooltipItem<'line'>, b: TooltipItem<'line'>) => {
              return b.parsed.y - a.parsed.y;
            }
          }
        }
      }
    }),
    [theme.colors.muted]
  );

  const config = useChartBackground(chartConfig);

  return (
    <>
      <Flex
        flexDirection={['column', 'row']}
        gap="xs"
        justifyContent="flex-end">
        <Flex
          gap="xs"
          flexWrap={['wrap', 'nowrap']}
          justifyContent={['center', 'flex-end']}>
          <DropdownAssetsFilter
            initialValue={filters.protocolsFiltered}
            titleFormat={(filteredCount) =>
              `Protocols filtered (${filteredCount}/${filters.protocolsOptions.length})`
            }
            callback={callbacks.filterByProtocol}
            key="protocolsFilter"
            options={filters.protocolsOptions}
          />
          <DropdownAssetsFilter
            initialValue={filters.tokensFiltered}
            titleFormat={(filteredCount) =>
              `Tokens filtered (${filteredCount}/${filters.tokensOptions.length})`
            }
            callback={callbacks.filterByToken}
            key="tokensFilter"
            options={filters.tokensOptions}
          />
          <DropdownAssetsFilter
            initialValue={filters.collateralTokensFiltered}
            titleFormat={(filteredCount) =>
              `Collateral filtered (${filteredCount}/${filters.collateralTokensOptions.length})`
            }
            callback={callbacks.filterByCollateralToken}
            key="collateralFilter"
            options={filters.collateralTokensOptions}
          />
          <Dropdown
            onChange={callbacks.changeApyTimeframe}
            iconSize="-2"
            initialValue={filters.apyTimeframe}
            options={{
              variants: {
                dropdownHeader: 'secondary'
              }
            }}
            key="apyTimeframe">
            {filters.apyTimeframeOptions.map(({ key, value }) => (
              <DropdownOption key={key} value={key} variant="secondary">
                <Flex gap="3xs" alignItems="center">
                  <Box>{value}</Box>
                </Flex>
              </DropdownOption>
            ))}
          </Dropdown>
        </Flex>
        <Flex>
          <ChartTimeFilter
            key="timeFilter"
            activeOption={filters.timeFilter}
            onChange={callbacks.filterByTime}
            justifyContent={['center', 'flex-end']}
          />
        </Flex>
      </Flex>

      <LineChart
        datasets={datasets}
        config={config}
        valueFormatter={() => null}
        subValueFormatter={() => null}
        yScaleValueFormatter={yScaleValueFormatter}
        tooltip={{
          labelFormatter: (value) => `${value}`,
          valueFormatter: (value) => `${formatPercentage(Number(value))}`,
          titleFormatter: (_value, tootlipItems) =>
            // @ts-ignore
            `${formatDatetime(tootlipItems[0].raw.x, { format: 'MMM dd, yyyy' })}`
        }}
        showKpisAsTooltip
        daysAgo={filters.timeFilter}
      />
    </>
  );
}
