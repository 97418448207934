import { useApi } from '@/api';
import {
  ChartSkeleton,
  Flex,
  formatCurrency,
  Heading,
  SankeyChart
} from '@blockanalitica/ui';
interface Flow {
  from: string;
  to: string;
  flow: string;
  percentage: string;
}

function transformToSankeyData(flows: Flow[]) {
  return {
    datasets: [
      {
        label: '',
        data: flows.map((item) => ({
          from: item.from,
          // hack for showing the flow from WETH -> WETH otherwise the flow doesn't exist
          to: item.to === item.from ? item.from + ' ' : item.to,
          flow: Number(item.flow)
        })),
        // colorMode: 'gradient' as 'gradient',
        // colorFrom: (ctx: { dataIndex: number }) =>
        //   getTokenColor(flows[ctx.dataIndex].from),
        // colorTo: (ctx: { dataIndex: number }) =>
        //   getTokenColor(flows[ctx.dataIndex].to),
        color: 'white'
      }
    ]
  };
}
export default function BackedSankeyChart() {
  const [data, error] = useApi<Flow[]>({
    url: `/usds-backed/sankey/`
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return (
      <Flex flexDirection="column" gap="xs">
        <ChartSkeleton />
      </Flex>
    );
  }

  if (data.length === 0) {
    return null;
  }

  const sankeyData = transformToSankeyData(data);

  const config = {
    options: {
      interaction: {
        mode: 'nearest',
        intersect: false
      },
      aspectRatio: 3,
      plugins: {
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context: { dataIndex: number }) => {
              const flow = data[context.dataIndex];
              return `${flow.from} -> ${flow.to}: ${formatCurrency(
                Number(flow.flow)
              )} (${flow.percentage}%)`;
            }
          }
        }
      }
    }
  };

  return (
    <Flex flexDirection="column" gap="xs">
      <Heading>USDS Backing</Heading>
      <SankeyChart data={sankeyData} config={config} />
    </Flex>
  );
}
