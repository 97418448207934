import { SVGProps } from 'react';
import Svg from './Svg';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';

export default function SavingsIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      {...props}>
      <path
        d="M7.39128 15.1345H5.68359C5.823 17.8006 8.05344 20.1182 12.009 20.1182C15.9471 20.1182 18.4564 17.9574 18.4564 15.1694C18.4564 13.1829 17.0101 11.0047 13.5599 9.8198C11.312 9.05308 10.4581 8.07726 10.4581 7.10144C10.4581 6.19532 11.19 5.23692 12.6886 5.23692C14.3614 5.23692 16.2434 6.54383 16.3828 8.21666H18.073C17.8988 5.51573 15.2675 3.63379 11.9219 3.63379C8.45423 3.63379 5.84042 5.65513 5.84042 8.51289C5.84042 10.6214 7.23445 12.573 10.5801 13.7405C12.8803 14.4898 13.8387 15.4308 13.8387 16.6157C13.8387 17.6612 13.0894 18.5325 11.4688 18.5325C9.44747 18.5325 7.51326 17.121 7.39128 15.1345ZM12.7234 11.2661C16.1911 12.4336 16.7487 14.1761 16.7487 15.2565C16.7487 16.5808 15.8774 17.7483 14.8319 18.0446C15.1978 17.6961 15.4767 16.9293 15.4767 16.1975C15.4767 14.9429 14.6402 13.4268 11.5385 12.3465C7.96632 11.1267 7.53068 9.45386 7.53068 8.46062C7.53068 7.04916 8.4368 5.91651 9.56945 5.56801C9.13382 6.07334 8.82016 6.80521 8.82016 7.50222C8.82016 8.7917 9.88311 10.3251 12.7234 11.2661Z"
        fill={color}
      />
    </Svg>
  );
}
