import { ErrorBoundary, Layout } from '@/components';
import HomePage from '@/pages';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router';
import PoolPage from './pages/pool';
import ProtocolPage from './pages/protocol';
import StablecoinBorrowPage from './pages/stablecoinBorrow';
import NetRatesEstimatorPage from './pages/leverageOptimizer';
import StablecoinSupplyPage from './pages/stablecoinSupply';
import USDSBackingPage from './pages/usdsBacking';
import LiquidationsPage from './pages/liquidations';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: (
      <Layout>
        <ErrorBoundary />
      </Layout>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/supply',
        element: <StablecoinSupplyPage />
      },
      {
        path: '/borrow',
        element: <StablecoinBorrowPage />
      },
      {
        path: '/:protocol/:poolId',
        element: <PoolPage />
      },
      {
        path: '/:protocol',
        element: <ProtocolPage />
      },
      {
        path: '/leverage',
        element: <NetRatesEstimatorPage />
      },
      {
        path: '/usds-backing',
        element: <USDSBackingPage />
      },
      {
        path: '/liquidations',
        element: <LiquidationsPage />
      }
    ]
  }
]);

export default router;
