import { useApi } from '@/api';

type ProtocolsRef = {
  key: string;
  value: string;
  icon?: string;
};

type TokensRef = {
  label: string;
  value: string;
  protocol: string;
};

interface OptionsData {
  protocols: ProtocolsRef[];
  loan_assets: TokensRef[];
  collateral_assets: TokensRef[];
}

type LiquidationDropdownFiltersProps = {
  protocols: string[];
};

export default function LiquidationDropdownOptions({
  protocols
}: LiquidationDropdownFiltersProps) {
  const [data, error] = useApi<OptionsData>({
    url: '/liquidations/options/',
    queryParams: {
      protocols: protocols.join(',')
    }
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return {
      protocolOptions: [],
      collOptions: [],
      debtOptions: []
    };
  }

  return {
    protocolOptions: data.protocols,
    collOptions: data.collateral_assets,
    debtOptions: data.loan_assets
  };
}
