import { Flex, KpiCard } from '@blockanalitica/ui';

type PoolKpisData = {
  total_supply: number | string;
  total_debt: number | string;
  underlying_symbol: string;
  underlying_address: string;
  network: string;
};

export default function PoolKpis({ data }: { data: PoolKpisData }) {
  return (
    <Flex gap={['m', 's']} flexDirection={['column', 'row']}>
      <KpiCard
        title="Total Supply"
        data={data}
        field="total_supply"
        options={{
          valueOptions: {
            currencyValueOptions: {
              currency: {
                name: data.underlying_symbol,
                address: data.underlying_address,
                network: data.network
              }
            }
          },
          variants: { kpiCardFooter: 'muted' }
        }}
      />
      <KpiCard
        title="Total Borrow"
        data={data}
        field="total_debt"
        options={{
          trend: true,
          valueOptions: {
            currencyValueOptions: {
              currency: {
                name: data.underlying_symbol,
                address: data.underlying_address,
                network: data.network
              }
            }
          },
          variants: { kpiCardFooter: 'muted' }
        }}
      />
    </Flex>
  );
}
