import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function modalHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'modalHeader',
    baseStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: theme.sizes['2'],
      gap: theme.space['xs']
    },
    variants: {}
  };
}
