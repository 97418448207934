import React, {
  useState,
  ReactNode,
  ReactElement,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  JSX
} from 'react';
import {
  useToggle,
  ThemedComponentWrapper,
  ChevronDownIcon,
  ChevronUpIcon
} from '@blockanalitica/ui';
import type { ThemedComponentWrapperProps } from '@blockanalitica/ui/package/types/src/theme/ThemedComponentWrapper.d.ts';
import DropdownOption from './DropdownOption';
import type { DropdownOptionProps } from './DropdownOption';

type Size =
  | '-5'
  | '-4'
  | '-3'
  | '-2'
  | '-1'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10';

export type DropdownComponentsVariants = {
  dropdownHeader?: string;
  dropdownList?: string;
};

export interface Options {
  variants?: DropdownComponentsVariants;
}

export interface DropdownProps
  extends Partial<Omit<ThemedComponentWrapperProps, 'onChange'>> {
  children: ReactNode;
  placeholder?: string;
  initialValue?: string | string[] | null;
  iconSize?: Size | string | number;
  onChange?: (value: string | string[]) => void;
  multi?: boolean;
  titleFormat?: (count: number) => string;
  options?: Options;
  search?: boolean;
}

export default function Dropdown({
  children,
  placeholder = 'Select an option',
  initialValue = null,
  iconSize = '1',
  onChange,
  multi = false,
  titleFormat,
  options,
  search = false,
  ...rest
}: DropdownProps): JSX.Element {
  const [isOpen, toggle] = useToggle();
  const [selectedOption, setSelectedOption] = useState<string | string[]>(
    multi ? (initialValue as string[]) || [] : (initialValue as string | '')
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        toggle(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggle]);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen]);

  const onOptionClicked = useCallback(
    (value: string) => {
      if (multi) {
        setSelectedOption((prevSelected) => {
          const newSelected = (prevSelected as string[]).includes(value)
            ? (prevSelected as string[]).filter((option) => option !== value)
            : [...(prevSelected as string[]), value];

          if (onChange !== undefined) {
            onChange(newSelected);
          }
          return newSelected;
        });
      } else {
        setSelectedOption(value);
        toggle(false);
        if (onChange !== undefined) {
          onChange(value);
        }
      }
    },
    [multi, onChange, toggle]
  );

  const childrenWithProps = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement<DropdownOptionProps>(child)) {
          if (child.type === DropdownOption) {
            return React.cloneElement(child, {
              onClick: () => onOptionClicked(child.props.value),
              selected: multi
                ? (selectedOption as string[]).includes(child.props.value)
                : child.props.value === selectedOption
            });
          }
        }
        return child;
      }),
    [children, onOptionClicked, selectedOption, multi]
  );

  type DropdownOptionElement = ReactElement<DropdownOptionProps>;
  const filteredChildren = useMemo(() => {
    const getLabelText = (element: DropdownOptionElement): string =>
      element.props.label ? String(element.props.label).toLowerCase() : '';

    if (!searchQuery) return childrenWithProps;
    const lowerQuery = searchQuery.toLowerCase();
    const matching: DropdownOptionElement[] = [];

    React.Children.forEach(childrenWithProps, (child) => {
      if (React.isValidElement(child)) {
        const element = child as DropdownOptionElement;
        const labelText = getLabelText(element);
        if (labelText.includes(lowerQuery)) {
          matching.push(element);
        } else {
          return;
        }
      }
    });

    matching.sort((a, b) => getLabelText(a).localeCompare(getLabelText(b)));
    return matching;
  }, [childrenWithProps, searchQuery]);

  const displayedChildren = search ? filteredChildren : childrenWithProps;

  const selectedOptionLabel = useMemo(() => {
    if (multi) {
      const selectedCount = (selectedOption as string[]).length;
      const defaultTitle = `Items Selected (${selectedCount})`;
      return titleFormat ? titleFormat(selectedCount) : defaultTitle;
    }
    const selectedChild = React.Children.toArray(children).find(
      (child) =>
        React.isValidElement<DropdownOptionProps>(child) &&
        child.props.value === selectedOption
    ) as ReactElement<DropdownOptionProps> | undefined;
    return selectedChild ? selectedChild.props.children : placeholder;
  }, [children, selectedOption, multi, placeholder, titleFormat]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <ThemedComponentWrapper ref={dropdownRef} {...rest} name="dropdown">
      <ThemedComponentWrapper
        name="dropdownHeader"
        onClick={() => toggle()}
        variant={options?.variants?.dropdownHeader}>
        {selectedOptionLabel}
        {isOpen ? (
          <ChevronUpIcon size={iconSize} />
        ) : (
          <ChevronDownIcon size={iconSize} />
        )}
      </ThemedComponentWrapper>
      {isOpen && childrenWithProps ? (
        <ThemedComponentWrapper
          as="ul"
          name="dropdownList"
          variant={options?.variants?.dropdownList}>
          {search && (
            <ThemedComponentWrapper name="dropdownOption">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search..."
              />
            </ThemedComponentWrapper>
          )}
          {displayedChildren}
        </ThemedComponentWrapper>
      ) : null}
    </ThemedComponentWrapper>
  );
}
