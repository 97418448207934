import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';

export default function SupplyHandIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 20"
      width={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      {...props}>
      <path
        d="M5 3.46285L7.61029 3.46285C7.95063 3.46285 8.28888 3.42235 8.61881 3.34136L11.3769 2.67112C11.9753 2.52533 12.5988 2.51116 13.2035 2.6286L16.253 3.22189C17.0585 3.37882 17.7996 3.76456 18.3803 4.3295L20.5379 6.42829C21.154 7.02664 21.154 7.99757 20.5379 8.59693C19.9832 9.13656 19.1047 9.19731 18.4771 8.73969L15.9626 6.90515C15.6025 6.64191 15.1643 6.50017 14.7137 6.50017L12.2855 6.50017L13.8311 6.50024C14.7022 6.50024 15.4079 7.18667 15.4079 8.03408L15.4079 8.34085C15.4079 9.0445 14.9156 9.65803 14.2141 9.82812L11.8286 10.4083C11.4404 10.5024 11.0428 10.55 10.6431 10.55C9.67833 10.55 7.9319 9.75118 7.9319 9.75118L5 8.52512M1.00001 8.95L1.00001 3.15001C1.00001 2.58995 1.00001 2.30993 1.109 2.09602C1.20487 1.90785 1.35785 1.75487 1.54602 1.659C1.75993 1.55001 2.03995 1.55001 2.60001 1.55001L3.4 1.55001C3.96006 1.55001 4.24008 1.55001 4.454 1.659C4.64216 1.75487 4.79514 1.90785 4.89101 2.09601C5 2.30993 5 2.58995 5 3.15001L5 8.95C5 9.51005 5 9.79007 4.89101 10.004C4.79514 10.1921 4.64216 10.3451 4.454 10.441C4.24008 10.55 3.96006 10.55 3.40001 10.55L2.60001 10.55C2.03996 10.55 1.75993 10.55 1.54602 10.441C1.35786 10.3451 1.20487 10.1921 1.109 10.004C1.00001 9.79007 1.00001 9.51005 1.00001 8.95ZM16.3755 12.0557C18.3084 12.0664 19.8667 13.6422 19.8559 15.5751C19.8451 17.5081 18.2694 19.0663 16.3364 19.0555C14.4035 19.0448 12.8452 17.4691 12.856 15.5361C12.8668 13.6031 14.4425 12.0449 16.3755 12.0557Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

SupplyHandIcon.displayName = '<SupplyHandIcon />';
