import { useMemo } from 'react';
import { ChartConfig } from '@blockanalitica/ui/package/types/src/components/charts/Chart';
import _ from 'lodash';
import {
  default as ba_logo_chart
  // @ts-ignore
} from '@/assets/logos/ba_logo_chart_background.svg?raw';

export default function useChartBackground(config?: ChartConfig) {
  const chartConfig: ChartConfig = useMemo(() => {
    const defaultConfig = {
      options: {
        plugins: {
          backgroundImage: {
            image: ba_logo_chart
          }
        }
      }
    };

    return _.merge({}, defaultConfig, config);
  }, [config]);

  return chartConfig;
}
