import { Box, Flex, BoxProps, KpiCard } from '@blockanalitica/ui';
import type { KpiCardProps } from '@blockanalitica/ui/package/types/src/components/molecules/KpiCard/KpiCard';
import { ReactNode } from 'react';
import styled from 'styled-components';

const FooterKpiTitle = styled(Box)<BoxProps>`
  font-size: ${(props) => props.theme.sizes[-1]};
`;

const FooterKpiContent = styled(Box)<BoxProps>`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.sizes[0]};
`;

export type MultiKpiSub = {
  title: string;
  content: ReactNode;
};

export interface MultiKpiCardProps extends KpiCardProps {
  kpis: MultiKpiSub[];
}

export default function MultiKpiCard({ kpis, ...rest }: MultiKpiCardProps) {
  return (
    <KpiCard
      {...rest}
      footer={
        kpis ? (
          <Flex gap={['xs', '2xs']} flex="1" marginTop="2xs">
            {kpis.map((kpi) => (
              <Flex
                key={kpi.title}
                flexDirection="column"
                flex="1"
                gap={['2xs', '3xs']}>
                <FooterKpiTitle>{kpi.title}</FooterKpiTitle>
                <FooterKpiContent>{kpi.content}</FooterKpiContent>
              </Flex>
            ))}
          </Flex>
        ) : null
      }
    />
  );
}
