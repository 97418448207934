import { useApi } from '@/api';

import {
  CurrencyValue,
  DataTable,
  PercentageValue,
  TableSkeleton
} from '@blockanalitica/ui';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';

type dataData = {
  symbol: string;
  networks: string[];
  protocols: string[];
  amount_usd: string;
  percentage: string;
};

export default function BackedTable() {
  const [data, error] = useApi<dataData[]>({
    url: `/usds-backed/table/`
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  const columns: TableColumn[] = [
    {
      header: 'Asset',
      getCell: (row) => row.symbol
    },
    // {
    //   header: 'Networks',
    //   getCell: (row) => row.networks
    // },
    // {
    //   header: 'Protocols',
    //   getCell: (row) => row.protocols
    // },
    {
      header: 'Amount',
      getCell: (row) => <CurrencyValue value={row.amount_usd} />,

      cellAlign: 'flex-end',
      headerAlign: 'flex-end'
    },
    {
      header: 'Share',
      getCell: (row) => <PercentageValue value={row.percentage} />,

      cellAlign: 'flex-end',
      headerAlign: 'flex-end'
    }
  ];
  return (
    <>
      <DataTable
        rows={data}
        columns={columns}
        getKey={(row) => `${row.symbol}`}
      />
    </>
  );
}
