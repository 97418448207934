import { PaginatedResponse, useApi } from '@/api';
import {
  PercentageValue,
  CryptoIcon,
  DataTable,
  TableSkeleton,
  useTableProps,
  Card
} from '@blockanalitica/ui';
import { EntityName } from '@/components';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';

type Collateral = {
  underlying_address: string;
  underlying_symbol: string;
  liquidation_threshold: string;
  ltv: string;
  max_leverage: string;
  liquidation_bonus: string;
  supply_apy: number;
  network: string;
};

export default function PoolCollateralsTable({
  poolId,
  protocol
}: {
  poolId: string;
  protocol: string;
}) {
  const { pagination, sorting } = useTableProps({
    pageSize: 5,
    initialSorting: '-max_leverage',
    filtersDebounceTime: 50
  });

  const [data, error] = useApi<PaginatedResponse<Collateral[]>>({
    url: `/pool/${protocol}/${poolId}/collateral/`,
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton />;
  }
  const columns: TableColumn<Collateral>[] = [
    {
      header: 'Collateral',
      getCell: (row) => (
        <EntityName
          symbol1={
            <CryptoIcon
              name={row.underlying_symbol}
              address={row.underlying_address}
              network={row.network}
              size="2"
            />
          }>
          {row.underlying_symbol}
        </EntityName>
      )
    },
    {
      header: 'Max Leverage',
      getCell: (row) => <>{row.max_leverage ? `${row.max_leverage}x` : '-'}</>,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'max_leverage'
    },
    {
      header: 'Liquidation Threshold',
      getCell: (row) => <PercentageValue value={row.liquidation_threshold} />,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'liquidation_threshold'
    },
    {
      header: 'LTV',
      getCell: (row) => <PercentageValue value={row.ltv} />,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'ltv'
    },
    {
      header: 'Liquidation Bonus',
      getCell: (row) => <PercentageValue value={row.liquidation_bonus} />,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'liquidation_bonus'
    },
    {
      header: 'Supply APY',
      getCell: (row) => <PercentageValue value={row.supply_apy} />,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'supply_apy'
    }
  ];
  return (
    <Card>
      <DataTable
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.underlying_symbol + row.ltv}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    </Card>
  );
}
