import { BarChart, CurrencyValue, ChartSkeleton } from '@blockanalitica/ui';
import { ChartConfig } from '@blockanalitica/ui/package/types/src/components/charts/Chart';
import { SimpleChartData } from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { useMemo, useCallback } from 'react';
import { useChartBackground } from '@/hooks';
import { getAssetColor } from '@/utils/utils';
export interface CollateralAssetData {
  collateral_underlying_symbol: string;
  amount_usd: string;
}

export default function CollateralAssetLiquidationsGraph({
  data,
  chartDaysAgo
}: {
  data: CollateralAssetData[];
  chartDaysAgo?: string;
}) {
  const datasets = useMemo(() => {
    if (!data) return [];

    return [
      {
        xField: 'collateral_underlying_symbol',
        yField: 'amount_usd',
        label: 'Assets',
        backgroundColor: data.map(
          (asset) => getAssetColor(asset.collateral_underlying_symbol) || '#fff'
        )
      }
    ];
  }, [data]);

  const xScaleValueFormatter = useCallback(
    (value: string | number) => {
      return data[Number(value)].collateral_underlying_symbol;
    },
    [data]
  );

  const subValueFormatter = useCallback((value: string | number) => {
    return <>Asset: {value}</>;
  }, []);

  const valueFormatter = useCallback((value: string | number) => {
    return (
      <CurrencyValue
        value={value}
        variant="dynamic"
        options={{
          currency: 'USD'
        }}
      />
    );
  }, []);

  const categoryConfig: ChartConfig = useMemo(() => {
    return {
      options: {
        scales: {
          x: {
            type: 'category',
            ticks: {
              autoSkip: false
            }
          }
        }
      }
    };
  }, []);
  const config = useChartBackground(categoryConfig);

  if (!data) {
    return <ChartSkeleton />;
  }

  return (
    <BarChart
      data={data as unknown as SimpleChartData[]}
      datasets={datasets}
      config={config}
      xScaleValueFormatter={xScaleValueFormatter}
      subValueFormatter={subValueFormatter}
      valueFormatter={valueFormatter}
      daysAgo={chartDaysAgo}
      defaultDataIndex={0}
    />
  );
}
