import { Page, PageSkeleton, Tabs, CustomDropdownFilter } from '@/components';
import { useApi } from '@/api';
import LiquidationsTable from './components/LiquidationsTable';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { DropdownAssetsFilter, ApplyButton, DateSelector } from '@/components';
import {
  Text,
  Flex,
  KpiCard,
  useIsMobile,
  formatCurrency,
  formatNumber,
  formatDatetime
} from '@blockanalitica/ui';
import CollateralSeizedHistoricTabs from './components/CollateralSeizedHistoricTabs';
import { useTheme } from 'styled-components';
import LiquidationDropdownOptions from '@/pages/liquidations/components/LiquidationDropdownOptions';
import { prioritizeSelectedOptions, toUTCISO } from '@/utils/utils';
import { TZDate } from 'react-day-picker';

type ProtocolsRef = {
  key: string;
  value: string;
  icon?: string;
};

type TokensRef = {
  label: string;
  value: string;
  protocol: string;
};

type Liquidations = {
  num_liquidations: number;
  total_debt_repaid_usd: string;
  total_collateral_seized_usd: string;
  change_num_liquidations: string;
  change_total_debt_repaid_usd: string;
  change_total_collateral_seized_usd: string;
  protocols: ProtocolsRef[];
  loan_assets: TokensRef[];
  collateral_assets: TokensRef[];
};

type FilterUpdate = {
  selectedDebtOption?: string[];
  selectedCollateralOption?: string[];
  textSearch?: string;
};

type DateRangeString = [string | null, string | null];

const PROTOCOLS = [
  {
    key: 'sparkprotocol',
    value: 'Spark'
  },
  {
    key: 'aaveprotocol',
    value: 'Aave Core'
  },
  {
    key: 'aaveprotocol_lido',
    value: 'Aave Prime'
  },
  {
    key: 'Morpho',
    value: 'Morpho'
  },
  {
    key: 'COMP',
    value: 'CompoundV3'
  },
  {
    key: 'skyprotocol',
    value: 'Sky'
  }
];

export default function LiquidationsPage() {
  const theme = useTheme();
  const [chartDaysAgo, setChartDaysAgo] = useState('9999');
  const isMobile = useIsMobile();
  const timeZone = 'UTC';
  const todayUTC = useMemo(() => {
    const now = new Date();
    return new TZDate(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      timeZone
    );
  }, [timeZone]);

  const ninetyDaysBefore = new TZDate(
    todayUTC.getFullYear(),
    todayUTC.getMonth(),
    todayUTC.getDate(),
    timeZone
  );
  ninetyDaysBefore.setDate(ninetyDaysBefore.getDate() - 9999);
  const defaultFromDate = toUTCISO(ninetyDaysBefore);
  const defaultToDate = toUTCISO(todayUTC);

  const [dateRange, setDateRange] = useState<DateRangeString>([
    defaultFromDate,
    defaultToDate
  ]);
  const [startDateString, endDateString] = dateRange;

  const [protocolInput, setProtocolInput] = useState<string[]>([
    'sparkprotocol',
    'aaveprotocol',
    'aaveprotocol_lido',
    'Morpho',
    'COMP',
    'skyprotocol'
  ]);
  const [debtAssetInput, setDebtAssetInput] = useState<string[]>([]);
  const [collateralAssetInput, setCollateralAssetInput] = useState<string[]>(
    []
  );

  const [appliedFilters, setAppliedFilters] = useState({
    protocols: protocolInput,
    loan_assets: debtAssetInput,
    coll_assets: collateralAssetInput,
    from_date: defaultFromDate,
    to_date: defaultToDate,
    days_ago: chartDaysAgo
  });

  const allOptions = LiquidationDropdownOptions({ protocols: protocolInput });

  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const handleChartTimeFilterChange = useCallback((daysAgo: string) => {
    setChartDaysAgo(daysAgo);
    setIsFormUpdated(true);
    const current = new Date();
    const end = new TZDate(
      current.getUTCFullYear(),
      current.getUTCMonth(),
      current.getUTCDate(),
      timeZone
    );
    end.setHours(23, 59, 59, 999);
    const start = new TZDate(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      timeZone
    );
    start.setDate(start.getDate() - Number(daysAgo));
    start.setHours(0, 0, 0, 0);

    setDateRange([toUTCISO(start), toUTCISO(end)]);
  }, []);

  const handleDateRangeChange = useCallback(
    (range: DateRangeString) => {
      setDateRange(range);
      setIsFormUpdated(true);
      const [customFrom, customTo] = range;
      let daysAgo = chartDaysAgo;
      if (customFrom) {
        const fromDate = new TZDate(
          new Date(customFrom).getUTCFullYear(),
          new Date(customFrom).getUTCMonth(),
          new Date(customFrom).getUTCDate(),
          timeZone
        );
        if (customTo) {
          const toDate = new TZDate(
            new Date(customTo).getUTCFullYear(),
            new Date(customTo).getUTCMonth(),
            new Date(customTo).getUTCDate(),
            timeZone
          );
          const differenceMs =
            fromDate.getTime() <= todayUTC.getTime()
              ? toDate.getTime() - fromDate.getTime()
              : toDate.getTime() - todayUTC.getTime();
          const differenceDays = Math.ceil(
            differenceMs / (1000 * 60 * 60 * 24)
          );
          daysAgo = differenceDays === 0 ? '0' : differenceDays.toString();
        } else {
          daysAgo = '0';
        }
      }
      setChartDaysAgo(daysAgo);
    },
    [chartDaysAgo, todayUTC, timeZone]
  );

  const handleDebtAssetUpdate = useCallback((updated: FilterUpdate) => {
    if (updated.selectedDebtOption !== undefined) {
      setDebtAssetInput(updated.selectedDebtOption);
      setIsFormUpdated(true);
    }
  }, []);

  const handleCollateralAssetUpdate = useCallback((updated: FilterUpdate) => {
    if (updated.selectedCollateralOption !== undefined) {
      setCollateralAssetInput(updated.selectedCollateralOption);
      setIsFormUpdated(true);
    }
  }, []);

  const [debtKey, setDebtKey] = useState(0);
  useEffect(() => {
    if (allOptions.debtOptions.length === 0) return;
    const validOptions = allOptions.debtOptions.map((option) => option.value);
    const filteredOptions = debtAssetInput.filter((option) =>
      validOptions.includes(option)
    );
    if (filteredOptions.length !== debtAssetInput.length) {
      setDebtAssetInput(filteredOptions);
      setIsFormUpdated(true);
      setDebtKey((prevKey) => prevKey + 1);
    }
  }, [allOptions.debtOptions, debtAssetInput]);

  const [collKey, setCollKey] = useState(0);
  useEffect(() => {
    if (allOptions.collOptions.length === 0) return;
    const validOptions = allOptions.collOptions.map((option) => option.value);
    const filteredOptions = collateralAssetInput.filter((option) =>
      validOptions.includes(option)
    );
    if (filteredOptions.length !== collateralAssetInput.length) {
      setCollateralAssetInput(filteredOptions);
      setIsFormUpdated(true);
      setCollKey((prevKey) => prevKey + 1);
    }
  }, [allOptions.collOptions, collateralAssetInput]);

  const handleApply = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const from_date = startDateString || '';
      const to_date =
        startDateString === endDateString ? '' : endDateString || '';
      setAppliedFilters({
        protocols: protocolInput,
        loan_assets: debtAssetInput,
        coll_assets: collateralAssetInput,
        from_date,
        to_date,
        days_ago: chartDaysAgo
      });
      setIsFormUpdated(false);
    },
    [
      protocolInput,
      debtAssetInput,
      collateralAssetInput,
      startDateString,
      endDateString,
      chartDaysAgo
    ]
  );

  const [extra, setExtra] = useState('');
  const [footer, setFooter] = useState(true);

  useEffect(() => {
    const earliestDate = new TZDate(2021, 0, 1, 'UTC');

    const getUTCDate = (dateStr: string): TZDate => {
      const d = new Date(dateStr);
      return new TZDate(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate(),
        'UTC'
      );
    };

    // All time condition
    if (
      (appliedFilters.from_date === '' && appliedFilters.to_date === '') ||
      Number(appliedFilters.days_ago) === 9999
    ) {
      setExtra('all time');
      setFooter(true);
      return;
    }

    // If to_date is today, display last X days (if applicable)
    const currentUTCDate = new TZDate(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      'UTC'
    );
    if (
      appliedFilters.to_date &&
      getUTCDate(appliedFilters.to_date).toDateString() ===
        currentUTCDate.toDateString()
    ) {
      setExtra(
        Number(appliedFilters.days_ago) < 9999
          ? `last ${appliedFilters.days_ago} ${appliedFilters.days_ago === '1' ? 'day' : 'days'}`
          : 'all time'
      );
      setFooter(false);
      return;
    }

    // If from_date exists and no to_date OR both dates are the same, display a single day
    if (
      appliedFilters.from_date &&
      (!appliedFilters.to_date ||
        appliedFilters.from_date === appliedFilters.to_date)
    ) {
      const fromTZDate = getUTCDate(appliedFilters.from_date);
      const validDate =
        fromTZDate.getTime() < earliestDate.getTime()
          ? earliestDate.toISOString()
          : appliedFilters.from_date;
      setExtra(`On: ${formatDatetime(validDate)}`);
      setFooter(false);
      return;
    }

    // Otherwise, display a date range
    const fromTZDate = getUTCDate(appliedFilters.from_date);
    const validFromDate =
      fromTZDate.getTime() < earliestDate.getTime()
        ? earliestDate.toISOString()
        : appliedFilters.from_date;
    const toDate = appliedFilters.to_date
      ? formatDatetime(appliedFilters.to_date)
      : 'N/A';
    setExtra(`From: ${formatDatetime(validFromDate)}\nTo: ${toDate}`);
    setFooter(false);
  }, [appliedFilters]);

  const [data, error] = useApi<Liquidations>({
    url: `/liquidations/stats/`,
    queryParams: {
      protocols: appliedFilters.protocols.join(','),
      loan_assets: appliedFilters.loan_assets.join(','),
      coll_assets: appliedFilters.coll_assets.join(','),
      from_date: appliedFilters.from_date || '',
      to_date: appliedFilters.to_date || ''
    }
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return <PageSkeleton />;
  }

  return (
    <Page>
      <Flex
        borderBottom="1px solid"
        borderColor={theme.colors.grey35}
        paddingBottom="m">
        <Flex
          gap="m"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          width={['auto', 'auto', '100%']}>
          <Flex flexDirection="column" gap="xs" flexBasis={['100%', '33%']}>
            <Text
              fontSize="0"
              color={theme.colors.grey69}
              style={{ fontWeight: '600' }}>
              Protocols
            </Text>
            <DropdownAssetsFilter
              options={PROTOCOLS}
              initialValue={protocolInput}
              // @ts-ignore
              titleFormat={(count) => (
                <Flex width="10rem" justifyContent="space-between">
                  <Text color={theme.colors.grey69}>Option</Text>
                  <Text>
                    {count}/{PROTOCOLS.length}
                  </Text>
                </Flex>
              )}
              callback={(value: string[]) => {
                setTimeout(() => {
                  setProtocolInput(value);
                  setIsFormUpdated(true);
                }, 0);
              }}
              dropdownHeader="liquidations"
              key="dropdown"
            />
          </Flex>

          <Flex flexDirection="column" gap="xs" flexBasis={['100%', '33%']}>
            <Text
              fontSize="0"
              color={theme.colors.grey69}
              style={{ fontWeight: '600' }}>
              Collaterals
            </Text>
            <CustomDropdownFilter
              multi
              withCryptoIcon
              network="ethereum"
              variant="liquidations"
              filterField="selectedCollateralOption"
              search
              options={prioritizeSelectedOptions(
                allOptions.collOptions,
                collateralAssetInput
              )}
              // @ts-ignore
              titleFormat={(count) => (
                <Flex width="10rem" justifyContent="space-between">
                  <Text color={theme.colors.grey69}>Asset</Text>
                  <Text>
                    {count ? count : allOptions.collOptions.length}/
                    {allOptions.collOptions.length}
                  </Text>
                </Flex>
              )}
              filtersApplied={{
                selectedCollateralOption: collateralAssetInput
              }}
              updateFilters={handleCollateralAssetUpdate}
              key={`coll-dropdown-${collKey}`}
            />
          </Flex>

          <Flex flexDirection="column" gap="xs" flexBasis={['100%', '33%']}>
            <Text
              fontSize="0"
              color={theme.colors.grey69}
              style={{ fontWeight: '600' }}>
              Debt
            </Text>
            <CustomDropdownFilter
              multi
              search
              withCryptoIcon
              network="ethereum"
              variant="liquidations"
              filterField="selectedDebtOption"
              options={prioritizeSelectedOptions(
                allOptions.debtOptions,
                debtAssetInput
              )}
              // @ts-ignore
              titleFormat={(count) => (
                <Flex width="10rem" justifyContent="space-between">
                  <Text color={theme.colors.grey69}>Asset</Text>
                  <Text>
                    {count ? count : allOptions.debtOptions.length}/
                    {allOptions.debtOptions.length}
                  </Text>
                </Flex>
              )}
              filtersApplied={{ selectedDebtOption: debtAssetInput }}
              updateFilters={handleDebtAssetUpdate}
              key={`debt-dropdown-${debtKey}`}
            />
          </Flex>

          <Flex
            flexDirection="column"
            gap="xs"
            minWidth="15rem"
            padding={isMobile ? 'm 0 m 0' : '0 m 0 m'}
            style={{
              backgroundImage: `
                                repeating-radial-gradient(circle at center,
                                    ${theme.colors.grey35} 0px,
                                    ${theme.colors.grey35} 1px,
                                    transparent 1.5px,
                                    transparent 6px),
                                repeating-radial-gradient(circle at center,
                                    ${theme.colors.grey35} 0px,
                                    ${theme.colors.grey35} 1px,
                                    transparent 1.5px,
                                    transparent 6px)
                            `,
              backgroundPosition: isMobile
                ? '50% 0%, 50% 100%'
                : '0% 50%, 100% 50%',
              backgroundSize: '7px 7px',
              backgroundRepeat: isMobile ? 'repeat-x' : 'repeat-y'
            }}>
            <Text
              fontSize="0"
              color={theme.colors.grey69}
              style={{ fontWeight: '600' }}>
              Filter by Date
            </Text>
            <DateSelector
              key="datepicker"
              range={{
                from: dateRange[0]
                  ? new TZDate(
                      new Date(dateRange[0]).getUTCFullYear(),
                      new Date(dateRange[0]).getUTCMonth(),
                      new Date(dateRange[0]).getUTCDate(),
                      timeZone
                    )
                  : undefined,
                to: dateRange[1]
                  ? new TZDate(
                      new Date(dateRange[1]).getUTCFullYear(),
                      new Date(dateRange[1]).getUTCMonth(),
                      new Date(dateRange[1]).getUTCDate(),
                      timeZone
                    )
                  : undefined
              }}
              onSelect={(newRange) => {
                handleDateRangeChange([
                  newRange?.from ? toUTCISO(newRange.from) : null,
                  newRange?.to ? toUTCISO(newRange.to) : null
                ]);
              }}
              chartDaysAgo={chartDaysAgo}
              onChange={handleChartTimeFilterChange}
              handleApply={handleApply}
              isFormUpdated={isFormUpdated}
            />
          </Flex>

          <Flex
            as="form"
            onSubmit={handleApply}
            alignSelf={isMobile ? 'flex-start' : 'flex-end'}>
            <ApplyButton
              // @ts-ignore
              type="submit"
              disabled={!isFormUpdated}>
              <Text fontSize="0">Apply</Text>
            </ApplyButton>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={['m', 's']} flexDirection={['column', 'row']}>
        <KpiCard
          title="# of Liquidations"
          extra={
            <Text
              style={{ whiteSpace: 'pre-line' }}
              fontSize="-1"
              color={theme.colors.grey69}>
              {extra}
            </Text>
          }
          data={data as Liquidations}
          field="num_liquidations"
          footer={
            footer && (
              <Flex gap="m" alignItems="center" paddingTop={'3xs'}>
                <Text gap="m" fontSize="0" color={theme.colors.grey69}>
                  {formatNumber(Number(data.change_num_liquidations))} in the
                  last 24h
                </Text>
              </Flex>
            )
          }
        />
        <KpiCard
          title="Collateral Liquidated"
          extra={
            <Text
              style={{ whiteSpace: 'pre-line' }}
              fontSize="-1"
              color={theme.colors.grey69}>
              {extra}
            </Text>
          }
          data={data}
          field="total_collateral_seized_usd"
          options={{ currency: 'USD', notation: 'compact' }}
          footer={
            footer && (
              <Flex gap="m" alignItems="center" paddingTop={'3xs'}>
                <Text gap="m" fontSize="0" color={theme.colors.grey69}>
                  {formatCurrency(
                    Number(data.change_total_collateral_seized_usd)
                  )}{' '}
                  in the last 24h
                </Text>
              </Flex>
            )
          }
        />
        <KpiCard
          title="Debt Repaid"
          extra={
            <Text
              style={{ whiteSpace: 'pre-line' }}
              fontSize="-1"
              color={theme.colors.grey69}>
              {extra}
            </Text>
          }
          data={data}
          field="total_debt_repaid_usd"
          options={{ currency: 'USD', notation: 'compact' }}
          footer={
            footer && (
              <Flex gap="m" alignItems="center" paddingTop={'3xs'}>
                <Text gap="m" fontSize="0" color={theme.colors.grey69}>
                  {formatCurrency(Number(data.change_total_debt_repaid_usd))} in
                  the last 24h
                </Text>
              </Flex>
            )
          }
        />
      </Flex>
      <CollateralSeizedHistoricTabs appliedFilters={appliedFilters} />
      <Tabs
        tabs={[
          {
            key: 'liquidations',
            title: 'Liquidations',
            content: <LiquidationsTable appliedFilters={appliedFilters} />
          }
        ]}
        activeTab="liquidations"
        setActiveTab={() => null}
      />
    </Page>
  );
}
