import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function kpiCardValueTrend(theme: Theme): ComponentStyle {
  return {
    componentName: 'kpiCardValueTrend',
    variants: {
      multi: {
        fontSize: theme.sizes[-1]
      }
    }
  };
}
