import { Page } from '@/components';
import BackedSankeyChart from './BackedSankeyChart';
import BackedTable from './BackedTable';

export default function USDSBackingPage() {
  return (
    <Page>
      <BackedSankeyChart />
      <BackedTable />
    </Page>
  );
}
