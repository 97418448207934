import { useApi, PaginatedResponse } from '@/api';
import { EntityName, TableCellBottom, EtherscanLink } from '@/components';
import {
  CurrencyValue,
  DataTable,
  Flex,
  WalletAddress,
  RelativeDateTime,
  TableSkeleton,
  Card,
  CryptoIcon,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  formatNumber
} from '@blockanalitica/ui';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';
import { useTableProps } from '@blockanalitica/ui';
import { protocolToTitleCase } from '@/utils/utils';

type LiquidationData = {
  datetime: string;
  tx_hash: string;
  loan_token_address: string;
  collateral_underlying_address: string;
  wallet_address: string;
  liquidator_address: string;
  loan_token_symbol: string;
  debt_repaid: string;
  debt_repaid_usd: string;
  collateral_underlying_symbol: string;
  collateral_seized: string;
  collateral_seized_usd: string;
  liquidation_bonus_usd: string;
  protocol_symbol: string;
  pool: string;
  protocol: string;
  order_index: string;
  collaterals: {
    amount: number | null;
    amount_usd: number | null;
    underlying_symbol: string;
    underlying_address: string;
  }[];
};

interface AppliedFilters {
  protocols: string[];
  loan_assets: string[];
  coll_assets: string[];
  from_date: string | null;
  to_date: string | null;
}
interface LiquidationTableProps {
  appliedFilters?: AppliedFilters;
}

export default function LiquidationsTable({
  appliedFilters = {
    protocols: [],
    loan_assets: [],
    coll_assets: [],
    from_date: '',
    to_date: ''
  }
}: LiquidationTableProps) {
  const { pagination, sorting } = useTableProps({
    pageSize: 10,
    initialSorting: '-order_index'
  });

  const [data, error] = useApi<PaginatedResponse<LiquidationData[]>>({
    url: `/liquidations/`,
    queryParams: {
      p: pagination.currentPage.toString(),
      p_size: pagination.pageSize.toString(),
      order: sorting.currentSorting,
      protocols: appliedFilters.protocols.join(','),
      loan_assets: appliedFilters.loan_assets.toString(),
      coll_assets: appliedFilters.coll_assets.toString(),
      from_date: appliedFilters.from_date || '',
      to_date: appliedFilters.to_date || ''
    }
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  const columns: TableColumn[] = [
    {
      header: 'Wallet',
      getCell: (row) => (
        <EntityName
          symbol1={<CryptoIcon name={row.protocol_symbol} size="3" />}
          symbol2={
            <WalletAddress
              address={row.wallet_address}
              options={{
                name: protocolToTitleCase(row.protocol),
                inline: true,
                showCopyBtn: true
              }}
              size="3"
            />
          }>
          <Flex gap="3xs" alignItems="flex-start" flexDirection="column">
            <Flex gap="3xs" alignItems="center">
              {row.pool}
            </Flex>
          </Flex>
        </EntityName>
      )
    },
    {
      header: 'Collateral Seized',
      getCell: (row) => {
        if (row.collaterals.length === 1) {
          const collateral = row.collaterals[0];
          return (
            <CurrencyValue
              value={collateral.collateral_seized_usd}
              options={{
                currency: 'USD'
              }}
            />
          );
        }
        return (
          <Flex flexDirection="column" gap="2xs">
            {row.collaterals.map(
              (collateral: {
                collateral_token_address: string;
                collateral_seized: string;
                collateral_seized_usd: string;
                collateral_token_symbol: string;
              }) => (
                <Flex
                  key={collateral.collateral_token_address}
                  gap="3xs"
                  justifyContent="flex-end"
                  alignItems="flex-end">
                  <CurrencyValue
                    value={collateral.collateral_seized_usd}
                    options={{
                      currency: 'USD',
                      notation: 'compact',
                      variant: 'muted'
                    }}
                  />
                  /
                  <TableCellBottom checkValue={collateral.collateral_seized}>
                    <Tooltip>
                      <TooltipTrigger>
                        <CurrencyValue
                          value={collateral.collateral_seized}
                          options={{
                            currency: {
                              name: collateral.collateral_token_symbol,
                              address: collateral.collateral_token_address
                            }
                          }}
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        <Flex gap="xl" justifyContent="space-evenly">
                          {formatNumber(Number(collateral.collateral_seized))}{' '}
                          {collateral.collateral_token_symbol}
                        </Flex>
                      </TooltipContent>
                    </Tooltip>
                  </TableCellBottom>
                </Flex>
              )
            )}
          </Flex>
        );
      },
      getCellBottom: (row) => {
        if (row.collaterals.length === 1) {
          const collateral = row.collaterals[0];
          return (
            <TableCellBottom checkValue={collateral.collateral_seized}>
              <Tooltip>
                <TooltipTrigger>
                  <CurrencyValue
                    value={collateral.collateral_seized}
                    options={{
                      currency: {
                        name: collateral.collateral_token_symbol,
                        address: collateral.collateral_token_address
                      }
                    }}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <Flex gap="xl" justifyContent="space-evenly">
                    {formatNumber(Number(collateral.collateral_seized))}{' '}
                    {collateral.collateral_token_symbol}
                  </Flex>
                </TooltipContent>
              </Tooltip>
            </TableCellBottom>
          );
        }
        return null;
      },
      sortingField: 'collateral_seized_usd',
      cellAlign: 'flex-end',
      headerAlign: 'flex-end'
    },
    {
      header: 'Debt Repaid',
      getCell: (row) => (
        <CurrencyValue
          value={row.debt_repaid_usd}
          options={{
            currency: 'USD'
          }}
        />
      ),
      getCellBottom: (row) => (
        <TableCellBottom checkValue={row.debt_repaid_usd}>
          <Tooltip>
            <TooltipTrigger>
              <CurrencyValue
                value={row.debt_repaid}
                options={{
                  currency: {
                    name: row.loan_token_symbol,
                    address: row.loan_token_address
                  }
                }}
              />
            </TooltipTrigger>
            <TooltipContent>
              <Flex gap="xl" justifyContent="space-evenly">
                {formatNumber(Number(row.debt_repaid))} {row.loan_token_symbol}
              </Flex>
            </TooltipContent>
          </Tooltip>
        </TableCellBottom>
      ),
      sortingField: 'debt_repaid_usd',
      cellAlign: 'flex-end',
      headerAlign: 'flex-end'
    },
    {
      header: 'Liquidation Bonus',
      getCell: (row) => (
        <CurrencyValue
          value={row.liquidation_bonus_usd}
          options={{
            currency: 'USD'
          }}
        />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'liquidation_bonus_usd'
    },
    {
      header: 'Time',
      getCell: (row) => (
        <Flex gap="xs" color="muted">
          <RelativeDateTime value={row.datetime} />
          <EtherscanLink value={row.tx_hash} network="ethereum" />
        </Flex>
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'datetime'
    }
  ];

  return (
    <Card gap="m" flexDirection="column" justifyContent="flex-start">
      <Flex
        gap="xs"
        as="form"
        flexWrap={['wrap', 'nowrap']}
        justifyContent={['center', 'flex-end']}
        borderBottom="solid 1px #35384b"
        paddingBottom="m"></Flex>
      <DataTable
        rows={data.results}
        columns={columns}
        getKey={(row) => `${row.order_index}`}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
        width="100%"
      />
    </Card>
  );
}
