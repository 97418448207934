import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function popoverContent(theme: Theme): ComponentStyle {
  return {
    componentName: 'popoverContent',
    baseStyle: {
      width: 'auto',
      zIndex: 50
    },
    variants: {
      datepicker: {
        backgroundColor: theme.colors.foreground,
        borderRadius: theme.sizes['-4'],
        border: `1px solid ${theme.colors.border}`,
        padding: '0',
        zIndex: 50
      },
      datepickerMobile: {
        backgroundColor: theme.colors.foreground,
        borderRadius: theme.sizes['-4'],
        border: `1px solid ${theme.colors.border}`,
        justifyContent: 'center',
        padding: '0',
        zIndex: 50,
        position: 'relative',
        right: '3%',
        width: 'calc(100vw - 2px)'
      }
    }
  };
}
