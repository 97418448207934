import {
  formatNumber,
  CurrencyValue,
  LineChart,
  ChartSkeleton
} from '@blockanalitica/ui';
import { useMemo, useState } from 'react';
import { useApi } from '@/api';
import type {
  SimpleChartData,
  SimpleChartDataset
} from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { ChartTimeFilter, Tabs } from '@/components';
import { useChartPrefillDatasetsDates, useChartBackground } from '@/hooks';

export default function PoolStatsHistoricGraphs({
  underlyingAddress,
  marketSymbol,
  poolId,
  protocol
}: {
  underlyingAddress: string;
  marketSymbol: string;
  poolId: string;
  protocol: string;
}) {
  const [activeTab, setActiveTab] = useState('total_supply');
  const [chartDaysAgo, setChartDaysAgo] = useState('90');

  const [data, error] = useApi<SimpleChartData[]>({
    url: `/pool/${protocol}/${poolId}/stats-history/`,
    queryParams: {
      days_ago: chartDaysAgo
    }
  });

  const config = useChartBackground();

  const datasets: SimpleChartDataset[] = useMemo(
    () => [
      {
        xField: 'date',
        yField: activeTab
      }
    ],
    [activeTab]
  );

  const chartData = useChartPrefillDatasetsDates(data, datasets);

  const network = useMemo(() => {
    return data && data.length > 0
      ? data[data.length - 1]['network']
      : undefined;
  }, [data]) as string | undefined;

  const timeFilter = useMemo(
    () => (
      <ChartTimeFilter activeOption={chartDaysAgo} onChange={setChartDaysAgo} />
    ),
    [chartDaysAgo]
  );

  const chart = useMemo(
    () => (
      <LineChart
        key={activeTab}
        data={chartData}
        config={config}
        datasets={datasets}
        valueFormatter={(value: string | number) => (
          <CurrencyValue
            value={value}
            variant="dynamic"
            options={{
              currencySize: '3',
              currency: {
                name: marketSymbol,
                address: underlyingAddress,
                network: network
              }
            }}
          />
        )}
        yScaleValueFormatter={(value: string | number) =>
          formatNumber(Number(value))
        }
        timeFilter={timeFilter}
        daysAgo={chartDaysAgo}
      />
    ),
    [
      chartData,
      config,
      datasets,
      marketSymbol,
      underlyingAddress,
      timeFilter,
      activeTab,
      chartDaysAgo,
      network
    ]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'total_supply',
        title: 'Total Supply',
        content: chart
      },
      {
        key: 'total_borrow',
        title: 'Total Borrow',
        content: chart
      }
    ],
    [chart]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <ChartSkeleton />;
  }

  return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
}
