import React, { ForwardedRef } from 'react';
import {
  SelectContent as RadixSelectContent,
  SelectContentProps as RadixSelectContentProps
} from '@radix-ui/react-select';
import { ThemedComponentWrapper } from '@blockanalitica/ui';
import type { ThemedComponentWrapperProps } from '@blockanalitica/ui';

type SelectContentVariant = 'baseStyle' | 'datepicker' | 'datepickerMobile';

interface ThemedSelectContentProps
  extends Partial<
      Omit<ThemedComponentWrapperProps<'div'>, 'variant' | 'color'>
    >,
    Omit<RadixSelectContentProps, 'color' | 'name'> {
  variant?: SelectContentVariant;
  color?: string;
  name?: string;
}

const SelectContent = React.forwardRef(
  (
    {
      children,
      name = 'selectContent',
      variant = 'baseStyle',
      ...rest
    }: ThemedSelectContentProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <ThemedComponentWrapper
        // @ts-ignore
        as={RadixSelectContent}
        name={name}
        variant={variant}
        ref={ref}
        {...rest}>
        {children}
      </ThemedComponentWrapper>
    );
  }
);

SelectContent.displayName = 'SelectContent';
export default SelectContent;
