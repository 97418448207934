import { CalendarIcon } from '../icons';
import { DateRange } from 'react-day-picker';
import {
  Button,
  ChartTimeFilter,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/';
import Calendar from '../Calendar/Calendar';
import {
  formatDatetime,
  Flex,
  useIsMobile,
  Box,
  Text
} from '@blockanalitica/ui';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import { TZDate } from 'react-day-picker';

type DateSelectorProps = {
  range?: DateRange;
  onSelect?: (range: DateRange | undefined) => void;
  chartDaysAgo: string;
  onChange: (daysAgo: string) => void;
  handleApply: (e: React.FormEvent) => void;
  isFormUpdated: boolean;
};

const timeOptions = [
  { key: '1', value: '1D' },
  { key: '7', value: '1W' },
  { key: '30', value: '1M' },
  { key: '90', value: '3M' },
  { key: '365', value: '1Y' },
  { key: '9999', value: 'MAX' }
];

export default function DateSelector({
  range,
  onSelect,
  chartDaysAgo,
  onChange,
  handleApply,
  isFormUpdated
}: DateSelectorProps) {
  const theme = useTheme();
  const timeZone = 'UTC';

  const isMobile = useIsMobile();
  const today = new TZDate(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    timeZone
  );

  const earliestDate = new TZDate(2021, 0, 1, timeZone);
  const variant = isMobile ? 'datepickerMobile' : 'datepicker';
  const [leftMonth, setLeftMonth] = useState(() => {
    return new TZDate(today.getFullYear(), today.getMonth() - 1, 1, timeZone);
  });
  const [rightMonth, setRightMonth] = useState(() => {
    return new TZDate(today.getFullYear(), today.getMonth(), 1, timeZone);
  });

  const leftIsToday =
    leftMonth.getMonth() === today.getMonth() - 1 &&
    leftMonth.getFullYear() === today.getFullYear();
  const rightIsToday =
    rightMonth.getMonth() === today.getMonth() &&
    rightMonth.getFullYear() === today.getFullYear();
  const goToTodayEnabled = !(leftIsToday && rightIsToday);
  const clearEnabled = Boolean(range?.from);

  const getButtonStyle = (enabled: boolean) => ({
    backgroundColor: enabled ? theme.colors.primary : 'transparent',
    color: enabled ? theme.colors.grey25 : theme.colors.muted,
    border: 'none',
    borderRadius: theme.sizes['-4'],
    padding: isMobile ? theme.space['s'] : theme.space['2xs'],
    cursor: enabled ? 'pointer' : 'default'
  });

  function handleGoToToday() {
    if (goToTodayEnabled) {
      setRightMonth(
        new TZDate(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          timeZone
        )
      );
      setLeftMonth(() => {
        const d = new Date();
        d.setMonth(d.getMonth() - 1);
        return new TZDate(d.getFullYear(), d.getMonth(), d.getDate(), timeZone);
      });
    }
  }
  const interfaceContent = (
    <Flex
      flexDirection="column"
      margin={isMobile ? 'xl' : '2xs'}
      paddingLeft={!isMobile ? '2xs' : undefined}
      paddingRight={!isMobile ? '2xs' : undefined}
      justifyContent={['space-around', 'space-evenly']}>
      <Text
        size={isMobile ? '6' : '3'}
        alignSelf={['center', 'flex-start']}
        color={theme.colors.grey69}
        paddingBottom={isMobile ? '2xs' : '0'}
        style={{
          fontWeight: '600',
          paddingBottom: isMobile ? 's' : '2xs'
        }}>
        Quick Filters
      </Text>
      <Flex
        flexDirection={['column', 'row']}
        gap="2xs"
        alignItems="center"
        width="100%"
        justifyContent="center">
        <Flex paddingBottom={isMobile ? '2xs' : null}>
          <ChartTimeFilter
            data={timeOptions}
            activeOption={chartDaysAgo}
            onChange={onChange}
            options={{
              variants: { displaySwitchOption: variant }
            }}
            key="timefilter"
          />
        </Flex>
        <Flex gap="2xs" justifyContent="space-around">
          <button
            style={getButtonStyle(goToTodayEnabled)}
            onClick={handleGoToToday}
            disabled={!goToTodayEnabled}>
            {<Text size={isMobile ? '6' : '3'}>Go to Today</Text>}
          </button>

          <button
            style={getButtonStyle(clearEnabled)}
            onClick={() => clearEnabled && onSelect?.(undefined)}
            disabled={!clearEnabled}>
            {<Text size={isMobile ? '6' : '3'}>Clear</Text>}
          </button>
          {isMobile && (
            <Flex as="form" onSubmit={handleApply}>
              <button
                style={getButtonStyle(isFormUpdated)}
                // @ts-ignore
                type="submit"
                disabled={!isFormUpdated}>
                <Text size={isMobile ? '6' : '3'}>Apply</Text>
              </button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
  return (
    <Popover>
      <Button
        // @ts-ignore
        as={PopoverTrigger}>
        <CalendarIcon size="0" />
        {range && range.from && Number(chartDaysAgo) !== 9999 ? (
          range.to ? (
            <>
              {range.from < earliestDate
                ? `${formatDatetime(earliestDate.getTime())} - `
                : `${formatDatetime(range.from.getTime())} - `}
              {formatDatetime(range.to.getTime())}
            </>
          ) : (
            formatDatetime(range.from.getTime())
          )
        ) : (
          <span>Pick a date</span>
        )}
      </Button>
      <PopoverContent
        variant={variant}
        sideOffset={4}
        align={isMobile ? 'start' : 'center'}>
        <Box
          flexDirection="column"
          gap="2"
          style={{
            background: `linear-gradient(${
              isMobile ? theme.colors.grey69 : theme.colors.datepicker
            }, ${isMobile ? theme.colors.datepicker : theme.colors.grey69})`,
            borderRadius: theme.sizes['-4'],
            border: `1px solid ${theme.colors.grey45}`
          }}>
          {isMobile ? null : interfaceContent}
          <Box
            backgroundColor={theme.colors.datepicker}
            style={{
              padding: '2xs',
              borderTopLeftRadius: isMobile ? theme.sizes['-4'] : undefined,
              borderTopRightRadius: isMobile ? theme.sizes['-4'] : undefined,
              borderBottomLeftRadius: !isMobile ? theme.sizes['-4'] : undefined,
              borderBottomRightRadius: !isMobile ? theme.sizes['-4'] : undefined
            }}>
            <Flex flexDirection={['column', 'row']} alignItems="center">
              {/* Left Calendar */}
              {!isMobile && (
                <Calendar
                  mode="range"
                  startMonth={earliestDate}
                  selected={range}
                  onSelect={(range) =>
                    onSelect?.(range as DateRange | undefined)
                  }
                  month={leftMonth}
                  disabled={{ after: today }}
                  onMonthChange={(month) =>
                    setLeftMonth(
                      new TZDate(
                        month.getFullYear(),
                        month.getMonth(),
                        1,
                        timeZone
                      )
                    )
                  }
                  key="leftCalendar"
                />
              )}
              {/* Right Calendar Use this one in Mobile as it shows the current month*/}
              <Calendar
                mode="range"
                startMonth={earliestDate}
                selected={range}
                onSelect={(range) => onSelect?.(range as DateRange | undefined)}
                disabled={{ after: today }}
                month={rightMonth}
                onMonthChange={(month) =>
                  setRightMonth(
                    new TZDate(
                      month.getFullYear(),
                      month.getMonth(),
                      1,
                      timeZone
                    )
                  )
                }
                key="rightCalendar"
              />
            </Flex>
          </Box>
          {isMobile ? interfaceContent : null}
        </Box>
      </PopoverContent>
    </Popover>
  );
}
