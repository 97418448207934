import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';

export default function CalendarIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={
        typeof size === 'number'
          ? size + 'px'
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? size + 'px'
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}>
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <path d="M3 10h18" />
    </Svg>
  );
}

CalendarIcon.displayName = '<CalendarIcon />';
