import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function kpiCardValue(theme: Theme): ComponentStyle {
  return {
    componentName: 'kpiCardValue',
    variants: {
      multi: {
        fontSize: theme.sizes[0]
      }
    }
  };
}
