import {
  LineChart,
  ChartSkeleton,
  formatPercentage,
  formatCurrency,
  Flex,
  Dropdown,
  DropdownOption,
  Box
} from '@blockanalitica/ui';
import { useCallback, useState, useMemo } from 'react';
import { useApi } from '@/api';
import { useChartBackground } from '@/hooks';
import type { SimpleChartData } from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { ChartTimeFilter, Tabs } from '@/components';
import { ChartConfig } from '@blockanalitica/ui/package/types/src/components/charts/Chart';
import { useTheme } from 'styled-components';
import type { TooltipItem } from 'chart.js';
import { BORROW_ONLY_PROTOCOLS } from '@/constants';

export default function ProtocolRatesChart({ protocol }: { protocol: string }) {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('avg_apys');
  const [chartDaysAgo, setChartDaysAgo] = useState('90');

  const [apyTimeframe, setApyTimeframe] = useState('7d');

  const apyTimeframes = useMemo(
    () => [
      { key: '1d', value: '1d Avg' },
      { key: '7d', value: '7d Avg' },
      { key: '30d', value: '30d Avg' },
      { key: '90d', value: '90d Avg' },
      { key: '365d', value: '365d Avg' }
    ],
    []
  );

  const changeApyTimeframe = useCallback((timeframe: string | string[]) => {
    setApyTimeframe(timeframe as string);
  }, []);

  const [data, error] = useApi<SimpleChartData[]>({
    url: `/protocol/${protocol}/stats-history/`,
    queryParams: {
      days_ago: chartDaysAgo,
      apy_timeframe: apyTimeframe
    }
  });

  const valueFormatter = useCallback(
    (value: number | string) => {
      if (activeTab === 'avg_apys') {
        return null;
      }
      return formatCurrency(Number(value), { currency: 'USD' });
    },
    [activeTab]
  );

  const yScaleValueFormatter = useCallback(
    (value: number | string) => {
      if (activeTab === 'avg_apys') {
        return formatPercentage(Number(value));
      }
      return formatCurrency(Number(value), { currency: 'USD' });
    },
    [activeTab]
  );

  const timeFilter = useMemo(
    () => (
      <Flex
        flexDirection={['column', 'row']}
        gap="xs"
        justifyContent="flex-end">
        <Flex
          gap="xs"
          flexWrap={['wrap', 'nowrap']}
          justifyContent={['center', 'flex-end']}>
          {activeTab === 'avg_apys' ? (
            <Dropdown
              onChange={changeApyTimeframe}
              iconSize="-2"
              initialValue={apyTimeframe}
              options={{
                variants: {
                  dropdownHeader: 'secondary'
                }
              }}
              key="apyTimeframe">
              {apyTimeframes.map(({ key, value }) => (
                <DropdownOption key={key} value={key} variant="secondary">
                  <Flex gap="3xs" alignItems="center">
                    <Box>{value}</Box>
                  </Flex>
                </DropdownOption>
              ))}
            </Dropdown>
          ) : null}
          <ChartTimeFilter
            activeOption={chartDaysAgo}
            onChange={setChartDaysAgo}
          />
        </Flex>
      </Flex>
    ),
    [chartDaysAgo, apyTimeframe, changeApyTimeframe, apyTimeframes, activeTab]
  );

  const datasets = useMemo(() => {
    if (activeTab === 'avg_apys') {
      return [
        ...(!BORROW_ONLY_PROTOCOLS.includes(protocol)
          ? [
              {
                xField: 'date',
                yField: 'supply_avg_apy',
                label: 'Supply AVG APY',
                borderColor: theme.colors.success,
                backgroundColor: theme.colors.success
              }
            ]
          : []),
        {
          xField: 'date',
          yField: 'borrow_avg_apy',
          label: 'Borrow AVG APY',
          borderColor: theme.colors.error,
          backgroundColor: theme.colors.error
        }
      ];
    } else {
      return [
        {
          xField: 'date',
          yField: activeTab
        }
      ];
    }
  }, [activeTab, theme.colors.success, theme.colors.error, protocol]);

  const defaultConfig: ChartConfig = useMemo(() => {
    if (activeTab === 'avg_apys') {
      return {
        options: {
          // @ts-ignore
          fill: false,
          plugins: {
            legend: {
              display: true,
              position: 'bottom' as const,
              labels: {
                color: theme.colors.muted,
                boxWidth: 20
              }
            },
            tooltip: {
              itemSort: (a: TooltipItem<'line'>, b: TooltipItem<'line'>) => {
                return b.parsed.y - a.parsed.y;
              }
            }
          }
        }
      };
    }
    return {};
  }, [activeTab, theme.colors.muted]);

  const config = useChartBackground(defaultConfig);

  const chart = useMemo(
    () => (
      <LineChart
        key={`${activeTab}${chartDaysAgo}`}
        data={data}
        config={config}
        datasets={datasets}
        yScaleValueFormatter={yScaleValueFormatter}
        valueFormatter={valueFormatter}
        timeFilter={timeFilter}
        tooltip={{
          labelFormatter: (value) => `${value}`,
          valueFormatter: (value) => `${formatPercentage(Number(value))}`
        }}
        showKpisAsTooltip={activeTab === 'avg_apys' ? true : false}
        daysAgo={chartDaysAgo}
      />
    ),
    [
      activeTab,
      data,
      config,
      valueFormatter,
      yScaleValueFormatter,
      chartDaysAgo,
      timeFilter,
      datasets
    ]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'avg_apys',
        title: 'Stablecoin Avg APYs',
        content: chart
      },
      {
        key: 'total_stablecoin_supply',
        title: 'Total Stablecoin Supply',
        content: chart
      },
      {
        key: 'total_stablecoin_borrow',
        title: 'Total Stablecoin Borrow',
        content: chart
      }
    ],
    [chart]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <ChartSkeleton />;
  }

  return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
}
