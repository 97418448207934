import _ from 'lodash';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { Borders } from '@blockanalitica/ui/package/types/src/theme/borders';
import { Colors } from '@blockanalitica/ui/package/types/src/theme/colors';
import { Sizes } from '@blockanalitica/ui/package/types/src/theme/sizes';
import { Space } from '@blockanalitica/ui/package/types/src/theme/space';

export type ResponsiveCSSValue<T> = T | T[] | undefined;

export function resolveThemeVariable<T>(
  value: T,
  themeKey?: Space | Sizes | Colors | Borders | Theme
) {
  if (typeof value === 'string' && themeKey) {
    const valueArray = value.split(' ');

    if (valueArray.length === 1) {
      const themeVariable = _.get(themeKey, value) ?? value;

      return themeVariable;
    }

    const themeVariable = valueArray
      .map((val) => _.get(themeKey, val) ?? val)
      .join(' ');

    return themeVariable;
  }

  return value;
}

export function getResponsiveStyles<T>(
  prop: ResponsiveCSSValue<T>,
  cssProperty: string,
  breakpoints: number[],
  themeKey?: Space | Sizes | Colors | Borders | Theme,
  resolveFn?: (value: T) => string | number
) {
  if (prop === undefined) return '';

  const values = Array.isArray(prop) ? prop : [prop];

  return values
    .map((value, index) => {
      // mobile-first
      if (index === 0) {
        return `${cssProperty}: ${
          resolveFn
            ? resolveFn(value)
            : resolveThemeVariable<T>(value, themeKey)
        };`;
      }

      return `@media (min-width: ${breakpoints[index - 1]}px) {
        ${cssProperty}: ${
          resolveFn
            ? resolveFn(value)
            : resolveThemeVariable<T>(value, themeKey)
        };
      }`;
    })
    .join('\n');
}
