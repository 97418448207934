import { ReactNode } from 'react';
import { ThemedComponentWrapper, CheckIcon } from '@blockanalitica/ui';

import type { ThemedComponentWrapperProps } from 'node_modules/@blockanalitica/ui/package/types/src/theme/ThemedComponentWrapper.d.ts';

type Size =
  | '-5'
  | '-4'
  | '-3'
  | '-2'
  | '-1'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10';
export interface DropdownOptionProps
  extends Partial<Omit<ThemedComponentWrapperProps, 'children'>> {
  children: ReactNode | ((selected?: boolean) => ReactNode);
  value: string;
  onClick?: () => void;
  selected?: boolean;
  iconSize?: Size;
  label: string;
}

export default function DropdownOption({
  children,
  onClick,
  selected,
  iconSize,
  ...rest
}: DropdownOptionProps) {
  return (
    <ThemedComponentWrapper name="dropdownOption" onClick={onClick} {...rest}>
      {typeof children === 'function' ? (
        children(selected)
      ) : selected ? (
        <ThemedComponentWrapper name="dropdownOptionSelected">
          {children} <CheckIcon size={iconSize} />
        </ThemedComponentWrapper>
      ) : (
        children
      )}
    </ThemedComponentWrapper>
  );
}
