import { useApi } from '@/api';
import { Tabs } from '@/components';
import { ChartSkeleton, Flex } from '@blockanalitica/ui';
import { useMemo, useState, JSX } from 'react';
import AssetLiquidationsGraph, { AssetData } from './AssetLiquidationGraph';
import DatesLiquidationsGraph, { DatesData } from './DatesLiquidationsGraph';
import ProtocolSumLiquidationsGraph, {
  ProtocolData
} from './ProtocolSumLiquidatedGraph';
import CollateralAssetLiquidationsGraph, {
  CollateralAssetData
} from './CollateralAssetLiquidationsGraph';
import PopupTableModal from './PopupTableModal';
import CollateralLiquidatedTable from './CollateralLiquidatedTable';
import DebtRepaidTable from './DebtReapidTable';
interface AppliedFilters {
  protocols: string[];
  loan_assets: string[];
  coll_assets: string[];
  from_date: string | null;
  to_date: string | null;
  days_ago: string;
}
interface HistoricTabsProps {
  appliedFilters?: AppliedFilters;
}
interface Tab {
  key: string;
  title: string;
  content: JSX.Element;
}
interface HistoricTabsProps {
  appliedFilters?: AppliedFilters;
}

export default function HistoricTabs({
  appliedFilters = {
    protocols: [],
    loan_assets: [],
    coll_assets: [],
    from_date: '',
    to_date: '',
    days_ago: ''
  }
}: HistoricTabsProps) {
  const [activeTab, setActiveTab] = useState<string>('date');

  const [data, error] = useApi<
    AssetData[] | CollateralAssetData[] | ProtocolData | DatesData
  >({
    url: `/liquidations/historic/`,
    queryParams: {
      type: activeTab,
      protocols: appliedFilters.protocols.join(','),
      loan_assets: appliedFilters.loan_assets.toString() || '',
      coll_assets: appliedFilters.coll_assets.toString() || '',
      from_date: appliedFilters.from_date || '',
      to_date: appliedFilters.to_date || ''
    }
  });

  const tabs: Tab[] = useMemo(() => {
    if (!data || !Object.keys(data).length) return [];
    return [
      {
        key: 'date',
        title: 'Liquidations - by Collateral',
        content: (
          <DatesLiquidationsGraph
            data={data as DatesData}
            chartDaysAgo={appliedFilters.days_ago}
          />
        )
      },
      {
        key: 'protocol',
        title: 'Liquidations - by Protocol',
        content: (
          <ProtocolSumLiquidationsGraph
            data={data as ProtocolData}
            chartDaysAgo={appliedFilters.days_ago}
          />
        )
      },
      {
        key: 'debt_limit',
        title: 'Debt Repaid',
        content: (
          <Flex gap="2xs" flexDirection="column">
            {Array.isArray(data) && data.length == 10 && (
              <Flex justifyContent="flex-end">
                <PopupTableModal
                  title="All debt repaid"
                  content={<DebtRepaidTable appliedFilters={appliedFilters} />}
                />
              </Flex>
            )}
            <AssetLiquidationsGraph
              data={data as AssetData[]}
              chartDaysAgo={appliedFilters.days_ago}
            />
          </Flex>
        )
      },
      {
        key: 'collateral_limit',
        title: 'Collateral Liquidated',
        content: (
          <Flex gap="2xs" flexDirection="column">
            {Array.isArray(data) && data.length == 10 && (
              <Flex justifyContent="flex-end">
                <PopupTableModal
                  title="All collateral liquidated"
                  content={
                    <CollateralLiquidatedTable
                      appliedFilters={appliedFilters}
                    />
                  }
                />
              </Flex>
            )}
            <CollateralAssetLiquidationsGraph
              data={data as CollateralAssetData[]}
              chartDaysAgo={appliedFilters.days_ago}
            />
          </Flex>
        )
      }
    ];
  }, [data, appliedFilters]);

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    );
  }

  return <ChartSkeleton />;
}
