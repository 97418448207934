import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function tableCell(theme: Theme): ComponentStyle {
  return {
    componentName: 'tableCell',
    baseStyle: {
      borderBottom: `1px solid ${theme.colors.border}`,
      fontSize: theme.sizes['0'],
      gap: theme.space['3xs']
    },
    variants: {
      stuck: {
        backgroundColor: `${theme.colors.grey10} !important`
      }
    }
  };
}
