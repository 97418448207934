import { ReactNode } from 'react';
import { Flex, FlexProps, BoxProps, Box } from '@blockanalitica/ui';
import styled, { css } from 'styled-components';

export interface FirstSymbolProps extends BoxProps {
  $entitySize: string;
  $hasSecondSymbol: boolean;
}

const FirstSymbol = styled(Box)<FirstSymbolProps>`
  z-index: 0;

  ${(props) =>
    props.$hasSecondSymbol &&
    css`
      margin-right: calc(${props.theme.sizes[props.$entitySize]} / -3);
    `}
  img {
    display: block;
    vertical-align: bottom;
  }
`;

const SecondSymbol = styled.div`
  z-index: 1;
  img {
    display: block;
    vertical-align: bottom;
  }
`;

export interface EntityNameProps extends FlexProps {
  symbol1?: ReactNode;
  symbol2?: ReactNode;
  entitySize?: string;
  children: ReactNode;
}

export default function EntityName({
  symbol1,
  symbol2,
  entitySize = '3',
  children,
  ...rest
}: EntityNameProps) {
  return (
    <Flex alignItems="center" gap="2xs" {...rest}>
      <Flex alignItems="center">
        {symbol1 ? (
          <FirstSymbol $entitySize={entitySize} $hasSecondSymbol={!!symbol2}>
            {symbol1}
          </FirstSymbol>
        ) : null}
        {symbol2 ? <SecondSymbol>{symbol2}</SecondSymbol> : null}
      </Flex>
      {children}
    </Flex>
  );
}
