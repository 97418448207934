import { useApi } from '@/api';
import { KpiSkeleton, PercentageValue, Flex } from '@blockanalitica/ui';
import { MultiKpiCard } from '@/components/kpi';

type PoolRatesKpisData = {
  underlying_address: string;
  borrow_apy: number | string;
  borrow_apy_7d: number | string;
  borrow_apy_30d: number | string;
  borrow_apy_90d: number | string;
  borrow_apy_change: number | string;
  borrow_apy_7d_change: number | string;
  borrow_apy_30d_change: number | string;
  borrow_apy_change_percentage: number | string;
  borrow_apy_7d_change_percentage: number | string;
  borrow_apy_30d_change_percentage: number | string;
  supply_apy: number | string;
  supply_apy_7d: number | string;
  supply_apy_30d: number | string;
  supply_apy_90d: number | string;
};

export default function PoolRatesKpis({
  poolId,
  protocol
}: {
  poolId: string;
  protocol: string;
}) {
  const [data, error] = useApi<PoolRatesKpisData>({
    url: `/pool/${protocol}/${poolId}/borrow-stats/`
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return (
      <>
        <KpiSkeleton />
        <KpiSkeleton />
        <KpiSkeleton />
        <KpiSkeleton />
      </>
    );
  }

  return (
    <Flex gap={['m', 's']} flexDirection={['column', 'row']}>
      <MultiKpiCard
        title="Current Borrow APY"
        data={data}
        field="borrow_apy"
        kpis={[
          {
            title: '7D AVG',
            content: <PercentageValue value={data.borrow_apy_7d} />
          },
          {
            title: '30D AVG',
            content: <PercentageValue value={data.borrow_apy_30d} />
          },
          {
            title: '90D AVG',
            content: <PercentageValue value={data.borrow_apy_90d} />
          }
        ]}
        options={{
          percentage: true,
          variants: {
            valuePercentageValue: 'dynamic'
          }
        }}
      />
      <MultiKpiCard
        title="Current Supply APY"
        data={data}
        field="supply_apy"
        kpis={[
          {
            title: '7D AVG',
            content: <PercentageValue value={data.supply_apy_7d} />
          },
          {
            title: '30D AVG',
            content: <PercentageValue value={data.supply_apy_30d} />
          },
          {
            title: '90D AVG',
            content: <PercentageValue value={data.supply_apy_90d} />
          }
        ]}
        options={{
          percentage: true,
          variants: {
            valuePercentageValue: 'dynamic'
          }
        }}
      />
    </Flex>
  );
}
