import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';

export default function CoinsStackIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 21"
      width={
        typeof size === 'number'
          ? size + 'px'
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? size + 'px'
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      {...props}>
      <path
        d="M11.6846 14.9C11.6846 17.6614 13.9231 19.9 16.6846 19.9C19.446 19.9 21.6846 17.6614 21.6846 14.9C21.6846 12.1386 19.446 9.89999 16.6846 9.89999C13.9231 9.89999 11.6846 12.1386 11.6846 14.9ZM11.6846 14.9C11.6846 13.7742 12.0567 12.7353 12.6846 11.8995V2.89999M11.6846 14.9C11.6846 15.7253 11.8846 16.504 12.2387 17.1901C11.3962 17.9018 9.45042 18.4 7.18457 18.4C4.147 18.4 1.68457 17.5046 1.68457 16.4V2.89999M12.6846 2.89999C12.6846 4.00456 10.2221 4.89999 7.18457 4.89999C4.147 4.89999 1.68457 4.00456 1.68457 2.89999M12.6846 2.89999C12.6846 1.79542 10.2221 0.899994 7.18457 0.899994C4.147 0.899994 1.68457 1.79542 1.68457 2.89999M1.68457 11.9C1.68457 13.0046 4.147 13.9 7.18457 13.9C9.37357 13.9 11.2639 13.435 12.1492 12.7618M12.6846 7.39999C12.6846 8.50456 10.2221 9.39999 7.18457 9.39999C4.147 9.39999 1.68457 8.50456 1.68457 7.39999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

CoinsStackIcon.displayName = '<CoinsStackIcon />';
