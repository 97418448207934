import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function collapsableSidebar(theme: Theme): ComponentStyle {
  return {
    componentName: 'collapsableSidebar',
    baseStyle: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '93%',
      width: '100vw',
      height: '500px',
      gap: theme.space.m,
      position: 'fixed',
      padding: `${theme.space.xs} ${theme.space.m}`,
      top: 0,
      left: 0,
      right: 0,
      background: `linear-gradient(
        180deg,
        ${theme.colors.grey15} 0%,
        #060507 15%
      )`,
      zIndex: 52,
      overflowY: 'auto',
      scrollbarWidth: 'none',
      transition: 'all 0.3s',
      visibility: 'visible'
    },
    variants: {
      closed: {
        // Shift the bar upward so it's hidden
        marginTop: '-400px',
        visibility: 'hidden'
      }
    }
  };
}
