import { Link } from '@/components';
import { protocolToTitleCase, transformString } from '@/utils/utils';
import {
  ArrowRightIcon,
  Box,
  Flex,
  Text,
  textShortener
} from '@blockanalitica/ui';
import { ReactNode, useMemo } from 'react';

type TCrumb = {
  href: string;
  text: ReactNode;
};

type BreadcrumbsProps = {
  crumbs: TCrumb[];
};

export default function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
  return (
    <Flex gap="2xs" display="inline-flex" alignItems="center">
      {crumbs.map((crumb, index) => (
        <Crumb
          key={crumb.href}
          last={crumbs.length === 1 || index === crumbs.length - 1}
          {...crumb}
        />
      ))}
    </Flex>
  );
}

type TCrumbProps = TCrumb & {
  last: boolean;
};

function Crumb({ href, text, last }: TCrumbProps) {
  const crumbChildren = useMemo(() => {
    if (typeof text === 'string') {
      if (text.startsWith('0x')) {
        return textShortener(text, {
          threshold: 11,
          firstSliceLength: 6,
          secondSliceLength: 4
        });
      }
      const shortText = textShortener(transformString(text), {
        threshold: 63,
        firstSliceLength: 4,
        secondSliceLength: 4
      });
      return protocolToTitleCase(shortText);
    }

    return text;
  }, [text]);

  if (last) {
    return <Text variant="2">{crumbChildren}</Text>;
  }

  return (
    <Flex display="inline-flex" gap="2xs" alignItems="center">
      {/* @ts-ignore */}
      <Box as={Link} to={href} fontSize="-1">
        {crumbChildren}
      </Box>
      <ArrowRightIcon size={12} />
    </Flex>
  );
}
