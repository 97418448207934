import { PaginatedResponse, useApi } from '@/api';
import {
  Card,
  TableSkeleton,
  Flex,
  Input,
  CurrencyValue,
  formatCurrency,
  Box
} from '@blockanalitica/ui';
import { useSearchParams } from '@/hooks';
import { useCallback, useState } from 'react';
import {
  ApplyButton,
  RateTableFilters,
  DropdownAssetsFilter
} from '@/components';
import StablecoinSupplyRatesEstimatorTable from './SupplyRatesEstimatorTable';

const MAX_AMOUNT = 5_000_000_000;
interface ExtendedPaginatedResponse extends PaginatedResponse<EstimatorPool[]> {
  stablecoins: { key: string; value: string }[];
  protocols: { key: string; value: string }[];
  collateral_tokens: { key: string; value: string }[];
}

export type EstimatorPool = {
  pool: string;
  protocol: string;
  rate: string;
  protocol_symbol: string;
  supply_apy: string;
  utilization: string | null;
  total_debt: string;
  underlying_symbol: string;
  base_rate: string | null;
  u_optimal: string | null;
  slope_rate_1: string | null;
  slope_rate_2: string | null;
  total_supply: string | null;
  pool_id: string;
  new_supply_apy: string;
  utilization_change: string;
  new_utilization: string;
  new_total_supply: string;
  supply_apy_change: string;
  total_supply_change: string;
  supplied_amount: string;
  undelrying_address: string;
  network: string;
};

export default function SupplyRatesEstimator() {
  const {
    pagination,
    sorting,
    tokensFiltered,
    protocolsFiltered,
    collateralTokensFiltered,
    filterByToken,
    filterByProtocol,
    filterByCollateralToken,
    updateSearchParams
  } = RateTableFilters({ sortingField: '-new_supply_apy' });

  const [amountParam] = useSearchParams('amount', '10000000');
  const [amount, setAmount] = useState<string>(amountParam ?? '');
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const handleAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (/^\d*$/.test(value)) {
        if (Number(value) <= MAX_AMOUNT) {
          setAmount(value);
          setIsFormUpdated(true);
        }
      }
    },
    []
  );

  const [data, error] = useApi<ExtendedPaginatedResponse>(
    {
      url: `/stablecoin-supply/rates-estimator/`,
      queryParams: {
        p: String(pagination.currentPage),
        p_size: String(pagination.pageSize),
        order: sorting.currentSorting,
        supply_amount: String(amountParam) || '',
        tokens: String(tokensFiltered),
        protocols: String(protocolsFiltered),
        collateral_tokens: String(collateralTokensFiltered)
      }
    },
    {
      keepPreviousData: true
    }
  );

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      updateSearchParams({ amount: Number(amount) });
      setIsFormUpdated(false);
    },
    [amount, updateSearchParams]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton rows={10} columns={7} />;
  }

  return (
    <Card flexDirection="column" gap="s">
      <Flex
        gap="m"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        borderBottom="solid 1px #35384b"
        paddingBottom="m"
        flexWrap="wrap">
        <Flex flexDirection="column" gap="3xs" as="form" onSubmit={submit}>
          <Flex fontSize="-1" color="muted" gap="2xs">
            <Box>Supply amount:</Box>
            <CurrencyValue
              value={Number(amount) || 0}
              options={{ currency: 'USD' }}
            />
          </Flex>
          <Flex gap="m">
            <Input
              variant="massive"
              type="text"
              value={amount}
              onChange={handleAmountChange}
              placeholder={`Enter amount up to ${formatCurrency(MAX_AMOUNT)}`}
            />
            <ApplyButton
              // @ts-ignore
              type="submit"
              disabled={
                !isFormUpdated ||
                isNaN(Number(amount)) ||
                Number(amount) <= 0 ||
                Number(amount) > MAX_AMOUNT
              }>
              Apply
            </ApplyButton>
          </Flex>
        </Flex>

        <Flex
          gap="xs"
          flexWrap={['wrap', 'nowrap']}
          justifyContent={['center', 'flex-end']}>
          <DropdownAssetsFilter
            initialValue={protocolsFiltered || []}
            titleFormat={(filteredCount) =>
              `Protocols filtered (${filteredCount}/${data['protocols'].length})`
            }
            callback={filterByProtocol}
            key="protocolsFilter"
            options={data['protocols']}
          />
          <DropdownAssetsFilter
            initialValue={tokensFiltered || []}
            titleFormat={(filteredCount) =>
              `Tokens filtered (${filteredCount}/${data['stablecoins'].length})`
            }
            callback={filterByToken}
            key="tokensFilter"
            options={data['stablecoins']}
          />
          <DropdownAssetsFilter
            initialValue={collateralTokensFiltered || []}
            titleFormat={(filteredCount) =>
              `Collateral filtered (${filteredCount}/${data['collateral_tokens'].length})`
            }
            callback={filterByCollateralToken}
            key="collateralFilter"
            options={data['collateral_tokens']}
          />
        </Flex>
      </Flex>

      <StablecoinSupplyRatesEstimatorTable
        results={data.results}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
        sorting={sorting}
        totalRecords={data.count}
      />
    </Card>
  );
}
