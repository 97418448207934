import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function selectTrigger(theme: Theme): ComponentStyle {
  return {
    componentName: 'selectTrigger',
    baseStyle: {
      justifyContent: 'space-between',
      backgroundColor: theme.colors.datepicker,
      color: theme.colors.text,
      cursor: 'pointer'
    },
    variants: {
      datepicker: {
        justifyContent: 'space-between',
        backgroundColor: theme.colors.datepicker,
        color: theme.colors.text,
        cursor: 'pointer',
        'div[name="text"]': {
          fontWeight: 600
        }
      },
      datepickerMobile: {
        justifyContent: 'space-between',
        backgroundColor: theme.colors.datepicker,
        color: theme.colors.text,
        cursor: 'pointer',
        'div[name="text"]': {
          fontSize: '4vw',
          fontWeight: 600
        }
      }
    }
  };
}
