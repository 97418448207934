import React, { ForwardedRef } from 'react';
import {
  SelectItem as RadixSelectItem,
  SelectItemProps as RadixSelectItemProps
} from '@radix-ui/react-select';
import { ThemedComponentWrapper } from '@blockanalitica/ui';
import type { ThemedComponentWrapperProps } from '@blockanalitica/ui';

type SelectItemVariant = 'baseStyle' | 'datepicker' | 'datepickerMobile';

interface ThemedSelectItemProps
  extends Partial<
      Omit<ThemedComponentWrapperProps<'div'>, 'variant' | 'color'>
    >,
    Omit<RadixSelectItemProps, 'color' | 'name'> {
  variant?: SelectItemVariant;
  color?: string;
  name?: string;
}

const SelectItem = React.forwardRef(
  (
    {
      children,
      name = 'selectItem',
      variant = 'baseStyle',
      ...rest
    }: ThemedSelectItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <ThemedComponentWrapper
        // @ts-ignore
        name={name}
        variant={variant}
        ref={ref}
        {...rest}>
        <RadixSelectItem {...rest}>{children}</RadixSelectItem>
      </ThemedComponentWrapper>
    );
  }
);

SelectItem.displayName = 'SelectItem';
export default SelectItem;
