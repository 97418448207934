import {
  Card,
  Flex,
  KpiHeader,
  CryptoIcon,
  useIsMobile,
  KpiCard
} from '@blockanalitica/ui';
import { EntityList } from '@/components';

export type TotalDebt = {
  total_debt: string;
  total_debt_change: string | null;
  total_debt_change_percentage: string | null;
};

export default function BorrowKpis({ data }: { data: TotalDebt }) {
  const isMobile = useIsMobile();

  return (
    <Flex flexDirection={['column', 'row']} gap="xs">
      <KpiCard
        title="Total Stablecoin Borrow"
        field="total_debt"
        data={data}
        options={{
          currency: 'USD'
        }}
        extra={
          <Flex
            flexDirection="column"
            gap="xs"
            alignItems="flex-end"
            // @ts-ignore
            display={isMobile ? 'none' : 'flex'}>
            <KpiHeader>Supported Protocols</KpiHeader>
            <EntityList showTotalNumber={false} entitySize="3">
              <CryptoIcon name="skyprotocol" size="5" />
              <CryptoIcon name="sparkprotocol" size="5" />
              <CryptoIcon name="aaveprotocol" size="5" />
              <CryptoIcon name="Morpho" size="5" />
              <CryptoIcon name="COMP" size="5" />
              <CryptoIcon name="fluid" size="5" />
              {/* <CryptoIcon name="liquity_v2" size="5" /> */}
            </EntityList>
          </Flex>
        }
      />
      <Card
        // @ts-ignore
        display={isMobile ? 'flex' : 'none'}>
        <Flex flexDirection="column" gap="xs">
          <KpiHeader>Supported Protocols</KpiHeader>
          <EntityList showTotalNumber={false} entitySize="3">
            <CryptoIcon name="skyprotocol" size="5" />
            <CryptoIcon name="sparkprotocol" size="5" />
            <CryptoIcon name="aaveprotocol" size="5" />
            <CryptoIcon name="Morpho" size="5" />
            <CryptoIcon name="COMP" size="5" />
            <CryptoIcon name="fluid" size="5" />
            {/* <CryptoIcon name="liquity_v2" size="5" /> */}
          </EntityList>
        </Flex>
      </Card>
    </Flex>
  );
}
