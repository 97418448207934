import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function selectItem(theme: Theme): ComponentStyle {
  return {
    componentName: 'selectItem',
    baseStyle: {},
    variants: {
      datepicker: {
        padding: `${theme.sizes['-2']} ${theme.sizes['-3']}`,
        fontSize: theme.sizes['-1'],
        color: theme.colors.text,
        width: '80%',
        cursor: 'pointer',
        display: 'block',
        '&:hover': {
          backgroundColor: theme.colors.grey45,
          borderRadius: theme.sizes['-4'],
          border: `1px solid ${theme.colors.primary}`
        }
      },
      datepickerMobile: {
        padding: `${theme.sizes['-2']} ${theme.sizes['-3']}`,
        fontSize: `4vw`,
        color: theme.colors.text,
        width: '80%',
        cursor: 'pointer',
        display: 'block',
        '&:hover': {
          backgroundColor: theme.colors.grey45,
          borderRadius: theme.sizes['-4'],
          border: `1px solid ${theme.colors.primary}`
        }
      }
    }
  };
}
