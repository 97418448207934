import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function dayPicker(theme: Theme): ComponentStyle {
  return {
    componentName: 'dayPicker',
    baseStyle: {
      '.rdp-root': {
        '--rdp-accent-color': 'blue',
        '--rdp-accent-background-color': '#f0f0ff',
        '--rdp-day-height': '44px',
        '--rdp-day-width': '44px',
        '--rdp-day_button-border-radius': '100%',
        '--rdp-day_button-border': '2px solid transparent',
        '--rdp-day_button-height': '42px',
        '--rdp-day_button-width': '42px',
        '--rdp-selected-border': '2px solid var(--rdp-accent-color)',
        '--rdp-disabled-opacity': '0.5',
        '--rdp-outside-opacity': '0.75',
        '--rdp-today-color': 'var(--rdp-accent-color)',
        '--rdp-dropdown-gap': '0.5rem',
        '--rdp-months-gap': '2rem',
        '--rdp-nav_button-disabled-opacity': '0.5',
        '--rdp-nav_button-height': '2.25rem',
        '--rdp-nav_button-width': '2.25rem',
        '--rdp-nav-height': '2.75rem',
        '--rdp-range_middle-background-color':
          'var(--rdp-accent-background-color)',
        '--rdp-range_middle-color': 'inherit',
        '--rdp-range_start-color': 'white',
        '--rdp-range_start-background':
          'linear-gradient(var(--rdp-gradient-direction), transparent 50%, var(--rdp-range_middle-background-color) 50%)',
        '--rdp-range_start-date-background-color': 'var(--rdp-accent-color)',
        '--rdp-range_end-background':
          'linear-gradient(var(--rdp-gradient-direction), var(--rdp-range_middle-background-color) 50%, transparent 50%)',
        '--rdp-range_end-color': 'white',
        '--rdp-range_end-date-background-color': 'var(--rdp-accent-color)',
        '--rdp-week_number-border-radius': '100%',
        '--rdp-week_number-border': '2px solid transparent',
        '--rdp-week_number-height': 'var(--rdp-day-height)',
        '--rdp-week_number-opacity': '0.75',
        '--rdp-week_number-width': 'var(--rdp-day-width)',
        '--rdp-week_number-text-align': 'center',
        '--rdp-weekday-opacity': '0.75',
        '--rdp-weekday-padding': '0.5rem 0rem',
        '--rdp-weekday-text-align': 'center',
        '--rdp-gradient-direction': '90deg',
        '--rdp-animation_duration': '0.3s',
        '--rdp-animation_timing': 'cubic-bezier(0.4, 0, 0.2, 1)'
      },
      '.rdp-root-container': {
        '--rdp-gradient-direction': '90deg',
        position: 'relative',
        boxSizing: 'border-box',
        '*': {
          boxSizing: 'border-box'
        }
      },
      '.rdp-root[dir="rtl"]': {
        '--rdp-gradient-direction': '-90deg'
      },
      '.rdp-root[data-broadcast-calendar="true"]': {
        '--rdp-outside-opacity': 'unset'
      },
      '.rdp-day': {
        width: 'var(--rdp-day-width)',
        height: 'var(--rdp-day-height)',
        textAlign: 'center'
      },
      '.rdp-day_button': {
        background: 'none',
        padding: '0',
        margin: '0',
        cursor: 'pointer',
        font: 'inherit',
        color: 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: 'var(--rdp-day_button-width)',
        height: 'var(--rdp-day_button-height)',
        border: 'var(--rdp-day_button-border)',
        borderRadius: 'var(--rdp-day_button-border-radius)'
      },
      '.rdp-day_button:disabled': {
        cursor: 'revert'
      },
      '.rdp-caption_label': {
        zIndex: 1,
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        border: 0
      },
      '.rdp-dropdown:focus-visible ~ .rdp-caption_label': {
        outline: '5px auto -webkit-focus-ring-color'
      },
      '.rdp-button_next, .rdp-button_previous': {
        border: 'none',
        background: 'none',
        padding: '0',
        margin: '0',
        cursor: 'pointer',
        font: 'inherit',
        color: 'inherit',
        appearance: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: 'var(--rdp-nav_button-width)',
        height: 'var(--rdp-nav_button-height)'
      },
      '.rdp-button_next:disabled, .rdp-button_previous:disabled': {
        cursor: 'revert',
        opacity: 'var(--rdp-nav_button-disabled-opacity)'
      },
      '.rdp-chevron': {
        display: 'inline-block',
        fill: 'var(--rdp-accent-color)'
      },
      '.rdp-root[dir="rtl"] .rdp-nav .rdp-chevron': {
        transform: 'rotate(180deg)',
        transformOrigin: '50%'
      },
      '.rdp-dropdowns': {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        gap: 'var(--rdp-dropdown-gap)'
      },
      '.rdp-dropdown': {
        zIndex: 2,
        opacity: 0,
        appearance: 'none',
        position: 'absolute',
        insetBlockStart: 0,
        insetBlockEnd: 0,
        insetInlineStart: 0,
        width: '100%',
        margin: 0,
        padding: 0,
        cursor: 'inherit',
        border: 'none',
        lineHeight: 'inherit'
      },
      '.rdp-dropdown_root': {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center'
      },
      '.rdp-dropdown_root[data-disabled="true"] .rdp-chevron': {
        opacity: 'var(--rdp-disabled-opacity)'
      },
      '.rdp-month_caption': {
        display: 'flex',
        alignContent: 'center',
        height: 'var(--rdp-nav-height)',
        fontWeight: 'bold',
        fontSize: 'large'
      },
      '.rdp-months': {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--rdp-months-gap)',
        maxWidth: 'fit-content'
      },
      '.rdp-month_grid': {
        borderCollapse: 'collapse'
      },
      '.rdp-nav': {
        position: 'absolute',
        insetBlockStart: 0,
        insetInlineEnd: 0,
        display: 'flex',
        alignItems: 'center',
        height: 'var(--rdp-nav-height)'
      },
      '.rdp-weekday': {
        opacity: 'var(--rdp-weekday-opacity)',
        padding: 'var(--rdp-weekday-padding)',
        fontWeight: 500,
        fontSize: 'smaller',
        textAlign: 'var(--rdp-weekday-text-align)'
      },
      '.rdp-week_number': {
        opacity: 'var(--rdp-week_number-opacity)',
        fontWeight: 400,
        fontSize: 'small',
        height: 'var(--rdp-week_number-height)',
        width: 'var(--rdp-week_number-width)',
        border: 'var(--rdp-week_number-border)',
        borderRadius: 'var(--rdp-week_number-border-radius)',
        textAlign: 'var(--rdp-weeknumber-text-align)'
      },
      '.rdp-today:not(.rdp-outside)': {
        color: 'var(--rdp-today-color)'
      },
      '.rdp-selected': {
        fontWeight: 'bold',
        fontSize: 'large'
      },
      '.rdp-selected .rdp-day_button': {
        border: 'var(--rdp-selected-border)'
      },
      '.rdp-outside': {
        opacity: 'var(--rdp-outside-opacity)'
      },
      '.rdp-disabled': {
        opacity: 'var(--rdp-disabled-opacity)'
      },
      '.rdp-hidden': {
        visibility: 'hidden',
        color: 'var(--rdp-range_start-color)'
      },
      '.rdp-range_start': {
        background: 'var(--rdp-range_start-background)'
      },
      '.rdp-range_start .rdp-day_button': {
        backgroundColor: 'var(--rdp-range_start-date-background-color)',
        color: 'var(--rdp-range_start-color)'
      },
      '.rdp-range_middle': {
        backgroundColor: 'var(--rdp-range_middle-background-color)'
      },
      '.rdp-range_middle .rdp-day_button': {
        borderColor: 'transparent',
        border: 'unset',
        borderRadius: 'unset',
        color: 'var(--rdp-range_middle-color)'
      },
      '.rdp-range_end': {
        background: 'var(--rdp-range_end-background)',
        color: 'var(--rdp-range_end-color)'
      },
      '.rdp-range_end .rdp-day_button': {
        color: 'var(--rdp-range_start-color)',
        backgroundColor: 'var(--rdp-range_end-date-background-color)'
      },
      '.rdp-range_start.rdp-range_end': {
        background: 'revert'
      },
      '.rdp-focusable': {
        cursor: 'pointer'
      },
      '@keyframes rdp-slide_in_left': {
        '0%': {
          transform: 'translateX(-100%)'
        },
        '100%': {
          transform: 'translateX(0)'
        }
      },
      '@keyframes rdp-slide_in_right': {
        '0%': {
          transform: 'translateX(100%)'
        },
        '100%': {
          transform: 'translateX(0)'
        }
      },
      '@keyframes rdp-slide_out_left': {
        '0%': {
          transform: 'translateX(0)'
        },
        '100%': {
          transform: 'translateX(-100%)'
        }
      },
      '@keyframes rdp-slide_out_right': {
        '0%': {
          transform: 'translateX(0)'
        },
        '100%': {
          transform: 'translateX(100%)'
        }
      },
      '.rdp-weeks_before_enter': {
        animation:
          'rdp-slide_in_left var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-weeks_before_exit': {
        animation:
          'rdp-slide_out_left var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-weeks_after_enter': {
        animation:
          'rdp-slide_in_right var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-weeks_after_exit': {
        animation:
          'rdp-slide_out_right var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-root[dir="rtl"] .rdp-weeks_after_enter': {
        animation:
          'rdp-slide_in_left var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-root[dir="rtl"] .rdp-weeks_before_exit': {
        animation:
          'rdp-slide_out_right var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-root[dir="rtl"] .rdp-weeks_before_enter': {
        animation:
          'rdp-slide_in_right var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-root[dir="rtl"] .rdp-weeks_after_exit': {
        animation:
          'rdp-slide_out_left var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '@keyframes rdp-fade_in': {
        from: {
          opacity: 0
        },
        to: {
          opacity: 1
        }
      },
      '@keyframes rdp-fade_out': {
        from: {
          opacity: 1
        },
        to: {
          opacity: 0
        }
      },
      '.rdp-caption_after_enter': {
        animation:
          'rdp-fade_in var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-caption_after_exit': {
        animation:
          'rdp-fade_out var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-caption_before_enter': {
        animation:
          'rdp-fade_in var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      },
      '.rdp-caption_before_exit': {
        animation:
          'rdp-fade_out var(--rdp-animation_duration) var(--rdp-animation_timing) forwards'
      }
    },
    variants: {
      datepicker: {
        '.rdp-root': {
          '--rdp-accent-color': theme.colors.primary,
          '--rdp-accent-background-color': theme.colors.primary,
          '--rdp-day-height': theme.sizes['3'],
          '--rdp-day-width': theme.sizes['3'],
          '--rdp-day_button-height': theme.sizes['3'],
          '--rdp-day_button-width': theme.sizes['3'],
          '--rdp-nav-height': theme.sizes['3'],
          backgroundColor: theme.colors.datepicker,
          color: theme.colors.text,
          borderRadius: theme.sizes['-4'],
          padding: theme.space['xs']
        },
        '.rdp-dropdowns': {
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '100%'
        },
        '.rdp-today:not(.rdp-outside) .rdp-day_button': {
          border: `1px solid ${theme.colors.grey85}`,
          color: theme.colors.grey85,
          borderRadius: '100%'
        },
        '.rdp-today.rdp-selected .rdp-day_button': {
          color: theme.colors.black,
          border: 'none'
        },
        '.rdp-nav': {
          position: 'absolute',
          top: 0,
          left: 0,
          justifyContent: 'space-between',
          padding: theme.space['xs']
        },
        '.rdp-caption_label': {
          margin: '0 auto',
          fontWeight: 600,
          fontSize: theme.sizes['0']
        },
        svg: {
          fill: theme.colors.white
        },
        '.rdp-button_previous': {
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          color: theme.colors.white,
          width: theme.sizes['2'],
          position: 'absolute',
          left: 0
        },
        '.rdp-button_next': {
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          width: theme.sizes['2'],
          color: theme.colors.white,
          position: 'absolute',
          right: 0
        },

        '.rdp-week': {
          borderBottom: `2px solid ${theme.colors.datepicker}`
        },
        '.rdp-weekday': {
          fontSize: theme.sizes['-1'],
          fontWeight: 400
        },
        '.rdp-day_button': {
          transition: 'background-color 0.2s ease',
          borderRadius: '100%',
          fontSize: theme.sizes['-1'],
          fontWeight: 400,
          ':hover': {
            backgroundColor: theme.colors.grey45
          }
        },
        '.rdp-selected .rdp-day_button': {
          color: theme.colors.black
        },
        '.rdp-range_middle .rdp-day_button': {
          color: `${theme.colors.grey85}`,
          backgroundColor: 'none'
        },
        '.rdp-range_start .rdp-day_button, .rdp-range_end .rdp-day_button': {
          borderRadius: '100%',
          backgroundColor: theme.colors.white,
          border: 'none'
        },
        '.rdp-range_middle:last-of-type': {
          borderTopRightRadius: '100%',
          borderBottomRightRadius: '100%'
        },
        '.rdp-range_middle:first-of-type': {
          borderTopLeftRadius: '100%',
          borderBottomLeftRadius: '100%'
        },
        "[data-state='open'][type='button']": {
          backgroundColor: theme.colors.grey45,
          width: theme.sizes['10'],
          borderRadius: theme.sizes['-4'],
          border: 'none'
        },
        "[data-state='closed'][type='button']": {
          backgroundColor: 'none',
          border: 'none',
          width: theme.sizes['10'],
          borderRadius: theme.sizes['-4']
        }
      },
      //"----------------------""
      datepickerMobile: {
        '.rdp-root': {
          '--rdp-accent-color': theme.colors.primary,
          '--rdp-accent-background-color': theme.colors.primary,
          '--rdp-day-height': '12vw',
          '--rdp-day-width': '12vw',
          '--rdp-day_button-height': '12vw',
          '--rdp-day_button-width': '12vw',
          '--rdp-nav-height': '12vw',
          backgroundColor: theme.colors.datepicker,
          color: theme.colors.text,
          borderRadius: theme.sizes['-4'],
          padding: '1vw'
        },
        //styles both dropdowns, position only//
        '.rdp-dropdowns': {
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '100%'
        },
        //styles the today button when not selected//
        '.rdp-today:not(.rdp-outside) .rdp-day_button': {
          border: `1px solid ${theme.colors.grey85}`,
          color: theme.colors.grey85,
          borderRadius: '100%'
        },
        //styles the today button when selected//
        '.rdp-today.rdp-selected .rdp-day_button': {
          color: theme.colors.black,
          border: 'none'
        },
        '.rdp-nav': {
          position: 'absolute',
          top: 0,
          left: 0,
          justifyContent: 'space-between',
          padding: '-2vw'
        },
        '.rdp-month_caption': {
          paddingTop: '2vw',
          paddingBottom: '2vw',
          height: '6vw'
        },
        '.rdp-week': {
          borderBottom: `1vw solid ${theme.colors.datepicker}`
        },

        '.rdp-weekday': {
          fontSize: '4vw',
          fontWeight: 400
        },

        '.rdp-day_button': {
          transition: 'background-color 0.2s ease',
          borderRadius: '100%',
          fontSize: '4vw',
          fontWeight: 400,
          ':hover': {
            backgroundColor: theme.colors.grey45
          }
        },
        //colors the selected dates//
        '.rdp-selected .rdp-day_button': {
          color: theme.colors.black
        },
        //colors the range middle buttons//
        '.rdp-range_middle .rdp-day_button': {
          color: `${theme.colors.grey85}`,
          backgroundColor: 'transparent'
        },
        //colors the range start and range end buttons//
        '.rdp-range_start .rdp-day_button, .rdp-range_end .rdp-day_button': {
          borderRadius: '100%',
          backgroundColor: theme.colors.white,
          border: 'none'
        },
        //shapes the accent on selected dates//
        '.rdp-range_middle:last-of-type': {
          borderTopRightRadius: '100%',
          borderBottomRightRadius: '100%'
        },
        '.rdp-range_middle:first-of-type': {
          borderTopLeftRadius: '100%',
          borderBottomLeftRadius: '100%'
        },
        //styles the month and year buttons//
        "[data-state='open'][type='button']": {
          backgroundColor: theme.colors.grey45,
          width: '40vw',
          height: '8vw',
          borderRadius: theme.sizes['-4'],
          border: 'none'
        },
        "[data-state='closed'][type='button']": {
          backgroundColor: 'none',
          width: '40vw',
          height: '8vw',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: theme.sizes['-4'],
          border: 'none'
        },
        //removes the grid lines?? or is it the outline overflow and this does nothing?//
        '.rdp-month_grid td': {
          border: 'none !important',
          borderSpacing: '0 !important'
        }
      }
    }
  };
}
