import { assetNameToRgba } from '@blockanalitica/ui';

const PROTOCOL_COLORS: Record<string, string> = {
  aave_core: '#9896ff',
  aave_prime: '#34b5c4',
  compound_v3: '#00D395',
  sky: '#00c2a2',
  morpho: '#5792ff',
  spark: '#f76d36',
  liquity_v2: '#405ae5',
  fluid: '#3F75FF'
};

function extractProtocolFromPoolId(poolId: string): {
  protocol: string;
  subset: string;
} {
  const parts = poolId.split(' ');
  const protocol = parts[0];
  const subset = parts.slice(1).join('_') || '';
  return { protocol, subset };
}

function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function LightenDarkenColor(col: string, amt: number) {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  let b = ((num >> 8) & 0x00ff) + amt;
  let g = (num & 0x0000ff) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  const newColor = (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
  return (usePound ? '#' : '') + newColor;
}

function generateDistinctColor(baseColor: string, subset: string): string {
  const hashVal = hashString(subset);
  const amt = (Math.abs(hashVal) % 101) - 50;

  return LightenDarkenColor(baseColor, amt);
}

export function generateColorFromPool(poolId: string) {
  const { protocol, subset } = extractProtocolFromPoolId(poolId);
  const baseColor = PROTOCOL_COLORS[protocol];
  const newColor = generateDistinctColor(baseColor, subset);
  return newColor;
}

export function protocolToTitleCase(str: string): string {
  return str
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function generateDistinctColors(count: number) {
  return Array.from(
    { length: count },
    (_, i) => `hsl(${((i * 360) / count) % 360}, 70%, 50%)`
  );
}

const colorPalette = generateDistinctColors(81);

export function getColorForKey(key: string) {
  const hash = key.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colorPalette[hash % colorPalette.length]; // Consistent color selection
}

export function transformString(str: string): string {
  return str
    .split('-') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words with a space
}

export const getProtocolColor = (protocol: string): string => {
  return PROTOCOL_COLORS[protocol] || '#fff';
};

export function generateColorFromName(name: string): string {
  let hash = 0;

  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}

export function getAssetColor(key: string, transparent = false): string {
  let color = assetNameToRgba(key) || generateColorFromName(key);
  if (transparent) {
    const alpha = 0.01;
    if (color.startsWith('rgba(')) {
      color = color.replace(
        /rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/,
        `rgba($1, $2, $3, ${alpha})`
      );
    } else if (color.startsWith('#')) {
      const r = parseInt(color.substring(1, 3), 16);
      const g = parseInt(color.substring(3, 2), 16);
      const b = parseInt(color.substring(5, 2), 16);
      color = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  }
  return color;
}

interface Option {
  value: string;
  label: string;
  [key: string]: any;
}

export function prioritizeSelectedOptions(
  options: Option[],
  selectedOptions: string[]
): Option[] {
  // Create a new array to avoid mutating the original options
  const sortedOptions = [...options];

  // Sort options to prioritize selected ones
  sortedOptions.sort((a, b) => {
    const aIsSelected = selectedOptions.includes(a.value);
    const bIsSelected = selectedOptions.includes(b.value);

    if (aIsSelected && !bIsSelected) {
      return -1; // a comes first
    }
    if (!aIsSelected && bIsSelected) {
      return 1; // b comes first
    }
    return 0; // no change in order for items not affecting the condition
  });

  return sortedOptions;
}

export function toUTCISO(date: Date): string {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toISOString();
}
