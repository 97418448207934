import React from 'react';
import {
  SelectTrigger as RadixSelectTrigger,
  SelectTriggerProps as RadixSelectTriggerProps
} from '@radix-ui/react-select';
import { ThemedComponentWrapper } from '@blockanalitica/ui';
import type { ThemedComponentWrapperProps } from '@blockanalitica/ui';
import type { ForwardedRef } from 'react';

type SelectTriggerVariant = 'baseStyle' | 'datepicker' | 'datepickerMobile';

interface ThemedSelectTriggerProps
  extends Partial<
      Omit<ThemedComponentWrapperProps<'button'>, 'variant' | 'color'>
    >,
    Omit<RadixSelectTriggerProps, 'color' | 'name'> {
  variant?: SelectTriggerVariant;
  color?: string;
  name?: string;
}

const SelectTrigger = React.forwardRef(
  (
    {
      children,
      name = 'selectTrigger',
      variant = 'baseStyle',
      ...rest
    }: ThemedSelectTriggerProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <ThemedComponentWrapper
        // @ts-ignore
        as={RadixSelectTrigger}
        name={name}
        variant={variant}
        ref={ref}
        {...rest}>
        {children}
      </ThemedComponentWrapper>
    );
  }
);

SelectTrigger.displayName = 'SelectTrigger';
export default SelectTrigger;
