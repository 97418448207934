import { useCallback, JSX } from 'react';
import { Box, Flex, CryptoIcon } from '@blockanalitica/ui';
import type { CryptoIconProps } from '@blockanalitica/ui/package/types/src/components/atoms/CryptoIcon.d.ts';
import type { TableProps } from '@blockanalitica/ui/package/types/src/hooks/useTableProps.d.ts';
import Dropdown, { DropdownProps } from './Dropdown';
import DropdownOption from './DropdownOption';

interface TableFilter {
  filtersApplied: Record<string, string> | Record<string, string[]>;
  updateFilters: (updated: Record<string, string | string[]>) => void;
}

export type DropdownFilterOption = {
  value: string;
  label: string;
};

export interface DropdownFilterProps
  extends Omit<DropdownProps, 'children' | 'options'>,
    TableFilter,
    Partial<TableProps> {
  filterField?: string;
  placeholder?: string;
  options: DropdownFilterOption[];
  multi?: boolean;
  withCryptoIcon?: boolean;
  cryptoIconProps?: Partial<CryptoIconProps>;
  titleFormat?: ((count: number) => string) | undefined;
}

export default function CustomDropdownFilter({
  filterField = 'selectedOption',
  updateFilters,
  filtersApplied,
  pagination,
  placeholder,
  options,
  search = false,
  multi = false,
  titleFormat = undefined,
  variant = 'dropdownFilter',
  withCryptoIcon = false,
  cryptoIconProps = { network: 'ethereum', size: '1' },
  ...rest
}: DropdownFilterProps): JSX.Element {
  const onChange = useCallback(
    (value: string | string[]) => {
      updateFilters({
        [filterField]: value
      });
      if (pagination?.onPageChange) {
        pagination.onPageChange(1);
      }
    },
    [filterField, pagination, updateFilters]
  );

  return (
    <Dropdown
      placeholder={placeholder}
      onChange={onChange}
      variant={variant}
      initialValue={filtersApplied[filterField]}
      titleFormat={titleFormat}
      search={search}
      iconSize="0"
      options={{
        variants: {
          dropdownHeader: variant,
          dropdownList: variant
        }
      }}
      {...rest}
      multi={multi}>
      {options.map((option) => (
        <DropdownOption
          value={option.value}
          label={option.label}
          key={option.label}
          variant={variant}>
          <Flex gap="3xs" alignItems="center">
            {withCryptoIcon ? (
              <CryptoIcon
                name={option.label}
                size={cryptoIconProps.size}
                address={option.value}
                network={cryptoIconProps.network}
                {...cryptoIconProps}
              />
            ) : null}
            <Box>{option.label}</Box>
          </Flex>
        </DropdownOption>
      ))}
    </Dropdown>
  );
}
