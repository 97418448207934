import {
  Box,
  Flex,
  useIsMobile,
  ChevronDownIcon,
  HomeIcon,
  useToggle,
  CrossIcon,
  BoxProps,
  FlexProps
} from '@blockanalitica/ui';
import { Outlet, Link } from 'react-router';
import { ReactNode, useState, useEffect } from 'react';
import BorrowHandIcon from '@/components/icons/BorrowHandIcon';
import SupplyHandIcon from '@/components/icons/SupplyHandIcons';
import RRLineIcon from '@/components/icons/RRLineIcons';
import StockDownIcon from '@/components/icons/StockDownIcon';
import WeighingScaleIcon from '@/components/icons/WeighingScaleIcon';
import CoinsStackIcon from '@/components/icons/CoinsStackIcon';
import CollapsableSidebar from '@/components/sidebars/CollapsableSidebar';
import MenuIcon from '@/components/icons/MenuIcon';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {
  default as PoweredByBA
  // @ts-ignore
} from '@/assets/logos/powered_by_ba.svg?react';
import {
  default as SphereLogo
  // @ts-ignore
} from '@/assets/logos/sphere_with_name.svg?react';
import { ExternalLink } from '@/components';
import Footer from './Footer';
import { useLocation } from 'react-router';

const MenuDropDownItem = styled(Flex)`
  transition: background-color 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const ContentWrapper = styled(Flex)`
  box-sizing: border-box;
  padding: ${(props) =>
    `${props.theme.space['xs']} ${props.theme.space['s-m']} ${props.theme.space['s-m']}`};
`;

const AlphaBackgroundLayer = styled.div`
  display: block;
  position: fixed;
  inset: 0;
  background-color: ${(props) => props.theme.colors.black65};
  z-index: 49;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;
const SidebarButton = styled(Flex)<FlexProps>`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.linkHover};
  }
`;

const CloseButton = styled(Box)<BoxProps>`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.linkHover};
  }
`;
export default function Layout({
  children
}: {
  children?: ReactNode | ReactNode[];
}) {
  const [sidebarOpen, toggleSidebar] = useToggle();
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { pathname } = useLocation();

  useEffect(() => {
    toggleSidebar(false);
  }, [pathname, toggleSidebar]);

  type CategoryTitle = 'Stablecoins' | 'Risk';

  const navigationCategories: {
    title: string;
    icon: React.ReactNode;
    linkTo?: string;
  }[] = [
    { title: 'Home', icon: <Box size="2" />, linkTo: '/' },
    { title: 'Stablecoins', linkTo: '', icon: <CoinsStackIcon size="2" /> },
    { title: 'Risk', linkTo: '', icon: <RRLineIcon size="2" /> }
  ];

  const categoryRoutes: Record<
    CategoryTitle,
    { title: string; linkTo: string; icon: React.ReactElement }[]
  > = {
    Stablecoins: [
      {
        title: 'Supply',
        linkTo: '/supply',
        icon: <SupplyHandIcon size={isMobile ? '4' : '2'} />
      },
      {
        title: 'Borrow',
        linkTo: '/borrow',
        icon: <BorrowHandIcon size={isMobile ? '4' : '2'} />
      },
      {
        title: 'Leverage',
        linkTo: '/leverage',
        icon: <WeighingScaleIcon size={isMobile ? '4' : '2'} />
      }
    ],
    Risk: [
      {
        title: 'Liquidations',
        linkTo: '/liquidations',
        icon: <StockDownIcon size={isMobile ? '4' : '2'} />
      }
    ]
  };

  const loc =
    pathname === '/supply' || pathname === '/borrow' || pathname === '/leverage'
      ? 'Stablecoins'
      : pathname === '/liquidations'
        ? 'Risk'
        : 'Home';

  return (
    <Flex width="100vw">
      <CollapsableSidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        alignItems="flex-start"
        borderBottom="1px solid"
        borderColor={theme.colors.grey25}>
        {/* Top row with logo and close button */}
        <Flex justifyContent="space-between" width="100%">
          <SphereLogo height={50} alt="Sphere" />

          <CloseButton alignSelf="center" onClick={() => toggleSidebar(false)}>
            <CrossIcon size="3" />
          </CloseButton>
        </Flex>

        {/* Loop through main categories */}
        {navigationCategories.map((category) => {
          // If it's 'Home', just render a direct link
          if (category.title === 'Home') {
            return (
              <Box
                // @ts-ignore
                as={Link}
                key={category.title + 'box'}
                to={category.linkTo}
                width="100%">
                <Flex
                  key={category.title}
                  alignItems="center"
                  gap="xs"
                  backgroundColor={
                    loc === category.title ? theme.colors.grey25 : 'transparent'
                  }
                  color={theme.colors.text}
                  padding="s 0 s 0"
                  fontSize="2"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor={
                    loc === category.title
                      ? theme.colors.primary
                      : 'transparent'
                  }
                  style={{ fontWeight: 700 }}>
                  <HomeIcon size="3" style={{ marginLeft: '15px' }} />
                  {category.title}
                </Flex>
              </Box>
            );
          }

          // Otherwise, show the category and sub-routes
          const subRoutes =
            categoryRoutes[category.title as CategoryTitle] || [];

          return (
            <Box key={category.title} width="100%">
              {/* Category label (e.g. "Stablecoins", "Risk") */}
              <Flex
                alignItems="center"
                gap="xs"
                fontSize="2"
                style={{ fontWeight: 700 }}
                marginTop="m"
                marginLeft="15px">
                {category.title}
              </Flex>

              {/* Sub-routes (e.g. "Supply", "Borrow", "Leverage") */}
              <Flex
                flexDirection="column"
                marginTop="m"
                gap="m"
                fontSize="2"
                style={{ fontWeight: 400 }}>
                {subRoutes.map((route) => (
                  <Box
                    key={route.title}
                    // @ts-ignore
                    as={Link}
                    to={route.linkTo}
                    backgroundColor={
                      route.linkTo === pathname
                        ? theme.colors.grey25
                        : 'transparent'
                    }
                    borderColor={
                      route.linkTo === pathname
                        ? theme.colors.primary
                        : 'transparent'
                    }
                    border="1px solid"
                    borderRadius="8px"
                    padding="s 0 s 0">
                    <Flex
                      alignItems="center"
                      gap="xs"
                      marginLeft="15px"
                      color={
                        route.linkTo === pathname
                          ? theme.colors.text
                          : theme.colors.grey69
                      }>
                      {route.icon}
                      {route.title}
                    </Flex>
                  </Box>
                ))}
              </Flex>
            </Box>
          );
        })}
      </CollapsableSidebar>

      <ContentWrapper
        flexGrow={1}
        flexShrink={1}
        flexBasis={['100%', 'auto']}
        margin="0 auto"
        width="100%"
        maxWidth={['100vw', '80rem']}
        flexDirection="column"
        gap="s">
        <Flex justifyContent="space-between" alignItems="center">
          <Box
            // @ts-ignore
            as={Link}
            to="/">
            <SphereLogo height={50} alt="Sphere" />
          </Box>

          {!isMobile ? (
            <>
              <Flex as="nav" gap="m" alignItems="center">
                {navigationCategories.map((category) => (
                  <Box
                    key={category.title}
                    onMouseEnter={() => setHoveredCategory(category.title)}
                    onMouseLeave={() => setHoveredCategory(null)}
                    paddingBottom="xs"
                    cursor="pointer"
                    style={{ position: 'relative' }}
                    // @ts-ignore
                    as={category.title === 'Home' ? Link : Box}
                    to={category.linkTo}>
                    <Flex
                      alignItems="center"
                      gap="xs"
                      border="1px solid"
                      borderRadius="3"
                      borderColor={
                        loc === category.title ||
                        hoveredCategory === category.title
                          ? theme.colors.primary
                          : 'transparent'
                      }
                      color={
                        loc === category.title ||
                        hoveredCategory === category.title
                          ? theme.colors.text
                          : theme.colors.grey69
                      }
                      backgroundColor={
                        hoveredCategory === category.title
                          ? theme.colors.grey20
                          : 'transparent'
                      }
                      padding="xs m xs m"
                      style={{ fontWeight: 500 }}>
                      {category.icon}
                      {category.title}
                      {category.title !== 'Home' && (
                        <ChevronDownIcon
                          size="0"
                          style={{ marginTop: '4px' }}
                        />
                      )}
                      {category.title === 'Home' && (
                        <Box size="3" width="0px" />
                      )}
                    </Flex>

                    {/* Show dropdown only for the hovered category */}
                    {hoveredCategory === category.title &&
                      categoryRoutes[category.title as CategoryTitle] && (
                        <Flex
                          flexDirection="column"
                          gap="xs"
                          marginTop="xs"
                          style={{
                            position: 'absolute',
                            zIndex: 9999,
                            backgroundColor: theme.colors.grey20,
                            borderRadius: '12px',
                            border: `1px solid ${theme.colors.grey35}`,
                            padding: theme.space.s
                          }}>
                          {(
                            categoryRoutes[category.title as CategoryTitle] ||
                            []
                          ).map((route) => (
                            <Link to={route.linkTo} key={route.title}>
                              <MenuDropDownItem
                                key={route.title}
                                gap="xs"
                                padding="xs m xs m"
                                borderRadius="12px"
                                color={theme.colors.text}
                                alignItems="center">
                                {route.icon}
                                {route.title}
                              </MenuDropDownItem>
                            </Link>
                          ))}
                        </Flex>
                      )}
                  </Box>
                ))}
              </Flex>
              <ExternalLink color="white" href="https://blockanalitica.com">
                <PoweredByBA height={45} />
              </ExternalLink>
            </>
          ) : (
            <SidebarButton>
              <Flex onClick={() => toggleSidebar()}>
                <MenuIcon size="3" />
              </Flex>
            </SidebarButton>
          )}
        </Flex>

        <main>{children ? children : <Outlet />}</main>
        <Footer />
      </ContentWrapper>
      {sidebarOpen ? (
        <AlphaBackgroundLayer onClick={() => toggleSidebar()} />
      ) : null}
    </Flex>
  );
}
