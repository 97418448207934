import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function displaySwitch(theme: Theme): ComponentStyle {
  return {
    componentName: 'displaySwitch',
    baseStyle: {
      flex: 1,
      backgroundColor: theme.colors.grey10,
      borderRadius: theme.sizes['-3']
    },
    variants: {
      bubble: {
        background: 'none'
      },
      liquidations: {
        background: 'none',
        flex: '1',
        borderRadius: theme.sizes['-4']
      },
      ssr: {
        background: 'none',
        border: `1px solid ${theme.colors.border}`,
        gap: 0
      }
    }
  };
}
