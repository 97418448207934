import { DayPicker, DayPickerProps } from 'react-day-picker';
import { ThemedComponentWrapper } from '@blockanalitica/ui';
import type { ThemedComponentWrapperProps } from '@blockanalitica/ui';
export type DayPickerVariant = 'baseStyle' | 'datepicker' | 'datepickerMobile';

export type ThemedDayPickerProps = Partial<ThemedComponentWrapperProps> &
  DayPickerProps & {
    variant?: DayPickerVariant;
  };

export default function ThemedDayPicker(props: ThemedDayPickerProps) {
  const { variant = 'baseStyle', ...dayPickerProps } = props;

  return (
    <ThemedComponentWrapper name="dayPicker" variant={variant}>
      <DayPicker {...dayPickerProps} />
    </ThemedComponentWrapper>
  );
}
