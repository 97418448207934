import { useTheme } from 'styled-components';
import { Select, SelectValue, SelectGroup } from '@radix-ui/react-select';
import {
  SelectTrigger,
  SelectContent,
  SelectItem,
  DayPicker
} from '@/components/';
import { useState } from 'react';
import {
  Text,
  ChevronUpIcon,
  ChevronDownIcon,
  useIsMobile
} from '@blockanalitica/ui';
import { DateRange } from 'react-day-picker';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  onSelect?: (range: DateRange | undefined) => void;
};

interface DropdownProps {
  options?: { label: string; value: string | number; disabled?: boolean }[];
  value?: string | number | readonly string[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function CustomSelectDropdown(props: DropdownProps) {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { options, value, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleValueChange = (newValue: string) => {
    if (onChange) {
      const syntheticEvent = {
        target: { value: newValue }
      } as React.ChangeEvent<HTMLSelectElement>;
      onChange(syntheticEvent);
    }
  };
  const variant = isMobile ? 'datepickerMobile' : 'datepicker';
  const isMonthDropdown = options?.length === 12;
  const style = isMobile
    ? {
        position: 'relative',
        right: isMonthDropdown ? '-22%' : null,
        left: isMonthDropdown ? null : '-22%'
      }
    : {
        position: 'relative',
        right: isMonthDropdown ? '-23%' : null,
        left: isMonthDropdown ? null : '-26%'
      };
  return (
    <Select
      value={value?.toString()}
      onValueChange={handleValueChange}
      open={isOpen}
      onOpenChange={setIsOpen}>
      <SelectTrigger name="selectTrigger" variant={variant}>
        <Text
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: theme.sizes['-2']
          }}>
          {options?.find((option) => option.value === value)?.label ??
            'Select…'}
          {isOpen ? (
            <ChevronUpIcon size={isMobile ? '3vw' : '-2'} />
          ) : (
            <ChevronDownIcon size={isMobile ? '3vw' : '-2'} />
          )}
        </Text>
        <SelectValue placeholder="Select…" />
      </SelectTrigger>

      <SelectContent
        variant={variant}
        position="popper"
        collisionPadding={0}
        avoidCollisions={false}
        align="center"
        sideOffset={5}
        alignOffset={isMobile ? 5 : 0}
        style={style as React.CSSProperties}>
        <SelectGroup>
          {options?.map((option) => (
            <SelectItem
              variant={variant}
              key={option.value}
              value={String(option.value)}
              disabled={option.disabled}>
              {option.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default function Calendar({ ...props }: CalendarProps) {
  const isMobile = useIsMobile();
  const variant = isMobile ? 'datepickerMobile' : 'datepicker';
  return (
    <DayPicker
      variant={variant}
      captionLayout="dropdown"
      hideNavigation
      fixedWeeks
      components={{
        Dropdown: CustomSelectDropdown
      }}
      showOutsideDays={false}
      {...props}
    />
  );
}
