import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function displaySwitchOption(theme: Theme): ComponentStyle {
  return {
    componentName: 'displaySwitchOption',
    baseStyle: {
      padding: `${theme.space['xs']} ${theme.space['s']}`,
      fontSize: theme.sizes['0'],
      borderRadius: theme.sizes['-3'],

      ['&:hover']: {
        backgroundColor: theme.colors.foreground,
        color: theme.colors.primary
      }
    },
    states: {
      active: {
        backgroundColor: theme.colors.foreground,

        ['&:hover']: {
          backgroundColor: theme.colors.foreground,
          color: theme.colors.text
        }
      }
    },
    variants: {
      bubble: {
        padding: `${theme.space['2xs']} ${theme.space['xs']}`,
        fontSize: theme.sizes['-1'],
        display: 'flex',
        alignItems: 'center'
      },
      liquidations: {
        border: `1px solid ${theme.colors.grey35}`,
        backgroundColor: theme.colors.grey20,
        borderRadius: theme.sizes['-2'],
        fontSize: theme.sizes['0'],
        height: theme.sizes['1'],
        color: theme.colors.textPrimary,
        "&[name='displaySwitchOption'][aria-selected='true']": {
          backgroundColor: theme.colors.primary
        },
        '&:hover': {
          borderColor: theme.colors.primary,
          backgroundColor: theme.colors.primary,
          color: theme.colors.text
        }
      },
      ssr: {
        padding: `${theme.space['xs']} ${theme.space['s']}`
      },
      datepicker: {
        border: `1px solid ${theme.colors.grey35}`,
        backgroundColor: theme.colors.grey45,
        borderRadius: theme.sizes['-2'],
        padding: `${theme.space['2xs']} ${theme.space['2xs']}`,
        fontSize: theme.sizes['-1'],
        height: theme.sizes['-1'],
        color: theme.colors.textPrimary,
        "&[name='displaySwitchOption'][aria-selected='true']": {
          backgroundColor: theme.colors.primary
        },
        '&:hover': {
          borderColor: theme.colors.primary,
          backgroundColor: theme.colors.primary,
          color: theme.colors.text
        }
      },
      datepickerMobile: {
        border: `1px solid ${theme.colors.grey35}`,
        backgroundColor: theme.colors.grey45,
        borderRadius: theme.sizes['-2'],
        padding: `${theme.space['m']} ${theme.space['m']}`,
        fontSize: '4vw',
        height: '4vw',
        color: theme.colors.textPrimary,
        "&[name='displaySwitchOption'][aria-selected='true']": {
          backgroundColor: theme.colors.primary
        },
        '&:hover': {
          borderColor: theme.colors.primary,
          backgroundColor: theme.colors.primary,
          color: theme.colors.text
        }
      }
    }
  };
}
