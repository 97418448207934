import { PaginatedResponse, useApi } from '@/api';
import {
  Card,
  TableSkeleton,
  Flex,
  Input,
  Text,
  formatCurrency,
  InfoCircleIcon
} from '@blockanalitica/ui';
import { useSearchParams } from '@/hooks';
import { useCallback, useState, useMemo } from 'react';
import { ApplyButton, RateTableFilters } from '@/components';
import LeverageOptimizerTable from './LeverageOptimizerTable';
import DropdownAssetsFilter from '@/components/DropdownAssetsFilter';
import { useSearchParams as useRRSearchParams } from 'react-router';
import { useTheme } from 'styled-components';
import { Tabs } from '@/components';

import SlippagesChart from './SlippagesChart';

const MAX_AMOUNT = 100_000_000;
interface ExtendedPaginatedResponse extends PaginatedResponse<EstimatorPool[]> {
  stablecoins: { key: string; value: string }[];
  protocols: { key: string; value: string }[];
  collateral_tokens: { key: string; value: string }[];
}

export type EstimatorPool = {
  pool: string;
  protocol: string;
  rate: string;
  protocol_symbol: string;
  liquidation_price: string;
  net_apy: string;
  max_leverage: string;
  health_rate: string;
  real_leverage: string;
  real_collateral_amount_usd: string;
  base_rate: string | null;
  collateral_symbol: string;
  underlying_symbol: string;
  real_borrow_amount_usd: string | null;
  slope_rate_1: string | null;
  slope_rate_2: string | null;
  total_supply: string | null;
  pool_id: string;
  new_supply_apy: string;
  utilization_change: string;
  new_utilization: string;
  new_total_supply: string;
  supply_apy_change: string;
  total_supply_change: string;
  supplied_amount: string;
  net_native_token_apy: string;
  net_supply_apy: string;
  net_borrow_apy: string;
  supply_cap_reached: boolean;
  max_borrow_reached: boolean;
};

export default function LeverageOptimizer() {
  const theme = useTheme();
  const [, setRouterSearchParams] = useRRSearchParams();

  const {
    pagination,
    sorting,
    tokensFiltered,
    protocolsFiltered,
    collateralTokensFiltered,
    filterByToken,
    filterByProtocol,
    filterByCollateralToken
  } = RateTableFilters({
    sortingField: '-net_apy',
    initialFilters: {
      protocols:
        'sparkprotocol,aaveprotocol,aaveprotocol_lido,COMP,Morpho,skyprotocol,fluid',
      tokens: 'DAI,USDC,USDS,USDT',
      collateral_tokens: 'WETH'
    }
  });

  const [showAll, setShowAll] = useState(false);
  const [supplyAmountParam] = useSearchParams('supply_amount', '1000000');
  const [supplyAmount, setSupplyAmount] = useState<string>(
    supplyAmountParam ?? ''
  );
  const [borrowAmountParam] = useSearchParams('borrow_amount', '500000');
  const [borrowAmount, setBorrowAmount] = useState<string>(
    borrowAmountParam ?? ''
  );

  const [isBorrowFocused, setIsBorrowFocused] = useState(false);
  const [isSupplyFocused, setIsSupplyFocused] = useState(false);

  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const [leverageParam] = useSearchParams('leverage', '1');
  const [leverage, setLeverage] = useState<string>(leverageParam ?? ''); // Default leverage

  const formatCurrencyComma = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0 // Ensures no decimal places
    }).format(amount);
  };

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string | number>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);
        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });
        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const slippagesChart = useMemo(() => {
    return (
      <SlippagesChart
        tokensFiltered={tokensFiltered}
        collateralTokensFiltered={collateralTokensFiltered}
      />
    );
  }, [tokensFiltered, collateralTokensFiltered]);

  const handleSupplyAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (/^\d*$/.test(value) && Number(value) <= MAX_AMOUNT) {
        setSupplyAmount(value);
        setIsFormUpdated(true);
      }
    },
    []
  );
  const handleBorrowAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (/^\d*$/.test(value) && Number(value) <= Number(supplyAmount)) {
        setBorrowAmount(value);
        setIsFormUpdated(true);
      }
    },
    [supplyAmount]
  );

  const handleLeverageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLeverage(event.target.value.toString());
      setIsFormUpdated(true);
    },
    []
  );

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      updateSearchParams({
        supply_amount: Number(supplyAmount),
        leverage: leverage,
        borrow_amount: Number(borrowAmount)
      });
      setIsFormUpdated(false);
    },
    [supplyAmount, leverage, updateSearchParams, borrowAmount]
  );

  const [data, error] = useApi<ExtendedPaginatedResponse>(
    {
      url: `/net-estimator/`,
      queryParams: {
        p: String(pagination.currentPage),
        p_size: String(pagination.pageSize),
        order: sorting.currentSorting,
        supply_amount: String(supplyAmountParam),
        tokens: String(tokensFiltered),
        protocols: String(protocolsFiltered),
        collateral_tokens: String(collateralTokensFiltered),
        leverage: String(leverageParam),
        borrow_amount: String(borrowAmountParam),
        show_all: String(showAll)
      }
    },
    { keepPreviousData: true }
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton rows={10} columns={7} />;
  }
  const leverageTitle = (
    <Flex gap="3xs" alignItems="center" justifyContent="center">
      <Text
        borderRight="1px solid"
        borderColor={theme.colors.grey35}
        paddingRight="3xs">
        Leverage Optimizer
      </Text>
      <InfoCircleIcon size="0" color={theme.colors.primary} />
      <Text size="2" color={theme.colors.muted}>
        Estimates are for informational purposes only and may vary; see our{' '}
        <Text
          as="a"
          size="2"
          color={theme.colors.muted}
          style={{ textDecoration: 'underline' }}
          // @ts-ignore
          href="https://blockanalitica.com/terms-of-service/"
          target="_blank">
          {' '}
          Terms of Service
        </Text>{' '}
        for details.
      </Text>
    </Flex>
  );
  return (
    <>
      <Tabs
        tabs={[
          {
            key: 'leverage-optimizer',
            // @ts-ignore
            title: leverageTitle,
            content: (
              <>
                <Card gap="m" backgroundColor="#6B6B941A" marginBottom="m">
                  {/* Inputs */}
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <Flex gap="m" flexDirection={['column', 'column', 'row']}>
                      {/* Protocols input */}

                      <Flex
                        alignItems={['flex-start']}
                        flexDirection={['column']}
                        gap="3xs">
                        <Text
                          fontSize="0"
                          color={theme.colors.muted}
                          style={{ fontWeight: 'bold' }}>
                          Protocols
                        </Text>
                        <DropdownAssetsFilter
                          initialValue={protocolsFiltered || ''}
                          // @ts-ignore
                          titleFormat={(filteredCount) => (
                            <>
                              <Flex gap="xl">
                                <Text color={theme.colors.muted}>Option</Text>
                                <Text>
                                  {filteredCount}/{data['protocols'].length}
                                </Text>
                              </Flex>
                            </>
                          )}
                          callback={filterByProtocol}
                          options={data['protocols']}
                          dropdownHeader="leverageOptimizerProtocols"
                        />
                      </Flex>
                      {/* Collateral input */}
                      <Flex
                        alignItems={['flex-start']}
                        flexDirection={['column']}
                        gap="3xs">
                        <Text
                          fontSize="0"
                          color={theme.colors.muted}
                          style={{ fontWeight: 'bold' }}>
                          Collateral
                        </Text>
                        <Flex flexDirection="column">
                          <DropdownAssetsFilter
                            initialValue={collateralTokensFiltered || ''}
                            // @ts-ignore
                            titleFormat={(filteredCount) => (
                              <>
                                <Flex gap="3xl">
                                  <Text color={theme.colors.muted}>Asset</Text>
                                  <Text>
                                    {filteredCount}/
                                    {data['collateral_tokens'].length}
                                  </Text>
                                </Flex>
                              </>
                            )}
                            callback={filterByCollateralToken}
                            options={data['collateral_tokens']}
                            dropdownHeader="leverageOptimizer"
                            width="100%"
                          />
                          <Flex
                            paddingLeft="10px"
                            alignItems="center"
                            justifyContent="space-between"
                            borderTop="1px dashed"
                            borderLeft="1px solid"
                            borderBottom="1px solid"
                            borderRight="1px solid"
                            borderColor={theme.colors.grey35}
                            borderRadius="0px 0px 5px 5px"
                            backgroundColor={theme.colors.purple}>
                            <Text color={theme.colors.muted}>Amount</Text>
                            <Input
                              type="text"
                              backgroundColor={theme.colors.purple}
                              value={
                                isSupplyFocused
                                  ? supplyAmount
                                  : `${formatCurrencyComma(Number(supplyAmount))}`
                              }
                              onChange={handleSupplyAmountChange}
                              onFocus={() => setIsSupplyFocused(true)}
                              onBlur={() => setIsSupplyFocused(false)}
                              placeholder={`Enter amount up to ${formatCurrency(MAX_AMOUNT)}`}
                              style={{
                                borderColor: 'transparent',
                                textAlign: 'right',
                                width: '100px',
                                fontSize: theme.sizes[0]
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      {/* Debt input */}
                      <Flex
                        alignItems={['flex-start']}
                        flexDirection={['column']}
                        gap="3xs">
                        <Text
                          fontSize="0"
                          color={theme.colors.muted}
                          style={{ fontWeight: 'bold' }}>
                          Debt
                        </Text>
                        <Flex flexDirection="column">
                          <DropdownAssetsFilter
                            initialValue={tokensFiltered || ''}
                            // @ts-ignore
                            titleFormat={(filteredCount) => (
                              <>
                                <Flex gap="3xl">
                                  <Text color={theme.colors.muted}>Asset</Text>
                                  <Text>
                                    {filteredCount}/{data['stablecoins'].length}
                                  </Text>
                                </Flex>
                              </>
                            )}
                            callback={filterByToken}
                            options={data['stablecoins']}
                            dropdownHeader="leverageOptimizer"
                            width="100%"
                          />
                          <Flex
                            paddingLeft="10px"
                            alignItems="center"
                            justifyContent="space-between"
                            borderTop="1px dashed"
                            borderLeft="1px solid"
                            borderBottom="1px solid"
                            borderRight="1px solid"
                            borderColor={theme.colors.grey35}
                            borderRadius="0px 0px 5px 5px"
                            backgroundColor={theme.colors.purple}>
                            <Text color={theme.colors.muted}>Amount</Text>
                            <Input
                              type="text"
                              backgroundColor={theme.colors.purple}
                              value={
                                Number(leverage) == 1
                                  ? isBorrowFocused
                                    ? borrowAmount
                                    : `${formatCurrencyComma(Number(borrowAmount))}`
                                  : '-'
                              }
                              onChange={handleBorrowAmountChange}
                              disabled={Number(leverage) > 1 ? true : false}
                              onFocus={() => setIsBorrowFocused(true)}
                              onBlur={() => setIsBorrowFocused(false)}
                              placeholder={`Enter amount up to ${formatCurrency(MAX_AMOUNT)}`}
                              style={{
                                borderColor: 'transparent',
                                textAlign: 'right',
                                width: '100px',
                                fontSize: theme.sizes[0]
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex
                        alignItems={['flex-start']}
                        flexDirection={['column']}
                        gap="3xs">
                        <Text
                          fontSize="0"
                          color={theme.colors.muted}
                          style={{ fontWeight: 'bold' }}>
                          Leverage
                        </Text>
                        <Flex
                          flexDirection="column"
                          backgroundColor={theme.colors.purple}
                          border="1px solid"
                          borderRadius="5px 5px 5px 5px"
                          borderColor={theme.colors.grey35}
                          height="100%"
                          paddingLeft="10px"
                          paddingRight="10px"
                          alignItems="center">
                          <Flex alignItems="center">
                            <Text fontSize="-1" color="muted">
                              1x
                            </Text>
                            <Input
                              type="range"
                              min="1"
                              max="15"
                              step="0.1"
                              value={leverage}
                              onChange={handleLeverageChange}
                              paddingLeft={0}
                              paddingRight={0}
                              paddingTop={theme.sizes['-3']}
                              paddingBottom={theme.sizes['-3']}
                              width={'130px'}
                              style={{ accentColor: theme.colors.primary }}
                            />
                            <Text fontSize="-1" color="muted">
                              15x
                            </Text>
                          </Flex>
                          <Text paddingBottom="xs">
                            {Number(leverage).toFixed(1)}x Leverage
                          </Text>
                        </Flex>
                      </Flex>
                      {/* Apply Button */}
                      <Flex
                        as="form"
                        onSubmit={submit}
                        // @ts-ignore
                        alignSelf={'start'}
                        marginTop={['0px', '0px', '20px']}
                        marginBottom="xs">
                        <ApplyButton
                          // @ts-ignore
                          type="submit"
                          style={{ color: theme.colors.text }}
                          disabled={
                            !isFormUpdated ||
                            isNaN(Number(supplyAmount)) ||
                            Number(supplyAmount) <= 0 ||
                            Number(supplyAmount) > MAX_AMOUNT
                          }>
                          Estimate APY
                        </ApplyButton>
                      </Flex>
                    </Flex>
                    <Flex
                      gap="3xs"
                      flexDirection="column"
                      paddingLeft="xs"
                      borderLeft="1px solid"
                      borderColor={theme.colors.grey35}
                      marginTop="xs">
                      <Text color={theme.colors.muted}>
                        Include All Markets
                      </Text>
                      <Flex gap="3xs" alignItems="center">
                        <Input
                          type="checkbox"
                          onChange={() => setShowAll(!showAll)}
                        />
                        <Text size="2" color={theme.colors.muted}>
                          Show all markets, <br />
                          regardless of leverage
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
                <LeverageOptimizerTable
                  results={data.results}
                  pagination={{
                    ...pagination,
                    totalRecords: data.count
                  }}
                  sorting={sorting}
                  totalRecords={data.count}
                />
              </>
            )
          }
        ]}
        activeTab="leverage-optimizer"
        setActiveTab={() => null}
      />
      {slippagesChart}
    </>
  );
}
