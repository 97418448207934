import { SVGProps } from 'react';
import Svg from './Svg';
import { resolveThemeVariable } from '../../utils/getResponsiveStyles';
import { useTheme } from 'styled-components';
import type { Size } from '../../theme/types';
export default function WeighingScaleIcon({
  size = '2',
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & { size?: Size | string | number }) {
  const theme = useTheme();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 22"
      width={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      height={
        typeof size === 'number'
          ? `${size}px`
          : resolveThemeVariable<Size | string>(size, theme.sizes)
      }
      fill="none"
      {...props}>
      <path
        d="M1 16.6112V16.4443C1 16.0926 1 15.9168 1.02663 15.7455C1.05027 15.5934 1.0895 15.4442 1.14368 15.3003C1.20469 15.1382 1.29106 14.9855 1.46378 14.6802L5.4 7.72227M1 16.6112C1 19.0658 2.96995 21.0556 5.4 21.0556C7.83005 21.0556 9.8 19.0658 9.8 16.6112M1 16.6112V16.3889C1 16.0778 1 15.9222 1.05995 15.8034C1.11268 15.6989 1.19682 15.6139 1.30031 15.5606C1.41796 15.5 1.57197 15.5 1.88 15.5H8.92C9.22803 15.5 9.38204 15.5 9.4997 15.5606C9.60318 15.6139 9.68732 15.6989 9.74005 15.8034C9.8 15.9222 9.8 16.0778 9.8 16.3889V16.6112M5.4 7.72227L9.33622 14.6802C9.50894 14.9855 9.59531 15.1382 9.65632 15.3003C9.7105 15.4442 9.74973 15.5934 9.77337 15.7455C9.8 15.9168 9.8 16.0926 9.8 16.4443V16.6112M5.4 7.72227L18.6 5.50005M14.2 14.3889V14.2221C14.2 13.8704 14.2 13.6946 14.2266 13.5233C14.2503 13.3712 14.2895 13.222 14.3437 13.0781C14.4047 12.916 14.4911 12.7633 14.6638 12.458L18.6 5.50005M14.2 14.3889C14.2 16.8435 16.1699 18.8334 18.6 18.8334C21.0301 18.8334 23 16.8435 23 14.3889M14.2 14.3889V14.1667C14.2 13.8556 14.2 13.7 14.2599 13.5812C14.3127 13.4766 14.3968 13.3916 14.5003 13.3384C14.618 13.2778 14.772 13.2778 15.08 13.2778H22.12C22.428 13.2778 22.582 13.2778 22.6997 13.3384C22.8032 13.3916 22.8873 13.4766 22.9401 13.5812C23 13.7 23 13.8556 23 14.1667V14.3889M18.6 5.50005L22.5362 12.458C22.7089 12.7633 22.7953 12.916 22.8563 13.0781C22.9105 13.222 22.9497 13.3712 22.9734 13.5233C23 13.6946 23 13.8704 23 14.2221V14.3889M12 1.0556V6.61116"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

WeighingScaleIcon.displayName = '<WeighingScaleIcon />';
