import { Page } from '@/components';

import LeverageOptimizer from './LeverageOptimizer';
export default function LeverageOptimizerPage() {
  return (
    <Page>
      <LeverageOptimizer />
    </Page>
  );
}
