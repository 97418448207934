import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function selectContent(theme: Theme): ComponentStyle {
  return {
    componentName: 'selectContent',
    baseStyle: {
      '&::-webkit-scrollbar': {
        width: theme.sizes['-2']
      },
      '&::-webkit-scrollbar-track': {
        background: theme.colors.grey45,
        borderRadius: theme.sizes['-4']
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.colors.primary,
        borderRadius: theme.sizes['-4']
      }
    },
    variants: {
      datepicker: {
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: theme.colors.datepicker,
        border: 'none',
        boxShadow: 'none',
        width: `calc(${theme.sizes['3']} * 8)`,
        height: `calc(${theme.sizes['3']} * 8)`,
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
          width: theme.sizes['-2']
        },
        '&::-webkit-scrollbar-track': {
          background: theme.colors.grey45,
          borderRadius: theme.sizes['-4']
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.colors.primary,
          borderRadius: theme.sizes['-4']
        }
      },
      datepickerMobile: {
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: theme.colors.datepicker,
        border: 'none',
        boxShadow: 'none',
        width: `100vw`,
        height: `87vw`,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          position: 'absolute',
          left: '1%'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.colors.grey45,
          borderRadius: theme.sizes['-4']
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.colors.primary,
          borderRadius: theme.sizes['-4']
        }
      }
    }
  };
}
