import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function modal(theme: Theme): ComponentStyle {
  return {
    componentName: 'modal',
    baseStyle: {
      padding: theme.space.m,
      border: 'none',
      position: 'relative',
      background: theme.colors.black65,
      borderRadius: theme.sizes['2'],
      gap: theme.space.m,
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      width: 'fit-content',
      top: '20%'
    },
    variants: {}
  };
}
