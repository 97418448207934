import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ThemedComponentWrapper } from '@blockanalitica/ui';
import type { ThemedComponentWrapperProps } from '@blockanalitica/ui';
import type { ComponentPropsWithoutRef } from 'react';

export interface PopoverContentProps
  extends Partial<Omit<ThemedComponentWrapperProps, 'children' | 'color'>>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  sideOffset?: number;
  variant?: 'baseStyle' | 'datepicker' | 'datepickerMobile';
}

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(({ sideOffset, align, ...props }, ref) => {
  return (
    <PopoverPrimitive.Portal>
      <ThemedComponentWrapper
        name="popoverContent"
        // @ts-ignore
        as={PopoverPrimitive.Content}
        ref={ref}
        variant={props.variant}
        avoidCollisions={false}
        align={align}
        side="bottom"
        sideOffset={sideOffset}
        {...props}
      />
    </PopoverPrimitive.Portal>
  );
});

PopoverContent.displayName = 'PopoverContent';
export default PopoverContent;
