import { Page } from '@/components';
import { useApi } from '@/api';
import { useParams } from 'react-router';
import { PageSkeleton, EntityName } from '@/components';
import { CryptoIcon, Flex, Box, KpiCard } from '@blockanalitica/ui';
import ProtocolStablecoinsPoolsTable from './components/ProtocolStablecoinsPoolsTable';
import { protocolToTitleCase } from '@/utils/utils';
import ProtocolRatesChart from './components/ProtocolStablecoinsRatesChart';

type Protocol = {
  protocol: string;
  protocol_symbol: string;
  total_debt: string;
  total_debt_change: string;
  total_debt_change_percentage: string;
};

export default function ProtocolPage() {
  const { protocol } = useParams() as {
    protocol: string;
  };

  const [data, error] = useApi<Protocol>(
    {
      url: `/protocol/${protocol}/`
    },
    {
      keepPreviousData: true
    }
  );

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <EntityName
            symbol1={<CryptoIcon name={data.protocol_symbol} size="6" />}>
            <Flex gap="3xs" flexDirection="column">
              <Box fontSize="4">{protocolToTitleCase(data.protocol)}</Box>
            </Flex>
          </EntityName>
        }>
        <Flex gap={['m', 's']} flexDirection={['column', 'row']}>
          <KpiCard
            title="Total Stablecoin Supply"
            field="total_supply"
            data={data}
            options={{
              currency: 'USD'
            }}
          />
          <KpiCard
            title="Total Stablecoin Borrow"
            field="total_debt"
            data={data}
            options={{
              currency: 'USD'
            }}
          />
        </Flex>
        <ProtocolRatesChart protocol={protocol} />
        <ProtocolStablecoinsPoolsTable />
      </Page>
    );
  }
  return <PageSkeleton />;
}
