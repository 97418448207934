import {
  LineChart,
  ChartSkeleton,
  formatPercentage,
  PercentageValue
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useApi } from '@/api';
import { useChartBackground } from '@/hooks';
import type { SimpleChartData } from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { useTheme } from 'styled-components';
import _ from 'lodash';

type PoolIrmResponse = {
  irm?: SimpleChartData[];
  current_utilization?: number;
  current_borrow_apy?: number;
  y_max?: number;
};

export default function PoolIrmGraph({
  poolId,
  protocol
}: {
  poolId: string;
  protocol: string;
}) {
  const theme = useTheme();

  const [data, error] = useApi<PoolIrmResponse>({
    url: `/pool/${protocol}/${poolId}/irm/`
  });

  const [currentApy, currentUtilization, yMax] = useMemo(() => {
    return [data?.current_borrow_apy, data?.current_utilization, data?.y_max];
  }, [data]);

  const chartConfig = useMemo(
    () => ({
      options: {
        scales: {
          x: {
            type: 'linear',
            ticks: {
              stepSize: 0.01
            }
          }
        },
        plugins: {
          annotation: {
            annotations: {
              currentRate: {
                type: 'line',
                xMin: currentUtilization,
                xMax: currentUtilization,
                yMin: 0,
                yMax: yMax,
                borderColor: theme.colors.muted,
                borderWidth: 2,
                borderDash: [2, 3],
                label: {
                  content: `Current borrow APY ${Number(currentApy).toFixed(2)}%`,
                  display: true,
                  position: 'end',
                  backgroundColor: 'transparent',
                  textStrokeColor: theme.colors.muted,
                  color: theme.colors.muted,
                  yAdjust: -10
                }
              }
            }
          }
        }
      }
    }),
    [currentUtilization, theme.colors.muted, currentApy, yMax]
  );

  const config = useChartBackground();
  const mergedChartConfig = _.merge({}, config, chartConfig);

  const yScaleValueFormatter = useCallback((value: number | string) => {
    return formatPercentage(Number(value));
  }, []);

  const valueFormatter = useCallback((value: number | string) => {
    return <PercentageValue value={value} variant="dynamic" />;
  }, []);

  const subValueFormatter = useCallback((value: number | string) => {
    return `Utilization: ${formatPercentage(Number(value))}`;
  }, []);

  const xScaleValueFormatter = useCallback((value: number | string) => {
    return formatPercentage(Number(value));
  }, []);

  const chart = useMemo(
    () => (
      <LineChart
        key={`irm-${protocol}-${poolId}`}
        data={data?.irm}
        config={mergedChartConfig}
        datasets={[
          {
            xField: 'utilization',
            yField: 'borrow_apy'
          }
        ]}
        xScaleValueFormatter={xScaleValueFormatter}
        yScaleValueFormatter={yScaleValueFormatter}
        valueFormatter={valueFormatter}
        subValueFormatter={subValueFormatter}
      />
    ),
    [
      protocol,
      poolId,
      data,
      mergedChartConfig,
      valueFormatter,
      yScaleValueFormatter,
      subValueFormatter,
      xScaleValueFormatter
    ]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <ChartSkeleton />;
  }

  return chart;
}
