import { useApi } from '@/api';
import {
  Card,
  KpiSkeleton,
  Flex,
  Input,
  CurrencyValue,
  formatCurrency,
  DropdownFilter,
  KpiCard,
  Text
} from '@blockanalitica/ui';
import { useSearchParams } from '@/hooks';
import { useSearchParams as useRRSearchParams } from 'react-router';

import { useCallback, useState } from 'react';
import { ApplyButton } from '@/components';
import styled, { css } from 'styled-components';

const MAX_AMOUNT = 5_000_000_000;

export type EstimatorPool = {
  new_supply_apy: string;
  new_utilization: string;
  new_total_supply: string;
  new_borrow_apy: number | string;
  new_utilization_change: string;
  new_borrow_apy_change: string;
  new_supply_apy_change: string;
  new_amount: string;
  max_borrow: string;
  reserve_factor: string | null;
};

const StyledDropdownFilter = styled(DropdownFilter)`
  ${({ theme }) => css`
    width: 110px;
    font-size: ${theme.sizes['0']};
    color: ${theme.colors.muted};
    border: 1px solid ${theme.colors.border};
    border-radius: ${theme.sizes['-4']};
  `}
`;

export default function PoolRatesEstimator({
  poolId,
  protocol
}: {
  poolId: string;
  protocol: string;
}) {
  const [, setRouterSearchParams] = useRRSearchParams();

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string | number>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);
        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });
        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const allowedTransactions = ['supply', 'withdraw', 'borrow', 'repay'];
  const [transactionParam] = useSearchParams('transaction', 'supply');

  const [transactionType, setTransactionType] = useState<string>(
    transactionParam && allowedTransactions.includes(transactionParam)
      ? transactionParam
      : 'supply'
  );
  const [transactionTypeInput, setTransactionTypeInput] = useState<string>(
    transactionParam ?? 'supply'
  );

  const [amountParam] = useSearchParams('amount', '10000000');
  const [amount, setAmount] = useState<string>(amountParam ?? '');

  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const handleAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (/^\d*$/.test(value)) {
        if (Number(value) <= MAX_AMOUNT) {
          setAmount(value);
          setIsFormUpdated(true);
        }
      }
    },
    []
  );

  const handleTransactionChange = useCallback((value: string) => {
    setTransactionTypeInput(value);
    setIsFormUpdated(true);
  }, []);

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      updateSearchParams({
        amount: Number(amount),
        transaction: transactionTypeInput
      });

      setTransactionType(transactionTypeInput);
      setIsFormUpdated(false);
    },
    [amount, transactionTypeInput, updateSearchParams]
  );
  const [data, error] = useApi<EstimatorPool>(
    {
      url: `/pool/${protocol}/${poolId}/estimator/`,
      queryParams: {
        amount: String(amountParam),
        transaction: transactionType
      }
    },
    {
      keepPreviousData: true
    }
  );

  const amountTitle = useCallback(() => {
    if (transactionType === 'supply') return 'Supplied amount';
    if (transactionType === 'withdraw') return 'Withdrawn amount';
    if (transactionType === 'repay') return 'Repaid amount';
    if (transactionType === 'borrow') return 'Borrowed amount';
    return 'Supplied amount';
  }, [transactionType]);

  if (error) {
    throw error;
  }
  if (!data) {
    return <KpiSkeleton />;
  }
  return (
    <Card padding="0" gap="s" flexDirection="column">
      <Flex as="form" onSubmit={submit} flexDirection="column" gap="2xs">
        <Flex color="muted" gap="2xs" flexDirection={['column', 'row']}>
          Amount:
          <CurrencyValue
            value={Number(amount) || 0}
            options={{ currency: 'USD' }}
          />
        </Flex>
        <Flex gap="s">
          <Input
            variant="multi"
            type="text"
            value={amount}
            onChange={handleAmountChange}
            placeholder={`Enter amount up to ${formatCurrency(MAX_AMOUNT)}`}
          />
          <StyledDropdownFilter
            filterField="transactionType"
            placeholder="Supply"
            filtersApplied={{}}
            initialValue={transactionType ?? 'Supply'}
            updateFilters={() => {}}
            variants={{ dropdownHeader: 'secondary' }}
            options={[
              { label: 'Supply', value: 'supply' },
              { label: 'Withdraw', value: 'withdraw' },
              { label: 'Borrow', value: 'borrow' },
              { label: 'Repay', value: 'repay' }
            ]}
            value={transactionTypeInput}
            onChange={(newValue: string) => handleTransactionChange(newValue)}
            multi={false}
          />
          <ApplyButton
            // @ts-ignore
            type="submit"
            disabled={
              !isFormUpdated ||
              isNaN(Number(amount)) ||
              Number(amount) <= 0 ||
              Number(amount) > MAX_AMOUNT
            }>
            <Text fontSize="0">Apply</Text>
          </ApplyButton>
        </Flex>
      </Flex>
      <Flex flexDirection={['column', 'row']} gap={['xs', '2xs']}>
        <Flex flex="1">
          <KpiCard
            padding={'0'}
            data={data}
            title="Estimated Supply Rate"
            field="new_supply_apy"
            options={{
              percentage: true,
              variants: { kpiCardValue: 'multi', kpiCardValueTrend: 'multi' },
              valueTrendOptions: {
                percentageValueOptions: { trendIconSize: '-2' }
              },
              ...(protocol === 'aave_core' &&
              poolId === '0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f'
                ? {
                    valueOptions: {
                      percentageValueOptions: {
                        nullable: '-'
                      }
                    }
                  }
                : {})
            }}
          />
          <KpiCard
            padding="0"
            data={data}
            title="Estimated Borrow Rate"
            field="new_borrow_apy"
            options={{
              percentage: true,
              variants: { kpiCardValue: 'multi', kpiCardValueTrend: 'multi' },
              valueTrendOptions: {
                percentageValueOptions: { trendIconSize: '-2' }
              }
            }}
          />
        </Flex>
        <Flex flex="1">
          <KpiCard
            padding="0"
            data={data}
            title="Pool Utilization"
            field="new_utilization"
            options={{
              percentage: true,
              variants: { kpiCardValue: 'multi', kpiCardValueTrend: 'multi' },
              valueTrendOptions: {
                percentageValueOptions: { trendIconSize: '-2' }
              }
            }}
          />
          <KpiCard
            padding="0"
            data={data}
            title={amountTitle()}
            field="new_amount"
            options={{
              currency: 'USD',
              notation: 'compact',
              variants: {
                kpiCardValue: 'multi',
                kpiCardValueTrend: 'multi',
                valueCurrencyValue: 'main'
              }
            }}
          />
        </Flex>
      </Flex>
    </Card>
  );
}
