import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function dropdownList(theme: Theme): ComponentStyle {
  return {
    componentName: 'dropdownList',
    baseStyle: {
      border: 'none',
      borderRadius: theme.sizes['-4'],
      marginTop: theme.space['3xs'],
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme.colors.primary} ${theme.colors.foreground}`,
      ['&::-webkit-scrollbar']: {
        width: '8px'
      },
      ['&::-webkit-scrollbar-thumb']: {
        background: theme.colors.primary,
        borderRadius: '4px'
      },
      ['&::-webkit-scrollbar-track']: {
        background: theme.colors.foreground
      }
    },
    variants: {
      liquidations: {
        flex: '1',
        '[name="dropdownOption"] [name="box"]': {
          maxWidth: '10rem',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        },
        '[name="dropdownOptionSelected"] [name="box"]': {
          maxWidth: '8rem',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        },
        '& svg, & img': {
          marginRight: theme.space['4xs']
        },
        '& input': {
          backgroundColor: theme.colors.foreground,
          color: theme.colors.text,
          border: `1px solid ${theme.colors.grey35}`,
          borderRadius: theme.sizes['-4'],
          fontSize: theme.sizes['0'],
          padding: theme.space['2xs'],
          width: '90%'
        },
        '& input:focus, & input:focus-visible': {
          outline: 'none',
          borderColor: theme.colors.primary
        }
      }
    }
  };
}
