import { useState, useCallback, useMemo } from 'react';
import {
  Flex,
  Input,
  Text,
  formatCurrency,
  FlexProps
} from '@blockanalitica/ui';
import { HomepageApplyButton, Link } from '@/components';
import { useTheme } from 'styled-components';
import styled, { css } from 'styled-components';

const MAX_AMOUNT = 5_000_000_000;
interface TabFlexProps extends FlexProps {
  $active: boolean;
}

const TabFlex = styled(Flex)<TabFlexProps>`
  flex-direction: row;
  font-weight: 500;

  ${(props) => css`
    background-color: ${props.theme.colors.tabInactive};
    padding: ${props.theme.space['xs']};
    border-radius: ${props.theme.sizes['-3']};
    color: ${props.theme.colors.muted};
    font-size: ${props.theme.sizes['2']};

    &:hover {
      cursor: pointer;
      background-color: ${props.theme.colors.foreground};
      color: ${props.theme.colors.text};
    }
  `}

  ${(props) =>
    props.$active &&
    css`
      background-color: ${props.theme.colors.grey10};
      color: ${props.theme.colors.text};

      // Disable hover for active tab
      &:hover {
        background-color: ${props.theme.colors.grey10};
        color: ${props.theme.colors.text};
        cursor: default;
      }
    `}
`;

export default function StablecoinRatesHomepageForm() {
  const [amount, setAmount] = useState('10000000');
  const [activeTab, setActiveTab] = useState('borrow');
  const theme = useTheme();

  const handleAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (/^\d*$/.test(value)) {
        if (Number(value) <= MAX_AMOUNT) {
          setAmount(value);
        }
      }
    },
    []
  );

  const amountComponent = useMemo(() => {
    return (
      <Text fontSize="2" color={theme.colors.primary}>
        {formatCurrency(Number(amount), { currency: 'USD' })}
      </Text>
    );
  }, [amount, theme.colors.primary]);

  return (
    <Flex
      gap="xs"
      flexDirection="column"
      marginBottom="2xl"
      alignItems="center">
      <Text fontSize="2" color={theme.colors.muted} display="inline-flex">
        {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} amount:{' '}
        {amountComponent}
      </Text>

      <Flex
        gap="xs"
        flexDirection={['column', 'row']}
        alignItems={['center', 'stretch']}>
        <Flex flexDirection="row" gap="xs">
          <TabFlex
            key="borrow"
            $active={'borrow' === activeTab}
            onClick={() => setActiveTab('borrow')}>
            Borrow
          </TabFlex>
          <TabFlex
            key="supply"
            $active={'supply' === activeTab}
            onClick={() => setActiveTab('supply')}>
            Supply
          </TabFlex>
        </Flex>
        <Input
          variant="massiveFullWidth"
          type="text"
          value={amount}
          placeholder={`Enter ${activeTab} amount`}
          onChange={handleAmountChange}
          textAlign="center"
        />
      </Flex>
      <Link to={`/${activeTab}?tab=rate_estimator&amount=${amount}`}>
        <HomepageApplyButton
          // @ts-ignore
          type="submit"
          disabled={isNaN(Number(amount)) || Number(amount) <= 0}>
          <Text fontSize="2">Estimate Rate</Text>
        </HomepageApplyButton>
      </Link>
    </Flex>
  );
}
