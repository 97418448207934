import { Page, EntityName } from '@/components';
import PoolKpis from './components/PoolKpis';
import { useApi } from '@/api';
import { useParams } from 'react-router';
import { PageSkeleton, Tabs } from '@/components';
import PoolStatsHistoricGraphs from './components/PoolStatsHistoricGraphs';
import PoolCollateralsTable from './components/PoolCollateralsTable';
import PoolHistoricRatesGraph from './components/PoolHistoricRatesGraph';
import PoolRatesKpis from './components/PoolRatesKpis';
import { Flex, CryptoIcon, Box } from '@blockanalitica/ui';
import { protocolToTitleCase } from '@/utils/utils';
import PoolRatesEstimator from './components/PoolRateEstimator';

type Pool = {
  underlying_address: string;
  underlying_symbol: string;
  protocol_symbol: string;
  protocol: string;
  pool_name: string;
  total_debt: string;
  total_debt_usd: string;
  total_supply: string;
  total_supply_usd: string;
  total_debt_change: string;
  total_debt_usd_change: string;
  total_supply_change: string;
  total_supply_usd_change: string;
  total_debt_change_percentage: string;
  total_debt_usd_change_percentage: string;
  total_supply_change_percentage: string;
  total_supply_usd_change_percentage: string;
  network: string;
};

export default function PoolPage() {
  const { protocol, poolId } = useParams() as {
    protocol: string;
    poolId: string;
  };

  const [data, error] = useApi<Pool>(
    {
      url: `/pool/${protocol}/${poolId}/`
    },
    {
      keepPreviousData: true
    }
  );

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <EntityName
            symbol1={<CryptoIcon name={data.protocol_symbol} size="6" />}
            symbol2={
              <CryptoIcon
                name={data.underlying_symbol}
                address={data.underlying_address}
                network={data.network}
                size="6"
              />
            }>
            <Flex gap="3xs" flexDirection="column">
              <Box fontSize="2">{data.pool_name}</Box>
              <Box color="muted" fontSize="0">
                {protocolToTitleCase(data.protocol)}
              </Box>
            </Flex>
          </EntityName>
        }>
        <PoolRatesKpis poolId={poolId} protocol={protocol} />
        <PoolHistoricRatesGraph poolId={poolId} protocol={protocol} />
        <PoolCollateralsTable poolId={poolId} protocol={protocol} />
        {!['sky', 'liquity_v2', 'fluid'].includes(protocol) && (
          <Tabs
            tabs={[
              {
                key: 'pool_estimator',
                title: 'Pool Rate Estimator',
                content: (
                  <PoolRatesEstimator poolId={poolId} protocol={protocol} />
                )
              }
            ]}
            activeTab="pool_estimator"
            setActiveTab={() => null}
          />
        )}
        <PoolKpis data={data} />
        <PoolStatsHistoricGraphs
          underlyingAddress={data.underlying_address}
          marketSymbol={data.underlying_symbol}
          poolId={poolId}
          protocol={protocol}
        />
      </Page>
    );
  }
  return <PageSkeleton />;
}
